/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import AntSelect from 'antd/lib/select';
import {
  ADD_MESSAGE,
  FORM_SIZE,
  GUTTER,
  LABEL,
  PLACEHOLDER,
  SLUG_ASSIGNED_CONTACTS,
  VALIDATION_MESSAGE,
} from '@moxie/constants';
import {
  Avatar,
  Card,
  Col,
  Empty,
  Form,
  Row,
  Select,
  Skeleton,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { Editor } from '@shared-components/forms';
import {
  IGetPopupContainer,
  ILead,
  IOfficeVisitForm,
  IService,
  IUser,
} from '@shared-components/models';
import { serviceActions, useAppSelector } from '@crm/core';
import {
  fetchPrevVisit,
  fetchPrevVisitByContact,
  fetchPrimaryBranchUsers,
  getAllCRMContacts,
  getVisitPurposeListBasedOnService,
  UserListForContacts,
} from '@crm/services.api';
import { errorHandler } from '@moxie/utils';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { ContactProfileCard } from 'libs/shared/src/modules/contacts/libs/contact-profile';
import { UserSelectBox } from '@shared-components/elements';
import moment from 'moment';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined className="font-large" spin />;

const OfficeVisitForm: React.FC<IOfficeVisitForm> = ({
  form,
  handleFieldsChange,
  initialData,
  submitForm,
}: IOfficeVisitForm) => {
  const [contactList, setContactList] = useState<ILead[]>([]);
  const [userList, setUserList] = useState<IUser[]>([]);
  const {
    dataLoading,
    services,
  }: { dataLoading: boolean; services: IService[] } = useAppSelector(
    (state) => ({
      dataLoading: state.office_visits.loading,
      services: state.services.allData,
    })
  );
  const [visitPurposes, setVisitPurposes] = useState([]);
  const [loading, setLoading] = useState({
    visitPurpose: true,
    contact: false,
  });
  const [searchKeywordsUser, setSearchKeywordsUser] = useState('');
  const [searchKeywordsContact, setSearchKeywordsContact] = useState('');
  const [totalDataCountUser, setTotalDataCountUser] = useState(0);
  const [totalDataCountContact, setTotalDataCountContact] = useState(0);
  const [pageUser, setPageUser] = useState(1);
  const [pageContact, setPageContact] = useState(1);
  const [prevVisits, setPrevVisits] = useState([]);
  const [prevVisitsLoading, setPrevVisitsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const resPerPage = 10;
  const skipDataUser = resPerPage * (pageUser - 1);
  const skipDataContact = resPerPage * (pageContact - 1);

  const handleSearchUser = (value: string) => {
    if (value.length === 0) {
      setSearchKeywordsUser('');
      setUserList([]);
      setPageUser(1);
    } else {
      setSearchKeywordsUser(value);
    }
  };

  const handleSearchContact = (value: string) => {
    if (value.length === 0) {
      setSearchKeywordsContact('');
      setContactList([]);
      setPageContact(1);
    } else {
      setSearchKeywordsContact(value);
    }
  };

  const getUsers = debounce(
    async (page: number) => {
      let url: string;
      let prevUsers: IUser[];
      if (searchKeywordsUser.length > 0) {
        url = `&selectBy=${searchKeywordsUser}`;
        prevUsers = [];
      } else {
        url = `&page=${page}&limit=${resPerPage}&skip=${skipDataUser}`;
        prevUsers = userList;
      }
      const res = await fetchPrimaryBranchUsers();
      setUserList([...prevUsers, ...res.data.data]);
      setTotalDataCountUser(res.data?.meta?.data_count);
    },
    100,
    { leading: true }
  );

  const getContacts = debounce(
    async (page: number) => {
      if (searchKeywordsContact.length > 0) {
        setContactList(() => {
          return [];
        });
        const response = await getAllCRMContacts(searchKeywordsContact);
        setContactList(response.data.data);
      } else {
        const response = await getAllCRMContacts(searchKeywordsContact, {
          page: page,
          limit: resPerPage,
          skip: skipDataContact,
        });
        setTotalDataCountContact(response.data.meta.data_count);
        const mergedContactData = [...contactList, ...response.data.data];
        setContactList(mergedContactData);
      }

      setLoading({ ...loading, contact: false });
    },
    100,
    { leading: true }
  );

  const handleScrollUser = debounce(
    (event: any) => {
      event.stopPropagation();
      const maxPage = Math.ceil(totalDataCountUser / resPerPage);
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (pageUser < maxPage) {
          setPageUser((prevPage) => {
            getUsers(prevPage + 1);
            return prevPage + 1;
          });
        }
      }
    },
    200,
    { leading: true }
  );

  const handleScrollContact = debounce(
    (event: any) => {
      event.stopPropagation();
      const maxPage = Math.ceil(totalDataCountContact / resPerPage);
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (pageContact < maxPage) {
          setPageContact((prevPage) => {
            getContacts(prevPage + 1);
            return prevPage + 1;
          });
        }
      }
    },
    200,
    { leading: true }
  );

  const getVisitPurposes = async (serviceId: string) => {
    try {
      if (serviceId) {
        const res = await getVisitPurposeListBasedOnService(serviceId);
        setVisitPurposes(res?.data?.data);
      }
    } catch (e) {
      errorHandler(e);
      setVisitPurposes([]);
    } finally {
      setLoading({ ...loading, visitPurpose: false });
      form.setFieldsValue({ visit_purpose_id: '' });
    }
  };

  const getPreviousVisits = async (id?: string) => {
    if (id) {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisitByContact(id);
      setPrevVisits(res.data.data);
    }
    setPrevVisitsLoading(false);
  };

  useEffect(() => {
    const setVisitPurposeList = async (id: string) => {
      const res = await getVisitPurposeListBasedOnService(id);
      setVisitPurposes(res?.data?.data);
      setLoading({ ...loading, visitPurpose: false });
    };

    const getPrevVisits = async (id: string) => {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisit(id);
      setPrevVisits(res?.data?.data);
      setPrevVisitsLoading(false);
    };

    if (initialData?.id) {
      setLoading({ contact: true, visitPurpose: true });
      setVisitPurposeList(initialData.service_id!);
      getPrevVisits(initialData.id);
      form.setFieldsValue(initialData);
    }
  }, [initialData?.id]);

  useEffect(() => {
    getContacts(pageContact);
  }, [searchKeywordsContact]);

  useEffect(() => {
    getUsers(pageUser);
  }, [searchKeywordsUser]);

  useEffect(() => {
    dispatch(serviceActions.getServicesRequest());
  }, []);

  return dataLoading || (loading.contact && loading.visitPurpose) ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      size={FORM_SIZE}
      form={form}
      onFieldsChange={handleFieldsChange}
      onFinish={submitForm}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="contact_id"
            label={LABEL.CONTACT}
            rules={[
              { required: true, message: VALIDATION_MESSAGE.SELECT_CONTACT },
            ]}
            data-testid="crm-officevisit-contact"
          >
            <AntSelect
              showSearch
              allowClear
              placeholder={PLACEHOLDER.SELECT_CONTACT}
              optionFilterProp="search_prop"
              onSearch={handleSearchContact}
              onPopupScroll={handleScrollContact}
              searchValue={searchKeywordsContact}
              defaultActiveFirstOption={false}
              optionLabelProp="label"
              listHeight={500}
              onChange={getPreviousVisits}
              disabled={!!initialData?.id}
              className="clear-disable"
            >
              {loading.contact ? (
                <Select.Option value="loading" disabled>
                  <Spin spinning size="small" className="full-width" />
                </Select.Option>
              ) : (
                contactList.map((item: any) => {
                  const fullName = `${item?.first_name} ${item?.last_name}`;
                  return (
                    <Select.Option
                      className="contact-selected-background"
                      value={item?.id}
                      key={item.id}
                      search_prop={searchKeywordsContact}
                      label={
                        <>
                          <label className="text-primary-color initial_capital">
                            {/* {item?.first_name} {item?.last_name} */}
                            {fullName}
                          </label>
                          <label className="profile__title margin-left-1 ">
                            |
                          </label>
                          <label className="contact-profile-text margin-left-1">
                            {item?.company?.company_code}-{item?.internal_id}
                          </label>
                        </>
                      }
                    >
                      <ContactProfileCard
                        firstName={item?.first_name}
                        lastName={item?.last_name}
                        email={item?.email}
                        phone={item?.phone}
                        status={item?.status}
                        branchName={item?.branch.name}
                        archivedContact={item?.archived}
                        id={`${item?.company?.company_code}-${item?.internal_id}`}
                      />
                    </Select.Option>
                  );
                })
              )}
            </AntSelect>
          </Form.Item>
        </Col>
      </Row>
      {form.getFieldValue('contact_id') && (
        <Row gutter={GUTTER}>
          <Col span={24} className="margin-bottom-2">
            <p className="ant-typography margin-bottom-1">Previous Visits</p>
            <Card size="small" className="prev-visit-card">
              {prevVisitsLoading ? (
                <div className="prev-visit-loader align-items-center">
                  <Spin indicator={antIcon} />
                </div>
              ) : !prevVisitsLoading && prevVisits.length !== 0 ? (
                <>
                  <Row>
                    <Col span={12}>
                      <h3 className="font-weight-normal">Assignee</h3>
                    </Col>
                    <Col span={9}>
                      <h3 className="font-weight-normal">Visit Purpose</h3>
                    </Col>
                    <Col span={3}>
                      <h3 className="font-weight-normal">Date</h3>
                    </Col>
                  </Row>
                  {prevVisits.map((item: any) => {
                    const fullName =
                      item?.assignee?.first_name +
                      ' ' +
                      item?.assignee?.last_name;
                    return (
                      <Row className="margin-bottom-2" key={item?.id}>
                        <Col className="display-flex" span={12}>
                          {item?.assignee ? (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                className="initial_capital margin-right-1"
                              >
                                {item?.assignee?.first_name?.substring(0, 1)}
                                {item?.assignee?.last_name?.substring(0, 1)}
                              </Avatar>
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                  ellipsis={
                                    fullName.length > 30
                                      ? { tooltip: fullName }
                                      : false
                                  }
                                >
                                  {fullName}
                                </Typography.Title>
                                <Tag>{item?.assignee?.branch?.name}</Tag>
                              </div>
                            </div>
                          ) : (
                            <div className="profile__info__item profile__info__user">
                              <Avatar
                                size="default"
                                icon={<UserOutlined />}
                                className="initial_capital margin-right-1"
                                alt="assignee"
                              />
                              <div className="profile__info__user__detail initial_capital">
                                <Typography.Title
                                  level={3}
                                  className="name-fixed-width"
                                >
                                  Unassigned
                                </Typography.Title>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col span={9}>
                          <Typography.Text className="text-primary">
                            {item?.service?.name}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {item?.visit_purpose?.visit_purpose}
                          </Typography.Text>
                        </Col>
                        <Col span={3}>
                          <Typography.Text type="secondary">
                            {moment(item?.created_at).format('DD/MM/yyyy')}
                          </Typography.Text>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <div className="prev-visit-loader align-items-center">
                  <Empty
                    description="No previous visit found"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name="service_id"
            label={LABEL.SERVICE}
            rules={[{ required: true }]}
            data-testid="crm-officevisit-service"
          >
            <AntSelect
              showSearch
              allowClear
              placeholder={LABEL.SERVICE}
              optionFilterProp="search_prop"
              onChange={getVisitPurposes}
              disabled={!!initialData?.id}
              className="clear-disable"
            >
              {services.length > 0 &&
                services?.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={item?.id}
                      search_prop={item?.name}
                    >
                      {item?.name}
                    </Select.Option>
                  );
                })}
            </AntSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="visit_purpose_id"
            label={LABEL.VISIT_PURPOSE}
            rules={[{ required: true }]}
            data-testid="crm-officevisit-visitpurpose"
          >
            <AntSelect
              showSearch
              allowClear
              placeholder={LABEL.VISIT_PURPOSE}
              disabled={!form.getFieldValue('service_id') || !!initialData?.id}
              optionFilterProp="search_prop"
              className={initialData?.id ? 'clear-disable' : ''}
            >
              {loading.visitPurpose ? (
                <Select.Option value="loading" disabled>
                  <Spin size="small" className="full-width" />
                </Select.Option>
              ) : (
                visitPurposes.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={item?.id}
                      search_prop={item?.visit_purpose}
                    >
                      {item?.visit_purpose}
                    </Select.Option>
                  );
                })
              )}
            </AntSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="assignee_list">
            <Form.Item
              name="assignee_id"
              label={LABEL.ASSIGNEE}
              data-testid="crm-officevisit-assignee"
            >
              <UserSelectBox
                placeholder={LABEL.ASSIGNEE}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('assignee_list')
                }
                users={userList}
                onSearch={handleSearchUser}
                onPopupScroll={handleScrollUser}
                searchValue={searchKeywordsUser}
                showUnassigned={true}
                disabled={
                  !!initialData?.id &&
                  !!initialData?.assignee_id &&
                  initialData?.status !== 'Waiting'
                }
                className="clear-disable"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="session_notes"
            label={LABEL.SESSION_NOTES}
            data-testid="crm-officevisit-sessionnotes"
          >
            <Editor placeholder={ADD_MESSAGE} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { OfficeVisitForm };
