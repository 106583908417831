import React from 'react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import App from './pages';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import '@moxie/styles';

ReactDOM.render(
  <StrictMode>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </StrictMode>,
  document.getElementById('root')
);
