import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  MessageOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { leadActions } from '@crm/core';
import {
  ARCHIVE,
  ASSIGN,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
  RESTORE,
  SEND_MAIL,
  SEND_SMS,
  UNASSIGNED_LABEL,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { popConfirm } from '@shared-components/elements';
import { ILead, ITableNameCardProps } from '@shared-components/models';
import { Col, Menu, Space, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { useDispatch } from 'react-redux';
import { UserNameCard } from './user-name-card';

const menu = (
  <Menu>
    <Menu.Item key={1}>
      <Space direction="horizontal">
        <MailOutlined />
        {SEND_MAIL}
      </Space>
    </Menu.Item>
    <Menu.Item key={2}>
      <Space direction="horizontal">
        <MessageOutlined />
        {SEND_SMS}
      </Space>
    </Menu.Item>
    <Menu.Item key={3}>
      <Space direction="horizontal">
        <EditOutlined />
        {EDIT_BTN}
      </Space>
    </Menu.Item>
    <Menu.Item key={4}>
      <Space direction="horizontal">
        <PlusOutlined />
        {ASSIGN}
      </Space>
    </Menu.Item>
    <Menu.Item key={5}>
      <Space direction="horizontal">
        <AppstoreAddOutlined />
        {ARCHIVE}
      </Space>
    </Menu.Item>
    <Menu.Item key={6} danger>
      <Space direction="horizontal">
        <DeleteOutlined />
        {DELETE_BTN}
      </Space>
    </Menu.Item>
  </Menu>
);

const userMenu = (
  archived: boolean,
  {
    onArchive,
    onDelete,
  }: {
    onArchive: () => void;
    onDelete: () => void;
  },
  data: ILead | any,
  // eslint-disable-next-line no-unused-vars
  user: any
) => {
  return (
    <Menu>
      {data?.status !== 'Client' && (
        <Menu.Item key={2} icon={<AppstoreAddOutlined />} onClick={onArchive}>
          {archived ? RESTORE : ARCHIVE}
        </Menu.Item>
      )}
      {
        <Menu.Item key={3} icon={<DeleteOutlined />} danger onClick={onDelete}>
          {DELETE_BTN}
        </Menu.Item>
      }
    </Menu>
  );
};

export const ContactTableElementCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  index,
  id,
  data,
  userProp,
  triggerRefresh,
  hideMenu = false,
  preventRedirect = false
}: ITableNameCardProps) => {
  const dispatch = useDispatch();
  const user = userProp;
  const onArchive = () => {
    if (data?.archived)
      dispatch(
        leadActions.archiveLeadRequest(
          id,
          { archived: false, assignee_user_id: data.assignee_user_id },
          (...args: any[]) => {
            if(triggerRefresh) {
              triggerRefresh(args)
            }
          }
        )
      );
    else
      dispatch(
        leadActions.archiveLeadRequest(
          id,
          { archived: true, assignee_user_id: data.assignee_user_id },
          (...args: any[]) => {
            if(triggerRefresh) {
              triggerRefresh(args)
            }
          }
        )
      );
    if(triggerRefresh) {
      triggerRefresh();
    }
  };

  const handleArchive = () => {
    popConfirm(
      !data?.archived ? ARCHIVE : RESTORE,
      !data?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${data?.first_name} ${data?.last_name}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onArchive
    );
  };

  const onDelete = () => {
    dispatch(leadActions.deleteLeadRequest(data.id, (...args: any[]) => {
      if(triggerRefresh) {
        triggerRefresh(args)
      }
    }));
  };

  const handleSingleLeadDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_LEAD,
      `${CONFIRM_MESSAGE.DELETE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onDelete,
      true
    );
  };

  const menuActions = {
    onArchive: handleArchive,
    onDelete: handleSingleLeadDelete,
  };

  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      id={id}
      email={email}
      link={preventRedirect ? undefined :`${URL_DETAIL_LINK}${id}/applications`}
      actionBar={true}
      index={index}
      menu={hideMenu ? null : userMenu(data?.archived || false, menuActions, data, user)}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export const UnassignedCard: React.FC = () => {
  return (
    <Space className="lead-table__name user-name-card">
      <Col className="align-items-center  user-name-card__avatar">
        <Avatar icon={<UserOutlined />} alt="unassigned" />
      </Col>
      <Col className="user-name-card__name ">
        <Typography.Text type="secondary">{UNASSIGNED_LABEL}</Typography.Text>
      </Col>
    </Space>
  );
};

export const TableUserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  link,
  index,
  actionBar,
  handleEdit,
}: ITableNameCardProps) => {
  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      link={link}
      email={email}
      index={index}
      actionBar={actionBar}
      menu={menu}
      showEmailAsLink={true}
      showMailIcon={true}
      handleEdit={handleEdit}
    />
  );
};
TableUserNameCard.defaultProps = {
  actionBar: true,
};
UserNameCard.defaultProps = {
  showEmailAsLink: false,
  showMailIcon: false,
};
