import axios from './axios';
import { API_URL } from '@moxie/constants';
import { IResponse, IService } from '@shared-components/models';

const getServiceList = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_SERVICES}/${params}`);
};

const getServicesListForProduct = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_SERVICES}/product/services`);
};

const getAdminServiceList = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_SERVICES}/admin/service-list/${params}`);
};

const getOneService = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_SERVICES}/${id}`);
};

const postService = async (body: IService): Promise<IResponse> => {
  return axios.post(API_URL.CRM_SERVICES, body);
};

const updateService = async (body: IService): Promise<IResponse> => {
  return axios.put(`${API_URL.CRM_SERVICES}`, body);
};

const deleteService = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_SERVICES}/${id}`);
};

const syncServiceCreateApi = async (id: string[]): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_SERVICES}/sync/create`, id);
};

export {
  getServiceList,
  getOneService,
  postService,
  updateService,
  deleteService,
  getAdminServiceList,
  syncServiceCreateApi,
  getServicesListForProduct,
};
