import { IPaginateResponse } from "@model/data.model"
import axiosInstance from "./axios"
import { Notification, UpdateNotificationStatusPayload } from "@model/notification"
import { API_URL } from "@moxie/constants"

export const fetchUserNotifications = async (page = 1, limit: number) => {
    const { data } = await axiosInstance.get<IPaginateResponse<Notification>>(API_URL.CRM_NOTIFICATION, {
      params: {
        page,
        limit: limit
      }
    })
  
    return data
  }
  

  export const markAllNotificationAsRead = () => axiosInstance.put(`/crm/notifications/all`)

  export const updateNotificationStatus = ({ is_read, notification_id}: UpdateNotificationStatusPayload) => axiosInstance.put(`${API_URL.CRM_NOTIFICATION}/${notification_id}`, {
    is_read
  })

  export const fetchNotificationsCount = () => axiosInstance.get<{ count: number}>(`${API_URL.CRM_NOTIFICATION}/count`)

