import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import * as agentPartnerAction from './agent-partner.action';
import * as agentPartnerConst from './agent-partner.constant';
import { getBranchesByAgentType } from '../../libs/services.api/lib/agent-partner.api';
import { AxiosResponse } from 'axios';

export function* getSubAgentBranches() {
  try {
    const agentType = 'sub-agent';
    const response: AxiosResponse = yield getBranchesByAgentType(agentType);
    yield put(
      agentPartnerAction.getSubAgentBranchesSuccess(response?.data?.data)
    );
  } catch (error) {
    errorHandler(error);
    yield put(agentPartnerAction.getSubAgentBranchesFailure(error));
  }
}

export function* getSuperAgentBranches() {
  try {
    const agentType = 'super-agent';
    const response: AxiosResponse = yield getBranchesByAgentType(agentType);
    yield put(
      agentPartnerAction.getSuperAgentBranchesSuccess(response?.data?.data)
    );
  } catch (error) {
    errorHandler(error);
    yield put(agentPartnerAction.getSuperAgentBranchesFailure(error));
  }
}

export default function* agentPartnerSaga() {
  yield takeLatest(
    agentPartnerConst.GET_SUB_AGENT_BRANCHES_REQUEST,
    getSubAgentBranches
  );
  yield takeLatest(
    agentPartnerConst.GET_SUPER_AGENT_BRANCHES_REQUEST,
    getSuperAgentBranches
  );
}
