import React, { useState } from 'react';
import { TEXT } from '@moxie/constants';
import { IProfileSection } from '@shared-components/models';
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { EditOutlined, BankOutlined } from '@ant-design/icons';
import { InstitutionBranchTagGroup } from '@shared-components/elements';
import { InstitutionDrawer } from './institutionDrawer';
import { capitalizeAllLetter } from 'libs/shared/src/functions';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const InstitutionDetailProfile: React.FC<IProfileSection> = (
  props: IProfileSection
) => {
  const { data, loading, triggerRefresh } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const canAccess = useCanAccess();

  const handleDrawerEvent = (bool: boolean) => {
    setDrawerOpen(bool);
    setIsEdit(true);
  };
  const handleEditEvent = (bool: boolean) => setIsEdit(bool);

  return (
    <>
      <section className="profile">
        <Card>
          <InstitutionDrawer
            drawerOpen={drawerOpen}
            handleDrawerEvent={handleDrawerEvent}
            isEdit={isEdit}
            handleEditEvent={handleEditEvent}
            isDetailPage={true}
            triggerRefresh={triggerRefresh}
          />

          <Row gutter={24}>
            <Col lg={8} className="custom_profile">
              {loading ? (
                <Skeleton avatar paragraph={{ rows: 4 }} active />
              ) : (
                <div className="profile__info">
                  <div className="profile__info__item profile__info__institute padding-top-2">
                    <Avatar size="large" icon={<BankOutlined />}>
                      {data?.name?.substring(0, 2)}
                    </Avatar>
                    <Typography.Text
                      className="initial_capital ellipsis-text font-bold profile__info__user__detail"
                      ellipsis={
                        String(data?.name).length >= 20
                          ? { tooltip: data?.name }
                          : false
                      }
                    >
                      {data?.name}
                    </Typography.Text>
                    {canAccess('institution','update') && <div className="profile__info__edit">
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => handleDrawerEvent(true)}
                        type="link"
                      />
                    </div>}
                  </div>

                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.BRANCH}:</h4>
                      <span className="profile__link">
                        <InstitutionBranchTagGroup
                          data={data?.branches}
                          numberOfBranch={3}
                        />
                      </span>
                    </Space>
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">
                        {TEXT.INSTITUTION_CODE}:
                      </h4>
                      <span>
                        {capitalizeAllLetter(
                          data?.identification_system_code || '-'
                        )}
                      </span>
                    </Space>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={8} className="custom_divider">
              {loading ? (
                <Skeleton paragraph={{ rows: 2 }} title={false} active />
              ) : (
                <>
                  <div>
                    <Space size={8} className=" align-items-center">
                      <h4 className="institute-profile">{TEXT.EMAIL}:</h4>
                      <Tooltip title={data?.email}>
                        <span className="profile__link">
                          {data?.email || '-'}
                        </span>
                      </Tooltip>
                    </Space>
                  </div>

                  {data?.branches &&
                    data?.branches.map(
                      (branch: { is_main_branch: any; website: any }) => {
                        if (branch.is_main_branch) {
                          return (
                            <>
                              <div className="profile__info__item">
                                <Space size={8} className=" align-items-center">
                                  <h4 className="institute-profile">
                                    {TEXT.WEBSITE}:
                                  </h4>
                                  <span>
                                    <a
                                      style={{
                                        pointerEvents: !branch?.website
                                          ? 'none'
                                          : 'auto',
                                      }}
                                      href={`https://${branch?.website}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {branch?.website || '-'}
                                    </a>
                                  </span>
                                </Space>
                              </div>
                            </>
                          );
                        }
                        return;
                      }
                    )}

                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.CURRENCY}:</h4>
                      <span>{data?.currency || '-'}</span>
                    </Space>
                  </div>
                </>
              )}
            </Col>
            <Col lg={8}>
              {loading ? (
                <Skeleton paragraph={{ rows: 2 }} title={false} active />
              ) : (
                <div>
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.SERVICE}:</h4>
                      <span className="profile__link initial_capital">
                        {data?.service?.name}
                      </span>
                    </Space>
                  </div>
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.WORKFLOW}:</h4>
                      <span className="profile__link initial_capital">
                        {data?.workflows?.map((item: any) => (
                          <Tag key={item?.id}>{item?.workflowType?.name}</Tag>
                        ))}
                      </span>
                    </Space>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Card>
      </section>
    </>
  );
};
export { InstitutionDetailProfile };
