import * as actionTypes from './workflow.constant';
import { IAction, IPayload, IWorkflow } from '@shared-components/models';

export const addCRMWorkflowRequest = (
  payload: IWorkflow,
  triggerRefresh: () => void
): IAction<IWorkflow> => {
  return {
    type: actionTypes.ADD_WORKFLOW_REQUEST,
    payload,
    triggerRefresh: triggerRefresh,
  };
};

export const addCRMWorkflowSuccess = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.ADD_WORKFLOW_SUCCESS,
  payload,
});

export const addCRMWorkflowFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.ADD_WORKFLOW_FAILURE,
  payload,
});

export const getAllWorkflowsRequest = (): IAction => ({
  type: actionTypes.FETCH_WORKFLOWS_REQUEST,
});

export const getAllWorkflowsSuccess = (
  payload: IWorkflow[]
): IAction<IWorkflow[]> => ({
  type: actionTypes.FETCH_WORKFLOWS_SUCCESS,
  payload,
});

export const getAllWorkflowsFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOWS_FAILURE,
  payload,
});

export const getCRMWorkflowRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_REQUEST,
  id,
});

export const getCRMWorkflowSuccess = (
  payload: IWorkflow[]
): IAction<IWorkflow[]> => ({
  type: actionTypes.FETCH_WORKFLOW_SUCCESS,
  payload,
});

export const getCRMWorkflowFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_FAILURE,
  payload,
});

export const getCRMWorkflowsByInstitutionIdRequest = (
  id: string
): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_REQUEST,
  id,
});

export const getCRMWorkflowsByInstitutionIdSuccess = (
  payload: any[]
): IAction<any[]> => ({
  type: actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_SUCCESS,
  payload,
});

export const getCRMWorkflowsByInstitutionIdFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_FAILURE,
  payload,
});

export const deleteCRMWorkflowRequest = (
  id: string,
  triggerRefresh: () => void
): IAction<string> => ({
  type: actionTypes.DELETE_WORKFLOW_REQUEST,
  payload: id,
  triggerRefresh: triggerRefresh,
});

export const deleteCRMWorkflowSuccess = (): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_SUCCESS,
});

export const deleteCRMWorkflowFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_FAILURE,
  payload,
});

export const clearCRMWorkflowFetch = (): IAction => ({
  type: actionTypes.CLEAR_WORKFLOW_FETCH,
});

export const addWorkflowStageRequest = (payload: any): IAction => {
  return {
    type: actionTypes.ADD_WORKFLOW_STAGE_REQUEST,
    payload,
  };
};

export const addWorkflowStageSuccess = (payload: any): IAction => ({
  type: actionTypes.ADD_WORKFLOW_STAGE_SUCCESS,
  payload,
});

export const addWorkflowStageFailure = (payload: any): IAction => ({
  type: actionTypes.ADD_WORKFLOW_STAGE_FAILURE,
  payload,
});

export const getWorkflowStagesRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_REQUEST,
  payload: id,
});

export const getWorkflowStagesSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_SUCCESS,
  payload,
});

export const getWorkflowStagesFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_FAILURE,
  payload,
});

export const getWfStageChecklists = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST,
  payload: id,
});

export const getWfStageChecklistsSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_SUCCESS,
  payload,
});

export const getWfStageChecklistsFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_FAILURE,
  payload,
});

export const clearWfStageChecklist = () => ({
  type: actionTypes.CLEAR_WORKFLOW_STAGE_CHECKLISTS,
});

export const getAllChecklistsRequest = () => ({
  type: actionTypes.FETCH_CHECKLISTS_REQUEST,
});

export const getAllChecklistsSuccess = (
  payload: IPayload<any>
): IAction<any> => ({
  type: actionTypes.FETCH_CHECKLISTS_SUCCESS,
  payload,
});

export const getAllChecklistsFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.FETCH_CHECKLISTS_FAILURE,
  payload,
});

export const deleteStageChecklistRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_REQUEST,
  payload: id,
});

export const deleteStageChecklistSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_SUCCESS,
  payload,
});

export const deleteStageChecklistFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_FAILURE,
  payload,
});
