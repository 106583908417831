import React, { ReactElement, ReactNode } from 'react';
import {
  Button,
  Table,
  TableUserNameCard,
  formatDateWithTimezone,
} from '@moxie/shared';
import { ILead, IOfficeBranch, IUser } from '@shared-components/models';
import {
  ADD_LEAD,
  ASSIGNEE_LABEL,
  CONTACT,
  LABEL,
  TEXT,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { PlusOutlined } from '@ant-design/icons';
// import Can, { PermissionAction, PermissionObjects } from '@crm/privilege';
import { useSelector } from 'react-redux';
import store, { RootState } from 'apps/crm/src/core/store';
import { Col, Row, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { LeadTableAssigneeCard } from './contact-table-assignee-card';
import { ContactTableNameCard } from './contact-table-name-card';
import { UserCard } from '../office-visit/libs/user-card';

const ContactTableList: React.FC<any> = ({
  filter,
  getData,
  refresh,
  filterOptions,
  handleAddContact,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: any) => {
  const currentUser = useSelector((store: RootState) => store.auth.user);

  const { Text } = Typography;

  const columns = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internal_id',
      render: (value: string, data: ILead): ReactNode => {
        return (
          <Link to={`${URL_DETAIL_LINK}${data?.id}/applications`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.company_code + '-' + value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'first_name',
      sorter: () => 0,
      render: (_text: string, row: ILead, index: number): ReactElement => (
        <ContactTableNameCard
          id={row.id}
          firstName={row.first_name || ''}
          email={row.email || ''}
          lastName={row.last_name}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
        />
      ),
    },
    {
      title: LABEL.CONTACT_TYPE,
      dataIndex: 'status',
      render: (_text: string, row: ILead): ReactNode => {
        return (
          <Row className="lead-table__added-from">
            <Col>
              <Tag className="tag-color">{row?.status}</Tag>
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      render: (_text: string, row: ILead): ReactNode => {
        return (
          <Row className="lead-table__phone">
            <Col>
              {row?.country_code}&nbsp;
              {row?.phone}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.BRANCH,
      dataIndex: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'crm_assignee_id',
      width: 300,
      render: (text: string, data: ILead): ReactElement => {
        return data?.archived === false ? (
          <LeadTableAssigneeCard data={data} triggerRefresh={triggerRefresh} />
        ) : (
          <UserCard
            firstName={data?.crm_assignee?.first_name || ''}
            lastName={data?.crm_assignee?.last_name || ''}
            email={data?.crm_assignee?.email || ''}
            showEmailAsLink={true}
          />
        );
      },
    },
    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updated_at',
      sorter: () => 0,
      render: (text: string, { user_timezone }: { user_timezone: string }) => {
        return (
          <div className="lead-table__created-at">
            <span>
              {user_timezone && formatDateWithTimezone(text, user_timezone)}
            </span>
          </div>
        );
      },
    },
    {
      title: LABEL.ARCHIVED_BY,
      dataIndex: 'archived_by',
      render: (text: string): ReactElement => {
        const user: IUser | undefined = store
          .getState()
          .users.allData?.find((item: IUser) => item.id === text);

        return (
          <div className="lead-table__archived-by">
            {user && (
              <TableUserNameCard
                index={user.id || ''}
                actionBar={false}
                firstName={user.first_name || ''}
                lastName={user.last_name}
                email={user.email || ''}
                id={user.id || ''}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={CONTACT}
        filterOptions={filterOptions}
        filter={filter}
        action={getData}
        columns={columns}
        size={'small'}
        refresh={refresh}
        tableButtons={
          // Can(currentUser, PermissionAction.Create, PermissionObjects.User) && (
          <Button type="primary" onClick={handleAddContact}>
            <PlusOutlined />
            {ADD_LEAD}
          </Button>
          // )
        }
      />
    </div>
  );
};

export { ContactTableList };
