import { IAction } from '@shared-components/models';
import * as actionTypes from './branch-institution.constant';

export interface IBranchesByInstitutionState {
  institutionsBranches: any[];
  institutionBranch: any;
  error: boolean | null;
  loading: boolean;
}

const initialBranchesByInstitutionState: IBranchesByInstitutionState = {
  institutionsBranches: [],
  institutionBranch: {},
  error: null,
  loading: false,
};
export const BRANCHES_BY_INSTITUTION_FEATURE_KEY = 'branches_by_institution';

export const branchesByInstitutionReducer = (
  state = initialBranchesByInstitutionState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.GET_BRANCHES_BY_INSTITUTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_BRANCHES_BY_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institutionsBranches: action.payload,
      };
    case actionTypes.GET_BRANCHES_BY_INSTITUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_BRANCH_FETCH:
      return {
        ...state,
        institutionsBranches: [],
      };
    default:
      return state;
  }
};
