import React from 'react';
import { Button, Table } from '@moxie/shared';
import { LABEL } from '@moxie/constants';
import {
  IOfficeBranch,
  IOfficeColumn,
  ITableProps,
} from '@shared-components/models';
import { TableBranchNameCard } from './table-branch-name-card';
import { TableUserNameCard } from '@shared-components/elements';
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';
import { AM_PERMISSIONS } from '@moxie/constants';
const {BRANCHES} = AM_PERMISSIONS;

export const columns = (props: ITableProps): IOfficeColumn[] => {
  const { deleteData, showDrawer, setMainBranch } = props;
  return [
    {
      title: LABEL.NAME,
      key: 'name',
      width: 200,
      sorter: () => 0,
      render(data: IOfficeBranch) {
        return (
          <div className="initial_capital">
            <TableBranchNameCard
              data={data}
              showDrawer={showDrawer}
              deleteData={deleteData}
              setMainBranch={setMainBranch}
            />
          </div>
        );
      },
    },
    {
      title: LABEL.CITY,
      dataIndex: 'city',
      sorter: () => 0,
      width: 200,
      render(_dataIndex: any, data: IOfficeBranch) {
        return (
          <div className="initial_capital">
            {data?.address?.city ? data?.address?.city : '-'}
          </div>
        );
      },
    },
    {
      title: LABEL.COUNTRY,
      dataIndex: 'country',
      sorter: () => 0,
      width: 200,
      render(_dataIndex: any, data: IOfficeBranch) {
        return data.address?.country ? data.address?.country : '-';
      },
    },
    {
      title: LABEL.MOBILE,
      key: 'mobile',
      width: 200,
      render(data: IOfficeBranch) {
        return data.mobile ? data.mobile : '-';
      },
    },
    {
      title: LABEL.PHONE_NUMBER,
      key: 'phone',
      width: 200,
      render(data: IOfficeBranch) {
        return data.phone ? data.phone : '-';
      },
    },
    {
      title: LABEL.CONTACT_PERSON,
      key: 'contact_person_id',
      width: 200,
      render(data: IOfficeBranch) {
        return data?.contactPerson ? (
          <TableUserNameCard
            index={data.contactPerson.id}
            actionBar={false}
            firstName={data.contactPerson.first_name || ''}
            lastName={data.contactPerson.last_name || ''}
            email={data.contactPerson.email || ''}
            id={data.contactPerson.id}
          />
        ) : (
          '-'
        );
      },
    },
  ];
};

const OfficeBranchTable: React.FC<ITableProps> = ({
  getData,
  tableName,
  deleteData,
  refreshTable,
  showDrawer,
  filter,
  setMainBranch,
}: ITableProps) => {
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );
  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
    setMainBranch: setMainBranch,
  };

  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={tableName}
        columns={columns(columnActions)}
        action={getData}
        refresh={refreshTable}
        filterOptions={true}
        filter={filter}
        tableButtons={
          permit([BRANCHES.MANAGE],rolePerms) && (
            <Button
              type="primary"
              typeof="button"
              onClick={() => showDrawer && showDrawer()}
            >
              <PlusOutlined /> {LABEL.CREATE_NEW_BRANCH}
            </Button>
          )
        }
      />
    </div>
  );
};

export default OfficeBranchTable;
