/* eslint-disable react/display-name */
import { PlusOutlined } from '@ant-design/icons';
// import Can, { PermissionAction, PermissionObjects } from '@crm/privilege';
import {
  LABEL,
  PHONE_LABEL,
  TEXT,
  USER_ADD_TITLE,
  USER_TITLE,
} from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import {
  IOfficeBranch,
  IUserForm,
  IUserTable,
} from '@shared-components/models';
import { RootState } from 'apps/crm/src/core/store';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { UserTableElementCard } from './table-form-name-card';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const UserTableList: React.FC<IUserTable> = ({
  showDrawer,
  filter,
  getData,
  refresh,
  filterOptions,
  actionBar,
  hideBranch,
  setIsEditMode,
  onClose,
  triggerRefresh,
}: IUserTable) => {
  const currentUser = useSelector((store: RootState) => store.auth.user);
const canAccess = useCanAccess();
  const columns = [
    {
      title: LABEL.NAME,
      sorter: () => 0,
      render: (_text: string, row: IUserForm, index: number): ReactNode => (
        <UserTableElementCard
          id={row.id}
          firstName={row.first_name}
          email={row.email}
          lastName={row.last_name}
          userProp={currentUser}
          index={index.toString()}
          data={row}
          showDrawer={showDrawer}
          onClose={onClose}
          setIsEditMode={setIsEditMode}
          actionBar={actionBar}
          triggerRefresh={triggerRefresh}
          refreshTable={refresh}
        />
      ),
    },

    {
      title: TEXT.BRANCH,
      dataIndex: 'branch',
      hidden: hideBranch,
      render: (data: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__added-from initial_capital">{`${data?.name}`}</div>
        );
      },
    },

    {
      title: TEXT.ADDRESS,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from initial_capital">{`${
          row.address1 ? row.address1 : ''
        } ${row.city_or_state}, ${row.country}`}</div>
      ),
    },
    {
      title: PHONE_LABEL,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from">{`${row.phone}`}</div>
      ),
    },

    {
      title: LABEL.EMAIL,
      sorter: () => 0,
      dataIndex: 'email',
      render: (email: string): ReactNode => (
        <div className="lead-table__added-from">{`${email.toLowerCase()}`}</div>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={USER_TITLE}
        filterOptions={filterOptions}
        filter={filter}
        action={getData}
        columns={columns}
        size={'small'}
        refresh={refresh}
        tableButtons={
          // Can(currentUser, PermissionAction.Create, PermissionObjects.User) && (
            <>
          {canAccess('user','create') && <Button
            type="primary"
            onClick={() => showDrawer && showDrawer()}
            data-testid="crm-adduser-btn"
          >
            <PlusOutlined />
            {USER_ADD_TITLE}
          </Button>}
          </>
          // )
        }
      />
    </div>
  );
};

export { UserTableList };
