import { getEnglishTestScore } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

export const useFetchEnglishTestScore = (id: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['englishTestScore', id],
    queryFn: async () => await getEnglishTestScore(id),
    enabled: true,
    onError: () => {
      errorNotificationHandler('Error fetching english test score.');
    },
  });

  return { englishTestScore: data?.data, isLoading };
};
