import { productTypeActions, useAppSelector } from '@crm/core';
import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useGetProductSubTypes = (form: FormInstance<any>) => {
  const [selectedProductType, setSelectedProductType] = useState<
    string | any
  >();
  const dispatch = useDispatch();
  const productSubTypes = useAppSelector(
    (state) => state.product_type.productSubTypes
  );
  const productSubTypesLoading = useAppSelector(
    (state) => state.product_type.loading
  );

  const handleProductTypeChange = (value: string) => {
    setSelectedProductType(value);
    form.setFieldsValue({ product_sub_type_id: undefined });
  };

  useEffect(() => {
    if (selectedProductType) {
      dispatch(
        productTypeActions.getProductSubTypesRequest(selectedProductType)
      );
    }
  }, [selectedProductType]);
  return {
    productSubTypes,
    productSubTypesLoading,
    selectedProductType,
    setSelectedProductType,
    handleProductTypeChange,
  };
};
