import { API_URL } from '@moxie/constants';
import {
  IProduct,
  IProductData,
  ITestTypeForForm,
} from '@shared-components/models';
import axios, { axiosInstance } from './axios';

const postCRMProduct = async (data: IProduct): Promise<IProduct> => {
  return axios.post(API_URL.CRM_PRODUCTS, data);
};

const updateCRMProduct = async (id: string, data: any): Promise<IProduct> => {
  return axios.put(`${API_URL.CRM_PRODUCTS}/${id}`, data);
};

const deleteCRMProduct = async (id: string): Promise<IProduct> => {
  return axios.delete(`${API_URL.CRM_PRODUCTS}/${id}`);
};

const getCRMProductsList = async (params: any): Promise<IProduct> => {
  return axios.get(API_URL.CRM_PRODUCTS, { params });
};

const getCRMSingleProduct = async (productId: string) => {
  const response = await axios.get<{ data: IProductData }>(
    `${API_URL.CRM_PRODUCTS}/${productId}`
  );
  return response.data.data;
};

const getProductInstitutionBranchesData = async (productId: string) => {
  return axios.get(`${API_URL.CRM_PRODUCTS}/${productId}/branches`);
};

const getProductWorkflowsData = async (productId: string) => {
  return axios.get(`${API_URL.CRM_PRODUCTS}/${productId}/workflows`);
};

const postCRMProductLanguageRequirements = async (data: ITestTypeForForm) => {
  return axiosInstance.post(`${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}`, data);
};
const getCRMProductLanguageRequirements = async (id: string) => {
  return axiosInstance.get(`${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/${id}`);
};

const getAllCRMProductLanguageRequirements = async (id: string) => {
  return axiosInstance.get(
    `${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/all/${id}`
  );
};

const updateCRMProductLanguageRequirements = (
  id: string,
  data: any
): Promise<string> => {
  return axiosInstance.put(
    `${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/${id}`,
    data
  );
};

export {
  postCRMProduct,
  updateCRMProduct,
  deleteCRMProduct,
  getCRMSingleProduct,
  getCRMProductsList,
  getProductInstitutionBranchesData,
  getProductWorkflowsData,
  postCRMProductLanguageRequirements,
  getCRMProductLanguageRequirements,
  getAllCRMProductLanguageRequirements,
  updateCRMProductLanguageRequirements,
};
