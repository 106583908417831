import axios from './axios';
import { Branch, IOfficeBranch, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getOfficeList = async (params = ''): Promise<IResponse> => {
  const responseData = await axios.get(`${API_URL.CRM_OFFICE}/${params}`);
  return responseData;
};

const postOfficeBranch = async (body: IOfficeBranch): Promise<IResponse> => {
  return axios.post(API_URL.CRM_OFFICE, body);
};

const getOneOfficeBranch = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_OFFICE}/${id}`);
};

const updateOfficeBranch = async (body: IOfficeBranch): Promise<IResponse> => {
  return axios.put(`${API_URL.CRM_OFFICE}/${body.id}`, body);
};
const deleteOfficeBranch = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_OFFICE}/${id}`);
};
const checkBranchName = async (
  name?: string,
  id?: string
): Promise<IResponse> => {
  const params = { id, name };
  return axios.get(`${API_URL.CRM_OFFICE}/check/name`, { params });
};

const getAssociatedOfficeBranches = async () => {
  const responseData = await axios.get<IResponse<Branch[]>>(`${API_URL.CRM_OFFICE}/assigned`);
  return responseData.data;
}

const getBranchesWithoutPagination = async () => {
  return await axios.get(`${API_URL.CRM_OFFICE}/all`);
}

export {
  getOfficeList,
  postOfficeBranch,
  getOneOfficeBranch,
  updateOfficeBranch,
  deleteOfficeBranch,
  checkBranchName,
  getAssociatedOfficeBranches,
  getBranchesWithoutPagination
};
