import { API_URL } from '@moxie/constants';
import axios from './axios';

const getBranchesByAgentType = (agentType: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_AGENT_PARTNER}/all/${agentType}`);
};

const getAllPartnerAgentByType = async (type: any) => {
  return axios.get(`${API_URL.CRM_AGENT_PARTNER}/all/${type}`);
};

export { getBranchesByAgentType, getAllPartnerAgentByType };
