import React from 'react';
import { LABEL, TEXT, PHONE_LABEL } from '@moxie/constants';
import Table from 'antd/lib/table';
import { IAddress, IInstitutionBranch } from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';
import { normalizePhrase } from '@moxie/shared';

const InstitutionDetailBranches = ({ branchData }: { branchData: any }) => {
  const columns: ColumnsType<IInstitutionBranch> = [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      sorter: () => 0,
      render(data: any) {
        return <span>{data ? normalizePhrase(data) : '-'}</span>;
      },
    },
    {
      title: TEXT.EMAIL,
      dataIndex: 'email',
      sorter: () => 0,
    },
    {
      title: PHONE_LABEL,
      dataIndex: 'phone_number',
      render(data: any) {
        return <span>{data ? data : '-'}</span>;
      },
    },
    {
      title: TEXT.ADDRESS,
      dataIndex: 'address',
      sorter: () => 0,
      render(data: IAddress) {
        return <span>{normalizePhrase(data?.city)}</span>;
      },
    },
  ];

  return (
    <>
      <div className="margin-top-1">
        <Table
          dataSource={branchData}
          pagination={{ hideOnSinglePage: true }}
          columns={columns}
        />
      </div>
    </>
  );
};
export default InstitutionDetailBranches;
