import { PlusOutlined } from '@ant-design/icons';
import {
  ADD_LEAD,
  ASSIGNEE_LABEL,
  LABEL,
  TEXT,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { ILead, IOfficeBranch, IUser } from '@shared-components/models';
import { Button, Col, Row, Typography } from 'antd';
import store, { RootState } from 'apps/crm/src/core/store';
import React, { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDateWithTimezone } from '../../functions';
import { Table } from '../../shared';
import {
  ContactTableElementCard,
  TableUserNameCard,
} from '../contact-add-edit/table-elements';
import SelectBranch from 'libs/shared/src/crm-modules/client/select-branch';
import {
  useAssignedBranch,
  useContactBranchFilter,
} from '@crm/libs/hooks/contacts';
import { UnassignedCard, UserNameCard } from './component/user-name-card';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const LeadTableList = ({
  getData,
  refresh,
  handleAddContact,
  handleSelectedId,
  filterOptions,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: any) => {
  const currentUser = useSelector((store: RootState) => store.auth.user);
  const { filter, handleBranchChange } = useContactBranchFilter();
  const branchesProps = useAssignedBranch();
  const canAccess = useCanAccess();

  const { Text } = Typography;

  const leadColumns = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internal_id',
      render: (value: string, data: ILead): ReactNode => {
        return (
          <Link to={`${URL_DETAIL_LINK}${data?.id}/applications`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.company_code + '-' + value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'first_name',
      width: 200,
      sorter: () => 0,
      render: (_text: string, row: ILead, index: number): ReactElement => (
        <ContactTableElementCard
          id={row.id}
          firstName={row.first_name || ''}
          email={row.email || ''}
          lastName={row.last_name}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          hideMenu={!canAccess('contact', 'update')}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
        />
      ),
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      render: (_text: string, row: ILead): ReactNode => {
        const phoneNumber = row?.country_code && row?.phone ? `${row?.country_code} ${row?.phone}` : '-';
        return (
          <Row justify={phoneNumber === '-' ? 'center' : 'start'} className="lead-table__phone">
            <Col>
              {phoneNumber}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.BRANCH,
      dataIndex: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'crm_assignee_id',
      width: 200,
      render: (text: string, data: ILead): ReactElement => {
        return data?.crm_assignee_id !== null ? (
          <UserNameCard
            firstName={data?.crm_assignee?.first_name || ''}
            lastName={data?.crm_assignee?.last_name || ''}
            email={data?.crm_assignee?.email || ''}
            showEmailAsLink={true}
          />
        ) : (
          <UnassignedCard />
        )
      },
    },
    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updated_at',
      sorter: () => 0,
      render: (text: string, { user_timezone }: { user_timezone: string }) => {
        return (
          <div className="lead-table__created-at">
            <span>
              {user_timezone && formatDateWithTimezone(text, user_timezone)}
            </span>
          </div>
        );
      },
    },
    {
      title: LABEL.ARCHIVED_BY,
      dataIndex: 'archived_by',
      render: (text: string): ReactElement => {
        const user: IUser | undefined = store
          .getState()
          .users.allData?.find((item: IUser) => item.id === text);

        return (
          <div className="lead-table__archived-by">
            {user && (
              <TableUserNameCard
                index={user.id || ''}
                actionBar={false}
                firstName={user.first_name || ''}
                lastName={user.last_name}
                email={user.email || ''}
                id={user.id || ''}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <Table
        filterContent={
          <SelectBranch
            user={currentUser}
            onChange={handleBranchChange}
            {...branchesProps}
          />
        }
        filterOptions={filterOptions}
        tableName={TEXT.CONTACTS}
        refresh={refresh}
        size={'small'}
        onChangeSelected={handleSelectedId}
        columns={leadColumns}
        triggerRefresh={triggerRefresh}
        action={getData}
        filter={filter}
        className="lead-table"
        tableButtons={
          <Button type="primary" onClick={handleAddContact}>
            <PlusOutlined />
            {ADD_LEAD}
          </Button>
        }
      />
    </div>
  );
};

export { LeadTableList };
