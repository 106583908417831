import { IStatusConst } from '../../../model/index';

export * from './am-roles-permissions';
export * from './countries';
export * from './url';
export * from './config';
export * from './menu';
export * from './message';
export * from './constant-json';
export * from './slug';
export * from './environment';
export * from './label';
export * from './placeholder';
export * from './regex';
export * from './form-all-constant';
export * from './initial-values';
export * from './utc-timezone';
export * from './subject-area';
export * from './dummy-data';
export * from './sanitize';
export * from './class-names';

export const STATUS: IStatusConst = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  PRODUCT_SUCCEEDED: 'product_succeeded',
};

export const REVENUE_TYPE = {
  COMMISSION_FROM_PARTNER: 'Commission from Partner',
  REVENUE_FROM_CONTACT: 'Revenue from Contact',
};
export const DOCUMENT_FILTER_CHECKLIST = /\.(png|jpeg|jpg|bmp|txt|pdf|doc|docx|ppt|pptx|mp4|mpg|wmv|mov|avi|mp3|wav|xls|xlsx|csv)$/;

export const PRESENTATION_TYPE = '.ppt, .pptx';
export const DOCUMENT_TYPE = '.pdf, .doc, .docx';
export const SPREADSHEET_TYPE = '.xls, .xlsx, .csv';
export const IMAGE_TYPE = '.jpeg, .png, .bmp, .txt';
export const VIDEO_TYPE = '.mp4, .mpg, .wmv, .mov, .avi';
export const AUDIO_TYPE = '.mp3, .wav';

export const APPLICATION_STATUS = {
  IN_PROGRESS: 'In Progress',
  DISCONTINUED: 'Discontinued',
  COMPLETED: 'Completed',
};

export const APPLICATION_ACTORS = {
  ADMISSION: 'Admission',
  CLIENT: 'Client',
  BOTH: 'All',
};
export const CONTACT_STATUS = {
  LEAD: 'Lead',
  CLIENT: 'Client',
  PROSPECT: 'Prospect',
};

export const TEXT = {
  NAME: 'Name',
  COMPANY_DETAILS: 'Company Details',
  NO_PRODUCTS_ADDED: 'No Products Added',
  NO_PRODUCTS_FOUND: 'No Products Found',
  ADD_ANOTHER_BRANCH: 'Add Another Branch',
  ADD_ANOTHER_CONTACT: 'Add Another Contact',
  ADD_ANOTHER_DETAIL: 'Add Another Detail',
  TASK_NUMBER: 'Task Number ',
  CONTACT_DETAILS: 'Contact Details',
  PRIMARY_INFORMATION: 'Primary Information',
  SELECT_ALL_INTAKES: 'Select all regular intakes',
  FILTERED_BY: 'Filtered By',
  QUALIFICATIONS: 'Qualifications',
  SAVED_QUALIFICATIONS: 'Saved Qualifications',
  OTHER_TEST: 'Other Test',
  ELIGIBLE_PRODUCTS_ONLY: 'Show Eligible Products Only',
  ELIGIBILITY_FILTER_TOOLTIP:
    'Eligibility of this contact is being checked on the basis of: ',
  NO_QUALIFICATION: 'No qualifications available for comparison',
  LANGUAGE_TEST: 'Language Test',
  EDUCATION_BACKGROUND: 'Education Background',
  DURATIONS: 'Durations',
  YEARS: 'Years',
  MONTHS: 'Months',
  WEEKS: 'Weeks',
  NOTIFICATIONS: 'Notifications',
  SHOW_ONLY_READ: 'Show only read',
  IELTS: 'IELTS',
  ALL_COUNTRIES: 'All Countries',
  VALID_FOR_ALL_COUNTRIES: 'Valid for All Countries',
  INSTALLMENT_AMOUNT: 'Installment Amount',
  INSTALLMENTS: 'Installments',
  TOTAL_FEES: 'Total Fees',
  PTE: 'PTE',
  REMOVE_PRODUCT_FROM_COMPARISON: 'Product deleted from product comparison',
  ALERT_MINIMUM_PRODUCT: 'Product list cannot be less than two',
  MINIMUM_PRODUCT_FOR_COMPARISON:
    '*At least two products are required for comparison',
  MAXIMUM_PRODUCT_FOR_COMPARISION: 'At most 5 products can be compared',
  GRE: 'GRE',
  TOEFL: 'TOEFL',
  SAT_I: 'SAT I',
  SAT_II: 'SAT II',
  ADD_COMPARE_LIST: 'Add to Compare List',
  ADD_INTERESTED_SERVICE: 'Add to Interested Services',
  ADD_APPLICATION: ' Add Application',
  COMPARISON_LIST: ' added to comparison List',
  REMOVED_FROM_COMPARISON_LIST: 'removed from comparison list',
  START_APPLICATION: 'Start Application',
  SEARCH_FOR: 'Search for',
  SHORT_COURSE: 'Short course',
  SEARCH_MORE: 'SEARCH_MORE',
  DELIVERY: 'Delivery',
  COMPARE_SHORTLIST: 'Compare Shortlist',
  LEVEL_OF_QUALIFICATION: 'Level of Qualification',
  ENQUIRIES: 'Enquiries',
  ENQUIRY_FORM: 'Enquiry Forms',
  CONTACT: 'Contact',
  OTHER_DETAILS: 'Other Details',
  FORM_FIELDS: 'Form Fields',
  DOCUMENT_CHECKLIST: 'Document Checklist',
  LOCATION: 'Location',
  PROFILE: 'Profile',
  BRANCHES: 'Branches',
  FILTERS: 'Filters',
  FEES: 'Fees',
  FEE: 'Fee',
  INTAKES: 'Intakes',
  DEFAULT_FEE: 'Default Fee',
  PROGRAM_LINK: 'Program Link',
  LINK: 'Link',
  REVENUE_TYPE: 'Revenue Type',
  TOTAL_COMMISSION: 'Total Commission',
  ESTIMATED_COMMISSION: 'Estimated Commission',
  COPY_LINK: 'Copy Link',
  COPY_EMAIL: 'Copy Email',
  COPIED_SUCCESS: 'Copied !',
  RESET_FILTERS: 'Reset Filters',
  ACTION: 'Actions',
  EDUCATION_LEVEL: 'Education Level',
  DURATION: 'Duration',
  PRODUCT_REQUIREMENT: 'Product Requirement',
  OVERALL_ENGLISH_TEST_SCORE: 'Eligible Overall English Test Score',
  IELTS_MAXIMUM_VALUE: 9,
  DISCIPLINE: 'Discipline',
  POSITION: 'Position',
  SESSION_UPDATE: 'Session Update',
  CLIENT: 'Client',
  OFFICES: 'Offices',
  WORKFLOW: 'Workflow',
  CRICOS_PROVIDER_CODE: 'CRICOS Provider Code',
  VIEW: 'View',
  WORKFLOW_DETAIL: 'Workflow Detail',
  APPLIED_INTAKE: 'Intake',
  ENROLLMENT_PERIOD: 'Enrollment Period',
  COUNTRY: 'Country',
  OVERVIEW: 'Overview',
  DESCRIPTION: 'Description',
  INTERESTED_SERVICES: 'Interested Services',
  SERVICE: 'Service',
  TENANT: 'Tenant',
  NONE: 'None',
  EXPORT: 'Export',
  NOT_AVAILABLE: 'Not available',
  LEAD_LIST: 'Lead List',
  PROSPECT_LIST: 'Prospect List',
  CLIENT_LIST: 'Client List',
  CONTACTS_LIST: 'Contacts List',
  CREATE_INVOICE: 'Create Invoice',
  CLIENT_PORTAL: 'Client Portal',
  ACTIVATE: 'Activate',
  ADDRESS: 'Address',
  WEBSITE: 'Website',
  CURRENCY: 'Currency',
  SERVICES: 'Services',
  POSTAL_CODE: 'Postal Code',
  INTERNAL_ID: 'Internal ID',
  INTERNAL: 'Internal ',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDED_BY: 'Added By',
  ADDED_ON: 'Added On',
  LAST_MODIFIED: 'Last Modified',
  ASSIGNED_TO: 'Assigned To',
  LOGIN: 'Login',
  CHANGE_ASSIGNEE: 'Change Assignee',
  IN_PROGRESS: 'In Progress',
  ACTIVITIES: 'Activities',
  VIEW_LEAD_DETAIL: 'View Detail',
  EDIT_LEAD_DETAIL: 'Edit Detail',
  ARCHIVE_LEAD: 'Archive',
  RESTORE_LEAD: 'Restore',
  VIEW_ALL: 'View All',
  INSTITUTE: 'Institutions',
  INSTITUTE_LIST: 'Institutions List',
  ADD_INSTITUTE: 'Add Institution',
  EDIT_INSTITUTE: 'Edit Institution',
  EDIT_TENANT: 'Edit Tenant',
  MANAGE_TENANT: 'Manage Tenant Detail',
  ADD_TENANT: 'Add Tenant',
  BRANCH_LIST: 'Branch List',
  PRODUCT: 'Product',
  PRODUCTS: 'Products',
  ATAR_TO_RECEIVE_OFFERS: 'ATAR to receive offers',
  DOES_NOT_INCLUDE_ADJ_FACTORS: "Doesn't include adjustment factors",
  PRODUCT_LIST: 'Product List',
  ADMISSION_CRITERIA: 'Admission criteria',
  ADD_PRODUCT: 'Add Product',
  APPLICATIONS: 'Applications',
  APPLICATION_LIST: 'Application List',
  LEAD_APPLICATION_LISTS: 'Lead Application Lists',
  PRICING: 'Pricing',
  DOCUMENTS: 'Documents',
  LOADING: 'Loading...',
  ARCHIVED: 'Archived',
  IS_DELETED: 'is_deleted',
  INTAKE_MONTH: 'Intake ',
  HIGHEST: 'Highest',
  MEDIAN: 'Median',
  LOWEST: 'Lowest',
  DASHBOARD: 'Dashboard',
  CONTACTS: 'Contacts',
  CALENDAR: 'Calendar',
  PROSPECTS: 'Prospects',
  CLIENTS: 'Clients',
  LEADS: 'Leads',
  CONTACT_TYPE: 'Contact type',
  FOLLOWERS: 'Followers',
  NOTE_AND_TERMS: 'Note & Terms',
  NO_DATA: 'No Data',
  ERROR: 'error',
  DONE: 'done',
  CANCEL: 'cancel',
  STATUS_DONE: 'Done',
  FILE_SELECT: 'No files are selected',
  EXISTING_DOCUMENTS: 'Existing Documents',
  SELECTED_DOCUMENTS: 'Selected Documents',
  UPLOAD_FROM_DEVICE: 'Upload from device',
  PASSPORT_NUMBER: 'Passport Number',
  DEVICE: 'device',
  EXISTING: 'existing',
  SELECT_EXISTING_DOCUMENTS: 'Select existing Documents',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  SET_PASSWORD: 'Set Password',
  WELCOME: 'Welcome',
  WELCOME_TO_ADMIN: 'Welcome to Admin Panel',
  GO_TO_LOGIN: 'Go to Login',
  VERIFICATION_SUCCESSFUL: 'Verification successful',
  RESEND_EMAIL_VERIFICATION: '  Resend verification link',
  EMAIL_SENT_SUCCESSFUL: 'Email sent successfully.',
  ID: 'ID',
  SN: 'SN',
  FILE_NAME: 'File Name',
  FILE_SIZE: 'File Size',
  TITLE: 'Title',
  FOLDER_NAME: 'Documents',
  TEMPLATE_FOLDER: 'Template',
  COMPANY: 'Company',
  TOTAL_USERS: 'Total Users',
  TOTAL_DEPARTMENTS: 'Total Departments',
  ACCOUNT_SETTINGS: 'Account Settings',
  ORGANIZATION: 'Organization',
  ORGANIZATION_SETTINGS: 'Organization Settings',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_PASSWORD_DESC: 'Change your account password',
  PREFERENCES: 'Preferences',
  PREFERENCES_DESC: 'Your account, company and general preferences.',
  ROLES: 'Roles',
  ROLES_DESC: 'Manage roles and permission.',
  OTHER_DETAILS_DESC: 'Manage other detail descriptions.',
  DOCUMENT_CHECKLIST_DESC: 'Manage checklists for documents.',
  NEW_EVENT_CREATED: 'New event created',
  FILE_DOWNLOAD: 'EnquiriesTemplate.xlsx',
  SELECT_ASSIGNEE: 'Please select an assignee!! ',
  DEPARTMENT_LIST: 'Department List',
  ADD_APPOINTMENT: 'Add Appointment',
  EDIT_APPOINTMENT: 'Edit Appointment',
  ADD_EVENT: 'Add Event',
  EDIT_EVENT: 'Edit Event',
  DATE_SELECT: 'Please select a date',
  TITLE_SELECT: 'Please enter title',
  PRIORITY: 'Priority',
  TIME_SELECT: 'Please select time',
  TIMEZONE_SELECT: 'Please select timezone',
  INVITEES: 'Select Invitees',
  SHOW_INVITEES: 'Invitees:',
  NO_INVITEES: 'No Invitees',
  TODAY: 'Today',
  PAST: 'Past Meetings',
  WEEK: 'This Week',
  UPCOMING: 'Upcoming Meetings',
  STARTS_AT: 'Starts at',
  START_DATE: 'Start date',

  START_TIME: 'Start time',
  END_TIME: 'End time',
  END_DATE: 'End date',
  CREATED_BY: 'Created By',
  BUCKET_NAME: 'coreheubert',
  GENERAL_DETAILS: 'General Details',
  ORGANIZATION_DETAILS: 'Organization Details',
  REFERENCE_DETAILS: 'Reference Details',
  ATTENDED: 'Attended',
  CANCELLED: 'Cancelled',
  NO_SHOW: 'No Show',
  POSTPONED: 'Postponed',
  SCHEDULED: 'Scheduled',
  TODO: 'To Do',
  INPROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  APPOINTMENT_STATUS: 'Status:',
  PREVIOUS_NOTES: 'Previous Notes:',
  PREVIOUS_COMMENTS: 'Previous Comments:',
  DEFAULT_LANGUAGE: 'eng',
  APPOINTMENTS: 'Appointments',
  APPLICATION_ID: 'Application ID',
  BRANCH: 'Branch',
  INSTITUTION: 'Institution',
  REMOVE_FROM_COMPARISON: 'Remove from comparison',
  COURSE: 'Course',
  PARTNER_CLIENT_ID: 'Partner Client Id',
  WORKFLOW_LIST: 'Workflow List',
  WORKFLOW_TYPES: 'Workflow Types',
  VIEW_COURSE_DETAILS: 'View course details',
  USER_APPOINTMENT: 'user_appointment',
  CLICK_SELECT_ASSIGNEE: 'Click here to change assignee',
  TOTAL_CLIENTS: 'Total Contacts',
  UPLOAD_DOCUMENTS: 'Uploading Document....',
  UPDATING_TASK: 'Updating Task....',
  TOTAL_APPLICATIONS: 'Total Applications',
  WELCOME_ADMIN: 'Hello Admin, Welcome !',
  CLIENT_REPORT: 'Contact Report',
  APPLICATION_REPORT: 'Application Report',
  GENERATE_PDF: 'Generate PDF',
  GENERATE_PDF_DESCRIPTION:
    'To generate PDF for comparison of courses click the button below',
  MANAGE_DETAILS: 'Manage Details',
  MAX_TASK_DOCUMENTS_COUNT:
    'You can not upload more than 20 documents at a time',
  MAX_TASK_DOCUMENTS_SIZE: 'Maximum Total Size of Files Allowed is 50 MB',
  REGISTRATION_SUCCESS: 'Thanks for signing up.',
  CONTACT_FORM_NOTE_TITLE: 'Lead Form Comment',
  DOWNLOADING_DOCUMENT: 'Downloading Document',
  COMPLETE: 'Complete',
  ADMISSION: 'Admission',
  OFFICE_VISIT: 'Office Visit',
  NO_EMERGENCY_CONTACTS_AVAILABLE: 'No Emergency Contacts Available',
  AGENTS: 'Agents',
  AGENTS_HEADER: 'Partner Agents',
  ADD_AGENT: 'Add Agent',
  VIEW_DETAILS: 'View Details',
  PRIMARY: 'Primary',
  NO_CONTACTS_FOUND: 'No Contacts Found',
  NO_APPLICATION: 'No Applications',
  INSTITUTION_CODE: 'Institution Code',
  COURSE_CODE: 'Course Code',

  PROCESSING_BRANCH: 'Processing Branch',
  SUB_AGENT: 'Sub-Agent',
  SUPER_AGENT: 'Super-Agent',
  NO_MORE_COMMENTS: 'No more comments',

  WORKFLOW_OR_SERVICE: 'Workflow/Service',
  ASSIGNEES: 'Assignees',
  OFFICE_VISITS: 'Office Visits',
  PRODUCT_FILTER: 'Product Filter',
  APPLY_FILTERS: 'Apply Filters',
  SUCCESSFUL_FORM_SUBMISSION: 'Your form has been submitted successfully!',
  NEW_CONTACTS: 'New Contacts',
  EXISTING_CONTACTS: 'Existing Contacts',
};

export const COMPARE_TABLE_PRICING = {
  CSP: 'Commonwealth supported place(CSP)',
  CSP_DESCRIPTION:
    "A CSP is a place at a university or higher education provider where the government pays part of your fees. This part is a subsidy, not a loan, and you don't have to pay it back.",
  INDICATIVE_FEES: 'Indicative fees - Student contribution amount',
  INDICATIVE_FEES_DESCRIPTION:
    "A CSP is a place at a university or higher education provider where the government pays part of your fees. This part is a subsidy, not a loan, and you don't have to pay it back.",
};
export const BUCKET_DIRS = {
  USER_PROFILE_IMAGE: 'Users',
  DOCUMENTS: 'Documents',
  TASKS: 'Tasks',
  LEAD_FORM: 'LeadForm',
  CONTACTS: 'Contacts',
  CONTACT_DOCUMENTS: 'Contact Documents',
  APPLICATIONS: 'Applications',
  INSTITUTION: 'Institution',
  OTHERS: 'Others',
  CRM_INQUIRY_FORM: 'crm-inquiry-form',
};

export const PARAGRAPH = {
  SET_PASSWORD:
    'Please use newly set password to login. Your company domain is: ',
};

export const ACADEMIC_REQUIREMENT = 'Academic Requirements';
export const ENGLISH_LANGUAGE_REQUIREMENT = 'English Language Requirements';
export const ENGLISH_LANGUAGE_REQUIREMENT_CONTENT =
  'International students must satisfy our English language proficiency requirements for admission. If your first language is not English you will need to have.';

export const ACADEMIC_REQUIREMENT_CONTENT =
  'Applicants must normally hold an approved degree or equivalent qualification from an approved tertiary institution. However, the Dean(Education) may, under certain circumstances and subject to specific conditions, admit others who can show evidence of fitness for candidature.';

export const ACTIONS = {
  ADD: 'add',
  UPDATE: 'update',
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  DELETE: 'delete',
  UPLOAD: 'Upload',
  CANCEL: 'Cancel',
  SAVE_CHANGES: 'Save Changes',
};
export const QUERY_FAILED_ERROR = {
  NOT_NULL_VIOLATION: '23502',
  KEY_REFERENCED_VIOLATION: '23503',
};

export const DEGREE_LEVEL_LIST = [
  { name: 'Undergraduate', value: '33a223c2-d676-4345-ae61-e20d0cb8b2a4' },
  { name: "Associate's degree", value: '6408c01e-e830-58c0-bf78-b35628d5feec' },
  { name: "Master's degree", value: '9408c01e-e830-48c0-cf78-b35628d5feec' },
  { name: 'Graduate', value: '5408c01e-e830-48c0-df78-b35628d5feec' },
  { name: 'Diploma', value: '4408c01e-e830-48c0-ff78-b35628d5feec' },
  { name: 'Advanced Diploma', value: '3408c01e-e930-48c0-bf78-b35628d5feec' },
];

export const COLUMNS = {
  NAME: 'Name',
  INSTITUTION_BRANCHES: 'Institution/Branches',
  DURATION: 'Duration',
  PRODUCT_TYPE: 'Product Type',
  ACTIONS: 'Actions',
  CLIENT_NAME: 'Client Name',
  CONTACT_NAME: 'Contact Name',
  ADMIN_ASSIGNEE: 'Assignee',
  AGENT_ASSIGNEE: 'Assignee',
};

export const DURATION = {
  YEARS: 'Years ',
  MONTHS: 'Months ',
  WEEKS: 'Weeks ',
  YEAR: 'Year ',
  MONTH: 'Month ',
  WEEK: 'Week ',
};

export const APPOINTMENT_STATUS_LIST = [
  { name: 'Attended', value: 'attended' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'No Show', value: 'no-show' },
  { name: 'Postponed', value: 'postponed' },
];

export const TASK_STATUS_LIST = [
  { name: 'To Do', value: 'to-do' },
  { name: 'In Progress', value: 'in-progress' },
  { name: 'Done', value: 'done' },
];

export const PRODUCT_TABLE = {
  NAME: 'Name',
  DEGREE_LEVEL: 'Degree Level',
  PERCENTAGE_REQUIREMENT: 'Percentage Requirement',
  GPA_REQUIREMENT: 'GPA Requirement',
  EACH_BAND: 'Each Band',
  OVERALL: 'Overall',
  REMARK: 'Remark',
  MARKS: 'Marks',
  LISTENING: 'Listening',
  WRITING: 'Writing',
  READING: 'Reading',
  SPEAKING: 'Speaking',
  PRODUCT_TYPE: 'Product Type',
};

export const ENGLISH_TEST_SCORE = {
  IELTS_MAXIMUM_VALUE: 9,
  PTE_MAXIMUM_VALUE: 90,
  TOEFL_MAXIMUM_VALUE: 30,
};

export const WORKFLOW_STAGE_TABS = {
  CHECKLIST: 'checklist',
  FORM: 'form',
};

export const PROJECT_PORTAL = {
  ADMIN: 'Admission ApplyIMS',
  REGISTRATION: 'Register ApplyIMS',
  AGENT: 'Agent ApplyIMS',
  CRM: 'CRM ApplyIMS',
  AGENT_TITLE: 'Agent',
  CRM_TITLE: 'CRM',
  ADMIN_TITLE: 'Admission',
  ADMIN_CONST: 'admin',
  AGENT_CONST: 'agent',
  CRM_CONST: 'crm',
};

export const CONTACT_DETAILS_TITLE = {
  EDIT_PERSONAL_INFO: 'Edit Personal Information',
  EDIT_ADDRESS_INFO: 'Edit Address Information',
  EDIT_PASSPORT_VISA_INFO: 'Edit Passport And Visa Information',
  EDIT_EMERGENCY_CONTACT_INFO: 'Edit Emergency Contact Information',
};

export const NOT_APPLICABLE = 'N/A';

export const CONTACT_FOLLOWER_DISABLE_MESSAGE =
  'Users who are Assigned an Application cannot be removed';

export const IMPORT_CONTACT = {
  title: 'Import Contact',
  UPLOAD_FILE: 'Upload File',
  UPLOAD_FILE_FORMAT: ".xlsx, .xls",
  SUPPORTED_FILE_FORMAT: "Supported File Formats",
  MAXIMUM_SIZE: "Maximum Size",
  UPLOAD_DESCRIPTION: "The file should contain field values that closely aligns with the format provided in the template.",
  PREVIEW: 'Preview',
  SELECT_HEADER_ROW: 'Select header row',
  PREVIEW_UPLOADED_DATA: 'Preview Uploaded Data',
  PREVIEW_STEP_DESC: 'This is the preview of the imported data that you have uploaded.',
  MATCH_COLUMNS: 'Match Columns',
  VALIDATE_DATA: 'Validate data',
  MATCH_STEP_DESCRIPTION: 'You can map the columns in your file with system fields. Unmapped columns will not be imported.',
  MATCH_STEP_WARNING: "The default setting doesn't import the data.",
  CONTACT_ATTRIBUTE: "Contact Attribute",
  FILE_HEADER: "File Header",
  SAMPLE_DATA_FROM_FILE: "Sample Data From File",
  VALIDATE_MESSAGE: "Validate and fix the rows with errors before proceeding with the file import.", 
  NO_VALIDATE_ERROR: "There are no errors in this table. You can import your file.",
  DELETE_SELECTED_ROWS: "Delete selected rows",
  SHOW_ONLY_ERROR_ROWS: "Show only rows with errors"
}

export const ImportContactRequiredFields = [
 {
        name: 'First Name',
        validations: ['Should Contain only alphabets', 'Should not be more than 50 characters long'],
        values: ['Austin', 'Sarah'],
    },
    {
        name: 'Last Name',
        validations: ['Should Contain only alphabets', 'Should not be more than 50 characters long'],
        values: ['Broom', 'Johnson'],
    },
    {
      name: 'Email',
      validations: ['The same email should not appear more than once in a file', 'Should be a valid email'],
      values: ['Austinbroom@gmail.com', 'johnsonsarah1@gmail.com']
    },
]

export const ImportContactOptionalFields = [  
    {
      name: 'Phone',
      validations: ['Should be a valid number and not include the country code', 'Should be a minimum of 10 digits and a maximum of 11 digits long'],
      values: ['9841895526', '9803225889'],
    },
    {
      name: 'Nationality',
      validations: ['Full name must be provided', 'Should be spelled correctly'],
      values: ['Nepal', 'Iran']
    },
    {
      name: 'Country Code',
      validations: ['Required only if phone number is entered and should be a valid country code', 'Should be a minimum of 1 digit and a maximum 4 digits long'],
      values: ['+977', '+98']
    },
    {
      name: 'Country Of Residence',
      validations: ['Full name must be provided', 'Should be spelled correctly'],
      values: ['Nepal', 'Iran']
    },
    {
      name: 'State',
      validations: ['Should not be more than 50 characters long'],
      values: ['Bagmati', 'Madhesh']
    },
    {
      name: 'City',
      validations: ['Should not be more than 50 characters long'],
      values: ['Kathmandu', 'Pokhara'],
    },
    {
      name: 'Date Of Birth',
      validations: ['Should be of the format DD/MM/YYYY'],
      values: ['06/05/1996', '22/04/1992']
    },
    {
      name: 'Zip Code',
      validations: ['Should contain only alphanumeric characters'],
      values: ['44600', '89556']
    },
    {
      name: 'Country Of Passport',
      validations: ['Full name must be provided', 'Should be spelled correctly'],
      values: ['United States of America', 'United Kingdom']
    },
    {
      name: 'Visa Expiry Date',
      validations: ['Should be of the format DD/MM/YYYY'],
      values: ['15/01/2026', '12/05/2029']
    }
]

export const SESSION_NOTE_REQUIRED = 'Please enter session note';