import React from 'react';
import { IOfficeDrawerComponent } from '@shared-components/models';
import { Button, DrawerElem } from '@shared-components/elements';
import { Space } from 'antd';
import {
  CANCEL_BTN,
  CREATE_OFFICE_TITLE,
  DRAWER_WIDTH,
  EDIT_OFFICE_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { OfficeBranchForm } from '../../forms/office-branch';

const OfficeDrawer: React.FC<IOfficeDrawerComponent> = ({
  visible,
  onClose,
  disabled,
  setDisabled,
  form,
  initialData,
  handleFormSubmit,
  handleFieldsChange,
  handleSubmit,
  isEdit,
  nameValidationStatus,
  setNameValidationStatus,
}: IOfficeDrawerComponent) => {
  return (
    <DrawerElem
      data-testid="crm-addbranchdrawer"
      visible={visible}
      onClose={onClose}
      title={isEdit ? EDIT_OFFICE_TITLE : CREATE_OFFICE_TITLE}
      width={DRAWER_WIDTH}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
              htmlType="submit"
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <OfficeBranchForm
        form={form}
        initialData={initialData}
        setDisabled={setDisabled}
        handleFieldsChange={handleFieldsChange}
        onSubmit={handleSubmit}
        nameValidationStatus={nameValidationStatus}
        setNameValidationStatus={setNameValidationStatus}
      />
    </DrawerElem>
  );
};

export { OfficeDrawer };
