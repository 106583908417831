import React, { useState } from 'react';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_OFFICE_BRANCH,
  TEXT,
} from '@moxie/constants';
import OfficeTableList from './office-table-list';
import { getOfficeList, getOneOfficeBranch } from '@crm/services.api';
import { OfficeDrawer } from './office-drawer';
import { useForm } from 'antd/lib/form/Form';
import { IOfficeBranch } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { officeBranchActions } from '@crm/core';
import { errorHandler } from '@moxie/utils';
import {
  changeNullToUndefined,
  PageHeaderTitle,
  popConfirm,
} from '@moxie/shared';
import { FormItemProps } from 'antd';
import { PageHeaderMenu } from '../components/page-header-menu';

const OfficeTableComponent: React.FC = () => {
  const [form] = useForm();
  const [initialData, setInitialData] = useState<IOfficeBranch>();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(true);
  const [nameValidationStatus, setNameValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const getData = async (params: string) => {
    return getOfficeList(params);
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const response = await getOneOfficeBranch(id ? id : '');
        setInitialData(response.data.data);
        setIsEdit(true);
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setInitialData({});
    }
    setVisible(true);
    setDisabled(true);
  };

  const handleClose = () => {
    setVisible(false);
    setDisabled(true);
    form.resetFields();
    setIsEdit(false);
    triggerRefresh();
  };

  const handleSubmit = async (data: IOfficeBranch) => {
    if (data.contact_person_id === undefined) data.contact_person_id = null;
    data.email = data?.email?.replace(/ /g, '');

    let formData;

    if (initialData?.id) {
      formData = {
        ...data,
        id: initialData.id,
        address: changeNullToUndefined({
          ...data.address,
          id: initialData?.address_id,
        }),
      };
      dispatch(officeBranchActions.updateRequest(formData));
      setIsEdit(false);
      triggerRefresh();
    } else {
      formData = data;
      await dispatch(officeBranchActions.addRequest(formData, triggerRefresh));
      triggerRefresh();
    }
    form.resetFields();
    setVisible(false);
    triggerRefresh();
  };

  const handleFormSubmitViaBtn = () => {
    form.submit();
    setDisabled(true);
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };
  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_BRANCH}`, async () => {
      try {
        dispatch(
          officeBranchActions.deleteOfficeBranchRequest(id, triggerRefresh)
        );
      } catch (err) {
        errorHandler(err);
      }
      triggerRefresh();
    });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.OFFICES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_OFFICE_BRANCH} />

      <OfficeDrawer
        visible={visible}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        initialData={initialData}
        handleFormSubmit={handleFormSubmitViaBtn}
        isEdit={isEdit}
        handleSubmit={handleSubmit}
        handleFieldsChange={handleFieldsChange}
        nameValidationStatus={nameValidationStatus}
        setNameValidationStatus={setNameValidationStatus}
      />

      <OfficeTableList
        getData={getData}
        showDrawer={showDrawer}
        deleteData={handleDelete}
        refreshTable={refreshTable}
      />
    </div>
  );
};

export { OfficeTableComponent };
