import * as action from './service.action';
import * as actionTypes from './service.constant';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getServiceList,
  getOneService,
  postService,
  updateService,
  deleteService,
  getAdminServiceList,
  syncServiceCreateApi,
  getServicesListForProduct,
} from '../../libs/services.api/lib';
import { errorHandler } from '@moxie/utils';
import { IAction, IService } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';

const getServices = function* () {
  try {
    const res: AxiosResponse = yield getServiceList();
    yield put(action.getServicesSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.getServiceFailure(e));
  }
};

const getServicesForProduct = function* () {
  try {
    const res: AxiosResponse = yield getServicesListForProduct();
    yield put(action.getProductServicesSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.getProductServiceFailure(e));
  }
};

const getAdminServices = function* () {
  try {
    const res: AxiosResponse = yield getAdminServiceList();
    yield put(action.getAdminServicesSuccess(res?.data.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.getAdminServicesFailure(e));
  }
};

const getService = function* ({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneService(payload);
      yield put(action.getOneSuccess(response.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getOneFailure(err));
  }
};

export const addService = function* ({
  payload,
  triggerRefresh,
}: IAction<IService>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postService(payload);
      yield put(action.addSuccess());
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addFailure(err));
  }
};

export const editService = function* ({
  payload,
  triggerRefresh,
}: IAction<IService>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateService(payload);
      yield put(action.updateSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateFailure(err));
  }
};

export const deleteServiceData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteService(payload);
      yield put(action.deleteServiceSuccess());
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.deleteServiceFailure(err));
  }
};

export const syncServiceCreate = function* ({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield syncServiceCreateApi(payload);
      yield put(action.syncServiceSuccess());
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
      yield put(action.getProductServicesRequest());
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.syncServiceFailure());
    yield put(action.getProductServicesRequest());
  }
};

export default function* rootSaga(): unknown {
  yield takeLatest(actionTypes.GET_SERVICES_REQUEST, getServices);
  yield takeLatest(
    actionTypes.GET_PRODUCT_SERVICES_REQUEST,
    getServicesForProduct
  );
  yield takeLatest(actionTypes.GET_ADMIN_SERVICES_REQUEST, getAdminServices);
  yield takeLatest(actionTypes.GET_SERVICE_REQUEST, getService);
  yield takeLatest(actionTypes.ADD_SERVICE_REQUEST, addService);
  yield takeLatest(actionTypes.SYNC_SERVICE_CREATE_REQUEST, syncServiceCreate);
  yield takeLatest(actionTypes.UPDATE_SERVICE_REQUEST, editService);
  yield takeLatest(actionTypes.DELETE_SERVICE_REQUEST, deleteServiceData);
}
