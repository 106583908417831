import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UserNameCard } from '@shared-components/elements';

const ApplicationReportAssignee: React.FC<any> = (props: any) => {
  const { data } = props;

  return (
    <div className="lead-table__archived-by">
      <Tooltip title="Assignee">
        {data?.length > 0 ? (
          <Avatar.Group maxCount={2} size="default">
            {data?.map((item: any) => {
              return (
                <Tooltip
                  key={item.id}
                  color="white"
                  title={
                    <UserNameCard
                      firstName={item.first_name || ''}
                      lastName={item.last_name}
                      index={item.id || ''}
                    />
                  }
                  placement="top"
                >
                  <Avatar>
                    {`${item.first_name
                      ?.substring(0, 1)
                      .toUpperCase()}${item.last_name
                      ?.substring(0, 1)
                      .toUpperCase()}`}
                  </Avatar>
                </Tooltip>
              );
            })}
          </Avatar.Group>
        ) : (
          <Avatar icon={<UserOutlined />} alt="assignee" />
        )}
      </Tooltip>
    </div>
  );
};

export { ApplicationReportAssignee };
