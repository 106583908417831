import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { authActions } from './auth.reducer';
import { IAction, ILogin } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import Cookies from 'js-cookie';
import {
  API_URL,
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
  CRM_EMAIL_LABEL,
} from '@moxie/constants';
import { login, logoutUser } from '@crm/services.api';
import { axiosInstance } from '../../libs/services.api/lib/axios';

const baseUrl = process.env.NX_BASE_URL;
const get_current_user_endpoint = `${baseUrl}${API_URL.GET_CURRENT_USER}`;

function* getCurrentUser(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    const res: any = yield call(axiosInstance.request, {
      url: `${get_current_user_endpoint}`,
      method: 'post',
    });
    localStorage.setItem('branchId', res?.data?.data?.branch_id);
    yield put(authActions.authUser(res?.data?.data));
  } catch (err) {
    errorHandler(err);
  }
}

function* logout(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    // TODO: Logic for logout.
    yield call(logoutUser);
    localStorage.removeItem(CRM_EMAIL_LABEL);
    localStorage.removeItem('branchId');
    sessionStorage.removeItem('accessToken');
  } catch (err) {
    errorHandler(err, false);
  }
}

function* loginAccount(
  data: IAction<ILogin>
): Generator<CallEffect<unknown> | PutEffect<IAction<ILogin>>> {
  try {
    yield put(authActions.loading(true));
    let response: any;
    if (data.payload) {
      response = yield call(login, data.payload);
    }

    Cookies.set(CRM_COOKIE_ACCESS, response.data.access_token);
    Cookies.set(CRM_COOKIE_REFRESH, response.data.refresh_token);
    yield put(authActions.setUser(response.data.user));
    yield put(authActions.closeSessionModal());
    yield put(authActions.loading(false));
  } catch (err) {
    errorHandler(err);
    yield put(authActions.loading(false));
  }
}

export default function* authSaga(): any {
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.login.type, loginAccount);
  yield takeLatest(authActions.getAuthUser.type, getCurrentUser);
}
