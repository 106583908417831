import { fetchPossibleUsersInContact } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";
import { useQuery } from "@tanstack/react-query";

export const useFetchAssignableUsers = (contactId: string | undefined) => {

    const { data, refetch } = useQuery({
      queryKey: ['contact-assignable-users', contactId],
      queryFn: () => fetchPossibleUsersInContact(contactId as string),
      onError: () => errorNotificationHandler('Error fetching users'),
    });
  
    return {
      users: data?.data ?? [],
      refetchUsers: refetch
    }
  }
