import React, { useEffect, useState } from 'react';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_WORKFLOW_TYPES,
  TEXT,
} from '@moxie/constants';
import { PageHeaderTitle, popConfirm } from '@shared-components/elements';
import { WorkflowTypeListTable } from './workflow-type-list-table';
import { WorkflowTypeDrawer } from './workflow-type-drawer';
import { serviceActions, useAppSelector, workflowTypeActions } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { IWorkflowType } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import { getOneCRMWorkflowType } from '@crm/services.api';
import { PageHeaderMenu } from '../components/page-header-menu';
import { useParams } from 'react-router-dom';

const WorkflowTypeTable: React.FC = () => {
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState<IWorkflowType>({});

  const { serviceId }: { serviceId: string } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(serviceActions.getOneRequest(serviceId));
  }, [dispatch]);

  const serviceData = useAppSelector(
    (state: RootState) => state.services.singleData
  );

  const showDrawer = async (id?: any) => {
    if (typeof id === 'string') {
      try {
        setIsEdit(true);
        const response = await getOneCRMWorkflowType(id);
        setInitialData(response.data.data);
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setInitialData({});
      setIsEdit(false);
    }
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
    form.resetFields();
  };
  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
  };

  const handleSubmit = async (data: IWorkflowType) => {
    let workflowTypeData;
    if (initialData.id) {
      workflowTypeData = {
        ...data,
        id: initialData.id,
      };
      await dispatch(
        workflowTypeActions.updateWorkflowTypeRequest(
          workflowTypeData,
          triggerRefresh
        )
      );
    } else {
      workflowTypeData = data;
      dispatch(
        workflowTypeActions.addWorkflowTypeRequest(data, triggerRefresh)
      );
    }
    handleClose();
  };

  const handleFormSubmit = () => {
    form.submit();
    setDisabled(true);
  };
  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  const deleteData = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_WORKFLOW_TYPE, () => {
      dispatch(
        workflowTypeActions.deleteWorkflowTypeRequest(id, triggerRefresh)
      );
    });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.WORKFLOW_TYPES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_WORKFLOW_TYPES} />

      <WorkflowTypeDrawer
        form={form}
        visible={visible}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        handleFormSubmit={handleFormSubmit}
        isEdit={isEdit}
        initialData={initialData}
        serviceData={serviceData}
        handleFieldsChange={handleFieldsChange}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      {serviceData && Object.keys(serviceData).length !== 0 ? (
        <WorkflowTypeListTable
          showDrawer={showDrawer}
          refreshTable={refreshTable}
          deleteData={deleteData}
        />
      ) : null}
    </div>
  );
};
export { WorkflowTypeTable };
