import { API_URL } from '@moxie/constants';
import { IInstitutionDetail, IResponse } from '@shared-components/models';
import { axiosInstance as axios } from './axios';

const getInstitutions = async (params = '') => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/${params}`);
};

const getInstitutionByService = async (service_id?: string) => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/byService/${service_id}`);
};

const apiGetInstitutionBranches = async (
  id: number,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/branches/${id}/${params}`);
};

const getImportInsitututions = async (params: any) => {
  return axios.get(`${API_URL.INSTITUTIONS_LIST}`, {
    params: {
      ...params,
      search: undefined,
      s: params.search,
      limit: 10,
    },
  });
};

const apiGetInstituteDetail = async (id: number): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/${id}`);
};
const getInstitutionDetail = async (id: string) => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/${id}`);
};

const getInstituteTypes = async () => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/types/all`);
};

const saveInstitution = async (body: IInstitutionDetail) => {
  return axios.post(`${API_URL.CRM_INSTITUTION}`, body);
};

const updateInstitution = async (body: IInstitutionDetail) => {
  return axios.put(`${API_URL.CRM_INSTITUTION}`, body);
};

const uploadLogo = async (body: any) => {
  return axios.post(`${API_URL.CRM_INSTITUTION}/upload`, body);
};
const getWorkflowsByInstitution = async (
  institution_id: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/workflows/${institution_id}`);
};
const deleteInstitution = async (id: number) => {
  return axios.delete(`${API_URL.CRM_INSTITUTION}/${id}`);
};

const checkInstitutionName = (name: string, id?: number): Promise<any> => {
  const params = {
    name: name,
    id: id,
  };
  return axios.get(`${API_URL.CRM_INSTITUTION}/check/name`, { params });
};

const getServices = (): Promise<IResponse> => {
  return axios.get(API_URL.CRM_SERVICES);
};

const getServiceWorkFlow = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${id}${API_URL.SERVICE}`);
};

const getWorkflow = (
  service_id: string,
  country: string
): Promise<IResponse> => {
  return axios.get(`crm/workflow/${service_id}/${country}`);
};

const fetchServiceCountry = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_SERVICES}/${id}/country`);
};

const importInstitution = (items: string[]): Promise<IResponse> => {
  return axios.post(`${API_URL.INSTITUTIONS}/imports`, items);
};

const getCRMWorkflowsByInstitutionId = async (
  id: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/workflows/${id}/${params}`);
};

export {
  getInstitutions,
  getInstitutionDetail,
  saveInstitution,
  getInstituteTypes,
  updateInstitution,
  deleteInstitution,
  uploadLogo,
  checkInstitutionName,
  getWorkflowsByInstitution,
  getInstitutionByService,
  getServices,
  getServiceWorkFlow,
  getWorkflow,
  fetchServiceCountry,
  apiGetInstituteDetail,
  getImportInsitututions,
  importInstitution,
  apiGetInstitutionBranches,
  getCRMWorkflowsByInstitutionId,
};
