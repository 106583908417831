import { useParams } from "react-router-dom";

// FIXME:: route param name should be change from id to contactId as same mutation is used in multiple places
const useContactParam = () => {
    const { id } = useParams<{ id: string }>()
    const { contactId } = useParams<{ contactId: string }>()

    return id ?? contactId;
}

export default useContactParam
