import {
  BankOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import {
  IELTS_SCORE,
  INDICATIVE_FEE,
  URL_PRODUCT_DETAIL_LINK,
  VIEW_DETAILS,
  regex,
} from '@moxie/constants';
import { capitalizeAllLetter, capitalizeFirstLetter } from '@moxie/shared';
import { Avatar, Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProductMenu } from './product-menu';
import Text from 'antd/lib/typography/Text';
import { ProductIntakeComponent } from './product-intake';

const ProductsCard = ({
  product,
  productId,
  setIsOpen,
  setIsEdit,
  setProductId,
  deleteProduct,
}: any) => {
  const intakes = product?.intakes?.replace(/{|}/g, '').split(',');
  const screenWidth = window.innerWidth;
  const displayBranch = product?.branches?.slice(0, 2);
  const additionalBranchesNumber = product?.branches?.length - 2;
  const additionalBranches = product?.branches?.slice(2);

  const showDurations = () => {
    return (
      <>
        <p>
          <span>
            {product.product_duration_year
              ? `${product.product_duration_year} yr`
              : null}{' '}
          </span>
          <span>
            {product.product_duration_month
              ? `${product.product_duration_month} mo`
              : null}{' '}
          </span>
          <span>
            {product.product_duration_week
              ? `${product.product_duration_week} wk`
              : null}{' '}
          </span>
        </p>
      </>
    );
  };

  return (
    /* Static values have been commented out for now */
    <>
      {/* <section className="margin-bottom-2 ">
        <Row className="page-header__menu">
          <Typography.Title level={4} className="text-primary initial_capital">
            {product?.product_name}
          </Typography.Title>
          <ProductMenu
            setIsOpen={setIsOpen}
            setIsEdit={setIsEdit}
            productId={productId}
            deleteProduct={() => deleteProduct(product.product_id)}
            setProductId={() => setProductId(product.product_id)}
          />
        </Row>
        <Row gutter={24}>
          <Col lg={6} md={7}>
            <div className="profile__info__item profile__info__user">
              <Avatar size="default" className="initial_capital margin-right-1">
                <BankOutlined className="product-institution-icon" />
              </Avatar>
              <div className="initial_capital">
                <Typography.Text className="name-fixed-width font-bold initial_capital">
                  {product?.institution_name}
                </Typography.Text>
                <LinkOutlined className="link-icon" />
                <div className="display-flex products-text">
                  <EnvironmentOutlined className="location-icon initial_capital" />
                  <Typography.Text className="margin-right-1">
                    {displayBranch?.map((branch: string, i: number) => (
                      <>
                        {i > 0 && ', '}
                        {branch}{' '}
                      </>
                    ))}
                    {additionalBranchesNumber > 0 && (
                      <Tooltip
                        title={
                          <p className="initial_capital">
                            {additionalBranches.join(', ')}
                          </p>
                        }
                      >
                        <p>+{additionalBranchesNumber}more</p>
                      </Tooltip>
                    )}
                  </Typography.Text>
                </div>
              </div>
            </div>
            <Row>
              <Col
                span={24}
                className="product-discipline margin-top-2 margin-bottom-1"
              >
                <p className="font-bold initial_capital">
                  {product?.product_type}
                </p>
                <p className="initial_capital">{product?.sub_type_name}</p>
              </Col>
            </Row>
            <GlobalOutlined className="margin-right-1 sub-text" />
            <Typography.Text className="products-text sub-text">
              Valid in all countries except India and China
            </Typography.Text>
          </Col>
          <Col lg={9} md={8} className="border-right">
            <Tag className="superagent-tag">Processed through Super-Agent</Tag>
            <Row>
              <div>
                <p className="margin-top-3 font-bold">IELTS Score</p>
                <p>
                  N/A
                  <Tooltip title="N/A">
                    <InfoCircleOutlined className="info-circle" />
                  </Tooltip>
                </p>
              </div>
              <Col className="margin-top-2 margin-right-3" offset={4}>
                <div className="product-type">
                  <CalendarOutlined className="product-type-icons" />
                  {displayMonths?.map((intake: string, i: number) => {
                    if (intake === 'NULL') {
                      return null;
                    } else {
                      return (
                        <span key={i}>
                          {i > 0 && ', '}
                          <span className="initial_capital">
                            {intake?.slice(0, 3)}&nbsp;
                          </span>
                        </span>
                      );
                    }
                  })}
                  {additionalMonthsNumber > 0 && (
                    <Tooltip
                      title={
                        <p className="initial_capital">
                          {additionalMonths.join(', ')}
                        </p>
                      }
                    >
                      <p>+{additionalMonthsNumber} more</p>
                    </Tooltip>
                  )}
                </div>
                <div className="product-type">
                  <HistoryOutlined className="product-type-icons" />
                  {product.product_duration_year ||
                  product.product_duration_month ||
                  product.product_duration_week
                    ? showDurations()
                    : 'N/A'}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            span={5}
            className="border-left ant-card-body-padding-1 border-right"
          >
            <Select
              className="align-items-center margin-top-1 margin-left-3 margin-right-3"
              placeholder="Select Fee Type"
            ></Select>
            <div className="product-fee">
              <Typography.Title level={4} className="text-primary">
                {product?.fees} {capitalizeAllLetter(product?.currency)}
              </Typography.Title>
              <InfoCircleOutlined className="info-circle" />
            </div>
            <p className="promotions-text">20% scholarship for July Intake</p>
            <Text
              className="promotions-text
          ellipsis-text"
              ellipsis={
                String('text').length >= 45 ? { tooltip: 'text' } : false
              }
            >
              {'text'}
            </Text>
            <Tooltip placement="topRight" title={'text'}>
              <p className="link-text text-primary">
                +2 more Active Promotions
              </p>
            </Tooltip>
          </Col>
          <Col span={4}>
            <div className="product-icons">
              <AppstoreAddOutlined />
              <HeartOutlined className="margin-left-3" />
            </div>
            <div>
              <Link to={`${URL_PRODUCT_DETAIL_LINK}${product.product_id}`}>
                <Button className="full-width margin-bottom-1 margin-top-2">
                  View Details
                </Button>
              </Link>
              <Button type="primary" className="full-width">
                {START_APPLICATION}
                </Button>
                </div>
                </Col>
                </Row>
                <Row />
                <div className="border-bottom margin-bottom-2 margin-top-2" />
              </section> */}

      <section className="margin-bottom-2 ">
        <Row className="page-header__menu">
          <Col>
            <Typography.Title
              level={4}
              className="text-primary ellipsis-text"
              ellipsis={
                String(product?.product_name).length >= 40
                  ? { tooltip: capitalizeFirstLetter(product?.product_name) }
                  : false
              }
            >
              {capitalizeFirstLetter(product?.product_name)}
            </Typography.Title>
          </Col>
          <Col>
            <ProductMenu
              setIsOpen={setIsOpen}
              setIsEdit={setIsEdit}
              productId={productId}
              deleteProduct={() => deleteProduct(product.product_id)}
              setProductId={() => setProductId(product.product_id)}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            <div className="profile__info__item profile__info__user">
              <Avatar size="default" className="margin-right-2">
                <BankOutlined className="product-institution-icon" />
              </Avatar>
              <div>
                {screenWidth >= 1200 && screenWidth < 1600 ? (
                  String(product?.institution_name).length >= 25 ? (
                    <Tooltip title={product?.institution_name}>
                      <Text className="font-bold institution-ellipsis" ellipsis>
                        {capitalizeFirstLetter(product?.institution_name) ??
                          'N/A'}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="font-bold">
                      {capitalizeFirstLetter(product?.institution_name) ??
                        'N/A'}
                    </Text>
                  )
                ) : screenWidth >= 1600 &&
                  String(product?.institution_name).length >= 30 ? (
                  <Tooltip
                    title={capitalizeFirstLetter(product?.institution_name)}
                  >
                    <Text className="font-bold institution-ellipsis" ellipsis>
                      {capitalizeFirstLetter(product?.institution_name) ??
                        'N/A'}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text className="font-bold">
                    {capitalizeFirstLetter(product?.institution_name) ?? 'N/A'}
                  </Text>
                )}

                <div className="products-branches">
                  <EnvironmentOutlined className="location-icon" />
                  {product?.branches
                    ? displayBranch?.map((branch: string, i: number) => (
                        <React.Fragment key={branch}>
                          <>
                            {i > 0 && ', '}
                            {branch?.length > 17 ? (
                              <Tooltip title={capitalizeFirstLetter(branch)}>
                                {branch.slice(0, 17) + '...'}
                              </Tooltip>
                            ) : (
                              capitalizeFirstLetter(branch)
                            )}
                            &nbsp;
                          </>
                        </React.Fragment>
                      ))
                    : 'N/A'}
                  {additionalBranchesNumber > 0 && (
                    <Tooltip
                      title={
                        <p className="initial_capital">
                          {additionalBranches?.join(', ')}
                        </p>
                      }
                    >
                      <span>
                        {additionalBranchesNumber >= 1
                          ? `+${additionalBranchesNumber} More`
                          : ''}
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <Row className="margin-left-3">
              <Col
                span={12}
                className="product-discipline margin-top-2 margin-bottom-1 margin-right-3 margin-left-2"
              >
                <p className="font-bold initial_capital">
                  {product?.product_sub_type ?? 'N/A'}
                </p>
                <p className="initial_capital">
                  {product?.product_type ?? 'N/A'}
                </p>
              </Col>
              <Col span={6}>
                <p className="margin-top-2 font-bold">{IELTS_SCORE}</p>
                <p>{product?.overall_score ?? 'N/A'}</p>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="border-right">
            <Row>
              <Col className="margin-top-2" offset={4}>
                <div className="product-type">
                  <CalendarOutlined className="product-type-icons" />
                  <ProductIntakeComponent product={product} intakes={intakes} />
                </div>
                <div className="product-type">
                  <HistoryOutlined className="product-type-icons" />
                  {product.product_duration_year ||
                  product.product_duration_month ||
                  product.product_duration_week
                    ? showDurations()
                    : 'N/A'}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            span={4}
            className="border-left ant-card-body-padding-1 border-right"
          >
            <div className="product-fee">
              <Typography.Text className="indicative-fee">
                {INDICATIVE_FEE}
              </Typography.Text>
              <Typography.Title level={4} className="margin-0">
                {product?.fees && parseFloat(product?.fees) !== 0 ? (
                  <div>
                    {product?.fees
                      ?.toString()
                      .replace(new RegExp(regex.FEES), ',')}{' '}
                    {capitalizeAllLetter(product?.currency)}
                  </div>
                ) : (
                  'Unavailable'
                )}
              </Typography.Title>
            </div>
          </Col>
          <Col span={4}>
            <div className="product-icons"></div>
            <div>
              <Link to={`${URL_PRODUCT_DETAIL_LINK}${product.product_id}`}>
                <Button className="full-width margin-top-3 view-details-button">
                  {VIEW_DETAILS}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row />
        <div className="border-bottom margin-bottom-2 margin-top-2" />
      </section>
    </>
  );
};

export default ProductsCard;
