import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  institutionActions,
  institutionBranchesActions,
  productActions,
  productTypeActions,
  useAppSelector,
  workflowActions,
} from '@crm/core';
import { GUTTER, PRODUCT_TITLE, SEARCH, TEXT } from '@moxie/constants';
import { Option, PageHeaderTitle } from '@shared-components/elements';
import { Button, Card, Col, Empty, Pagination, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Search from 'antd/lib/input/Search';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductsCard from './products-card';
import ProductFilter from './product-filter';
import { ProductsFormDrawer } from './products-form-drawer';
import { IProduct } from '../../../../../../libs/model/data.model';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useDeleteProduct } from '../hooks/useDeleteProduct';
import { ProductFilterContext } from '../provider/product-filter-provider';
import { ProductFormStateContext } from '../provider/product-form-state-provider';
import { useGetProductById } from '../hooks/useGetProductById';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const ProductsListComponent: React.FC = () => {
  const [productId, setProductId] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [initialData, setInitialData] = useState<IProduct>();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    filter,
    countriesLoading,
    handleServiceChange,
    selectedService,
    countries,
    selectedCountry,
    onSearch,
    searchInput,
    search,
    onSearchChange,
    onCountryChange,
  } = useContext(ProductFilterContext);

  const { setSelectedInstitution } = useContext(ProductFormStateContext);
  const canAccess = useCanAccess();

  const dispatch = useDispatch();
  const productsList = useAppSelector((state) => state.products.products);
  const productsListLoading = useAppSelector((state) => state.products.loading);
  const services = useAppSelector((state) => state.services.allData);
  const servicesLoading = useAppSelector((state) => state.services.loading);
  const { data } = useGetProductById(productId);

  const { updateProduct } = useUpdateProduct(
    productId,
    currentPage,
    search,
    selectedService,
    selectedCountry
  );

  const { deleteProduct } = useDeleteProduct(
    currentPage,
    search,
    selectedService,
    selectedCountry
  );

  const showDrawer = async (id?: string) => {
    if (id) {
      setIsEdit(true);
    } else {
      dispatch(institutionActions.clearFetchInstitution());
      dispatch(institutionBranchesActions.clearBranchFetch());
      dispatch(workflowActions.clearCRMWorkflowFetch());
      dispatch(productTypeActions.clearProductTypeFetch());
      dispatch(productTypeActions.clearProductSubTypeFetch());
      form.resetFields();
      setIsEdit(false);
    }
    setIsOpen(true);
  };

  const onClose = () => {
    if (isEdit && !data) {
      setIsOpen(false);
      setDisabled(true);
      form.resetFields();
    } else {
      dispatch(institutionActions.clearFetchInstitution());
      dispatch(institutionBranchesActions.clearBranchFetch());
      dispatch(workflowActions.clearCRMWorkflowFetch());
      dispatch(productTypeActions.clearProductTypeFetch());
      dispatch(productTypeActions.clearProductSubTypeFetch());
      form.resetFields();
      setIsEdit(false);
      setIsOpen(false);
      setDisabled(true);
    }
  };

  const triggerRefresh = () => {
    dispatch(
      productActions.getProductsRequest({
        page: currentPage,
        s: search,
        service: selectedService,
        country: selectedCountry,
        limit: 10,
      })
    );
  };

  const handleSubmit = async (data: any) => {
    if (isEdit) {
      updateProduct({ ...data, id: productId });
    } else {
      dispatch(productActions.addProductRequest(data, triggerRefresh));
    }
    triggerRefresh();
    onClose();
  };

  useEffect(() => {
    if (selectedService) {
      dispatch(
        productActions.getProductsRequest({
          page: currentPage,
          s: search,
          service: selectedService,
          country: selectedCountry,
          limit: 10,
          ...filter,
        })
      );
    }
  }, [currentPage, search, selectedService, selectedCountry, filter]);

  useEffect(() => {
    if (data && isEdit) {
      form.setFieldsValue({
        id: productId,
        name: data.name,
        service: data.institution.service_id,
        institution_id: data.institution.id,
        branches: data.institutionBranches.map(
          (item: any) => item.institution_branch_id
        ),
        workflows: data.institution_workflows.map(
          (item: any) => item.workflow.workflow_type_id
        ),
        identification_system_code: data.identification_system_code,
        product_type_id: data.productType.id,
        product_sub_type_id: data.product_sub_type_id,
        revenue_type: data.revenue_type,
        subject_disciplines_id: data.subject_disciplines?.id,
        subject_area_id: data.subject_area?.id,
        product_duration_year: data.product_duration_year,
        product_duration_month: data.product_duration_month,
        product_duration_week: data.product_duration_week,
        intakes: data.institutionBranches[0]?.intakes.map(
          (item: any) => item.intake
        ),
        program_link: data.program_link,
        description: data.description,
      });
      setSelectedInstitution(data?.institution?.id);
    } else {
      setSelectedInstitution(undefined);
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (selectedService) {
      dispatch(
        productActions.getProductsRequest({
          page: currentPage,
          s: search,
          service: selectedService,
          country: selectedCountry,
          limit: 10,
          ...filter,
        })
      );
    }
  }, [currentPage, search, selectedService, selectedCountry, filter]);

  return (
    <Spin spinning={productsListLoading}>
      <div className="full-height">
        <PageHeaderTitle title={PRODUCT_TITLE} />
        <ProductsFormDrawer
          productId={productId}
          isOpen={isOpen}
          form={form}
          isLoading={servicesLoading}
          handleSubmit={handleSubmit}
          initialData={initialData}
          onClose={onClose}
          isEdit={isEdit}
          disabled={disabled}
          setDisabled={setDisabled}
          formData={FormData}
        />

        <Row gutter={GUTTER}>
          <Col span={6}>
            <ProductFilter />
          </Col>

          <Col span={18} className=" bg-white padding-top-2 border-1-px">
            <Row justify="space-between">
              <Col span={20}>
                <Row>
                  <Col>
                    <Select
                      onChange={handleServiceChange}
                      className="product-filter-selection margin-right-1 initial_capital"
                      showSearch
                      optionFilterProp="search_prop"
                      placeholder="Select service"
                      value={servicesLoading ? 'Loading..' : selectedService}
                    >
                      {servicesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        services?.map((service: any, i: number) => (
                          <Option
                            className="initial_capital"
                            value={service.id}
                            key={i}
                          >
                            {service.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      className="product-filter-selection"
                      onChange={(value) => onCountryChange(value)}
                      showSearch
                      optionFilterProp="search_prop"
                      placeholder="Select country"
                      value={selectedCountry}
                      loading={countriesLoading}
                    >
                      {countriesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        countries?.map((country: any, i: number) => (
                          <Option value={country.name} key={i}>
                            {country.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Col>
                  <Col className="flex-grow-1">
                    <Search
                      data-testid="productsearch"
                      placeholder={SEARCH}
                      className="product_search margin-left-1"
                      onSearch={onSearch}
                      value={searchInput}
                      onChange={(e) => onSearchChange(e.target.value)}
                      allowClear
                    />
                  </Col>
                </Row>
              </Col>
              {canAccess('product','create') && <Button
                type="primary"
                data-testid="crm-addproductbutton"
                onClick={() => showDrawer()}
              >
                <PlusOutlined /> {TEXT.ADD_PRODUCT}
              </Button>}
            </Row>
            {productsList.data?.length > 0 ? (
              <Card bordered={true} className="margin-top-2 product-card">
                {productsList?.data?.map((product: any, i: number) => (
                  <ProductsCard
                    key={i}
                    product={product}
                    productId={productId}
                    setProductId={setProductId}
                    setIsOpen={setIsOpen}
                    setIsEdit={setIsEdit}
                    setInitialData={setInitialData}
                    deleteProduct={deleteProduct}
                  />
                ))}
              </Card>
            ) : (
              <Card className="margin-top-2">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={TEXT.NO_PRODUCTS_FOUND}
                />
              </Card>
            )}

            <Row justify="end" className="margin-top-2 margin-bottom-2">
              {Number(productsList?.meta?.total) > 1 ? (
                <Pagination
                  onChange={(value) => setCurrentPage(value)}
                  defaultCurrent={currentPage}
                  total={productsList?.meta?.data_count}
                  showSizeChanger={false}
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
export { ProductsListComponent };
