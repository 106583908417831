import { ApplicationAssigneePayload, BasicNotificationPayload, ContactOfficeVisitPayload, IAction, Notification } from '@shared-components/models';
import { socketSagaActions } from './socket.action';
import { Socket as WsSocket } from 'socket.io-client';
import { ActivitiesType, ActivityAction } from '@model/contact-activity';
import { ContactBranchPayload } from '../../../../../libs/model/notification';

export const SOCKET_FEATURE_KEY = 'socket';

interface ProfileUpdatedPayload {
  activities_type_id?: string;
  activities_type: ActivitiesType;
  crm_user_id?: string,
  activities_action: ActivityAction;
  previous_assigned_user_id?: string | null;
  assigned_user_id?: string | null;
  contact_id?: string;
  company_id?: string;
  data: unknown;
  document_type?: string;
}

interface FollowersUpdatedPayload {
  contact_id?: string;
  company_id?: string;
  crm_user_id?: string,
  data: unknown;
  addedFollowers: string[];
  removedFollowers: string[];
  remainingFollowers: string[];
}

export interface ContactPayload extends BasicNotificationPayload {
  removed_assignee: string | null;
  crm_receiver_id: string | null;
}

interface ServerToClientEvents {
  'client::update-activity-logs': (contact_id: string) => void
  'client::update-application-logs': (application_id: string) => void,
  'client::notification-updated': (notification: Notification) => void
}

interface ClientToServerEvents {
  'server::followers-updated': (payload: FollowersUpdatedPayload) => void
  'server::profile-updated': (payload: ProfileUpdatedPayload) => void
  'server::application-updated': (payload: ProfileUpdatedPayload) => void
  'server::join-room': (payload: { userId: string, socketId: string }) => void
  'server::assignee-updated': (payload: ContactPayload) => void;
  'server::basic-detail-updated': (payload: BasicNotificationPayload) => void
  'server::application-assignee-updated': (payload: ApplicationAssigneePayload) => void;
  'server::office-visit-updated': (payload: ContactOfficeVisitPayload) => void;
  'server::branches-updated': (payload: ContactBranchPayload) => void;
}

export type Socket = WsSocket<ServerToClientEvents, ClientToServerEvents>;
export interface SocketState {
  wss: Socket | null;
}

export const initialSocketState: SocketState = {
  wss: null,
};
export const socketReducer = (
  state = initialSocketState,
  action: IAction<any>
): SocketState => {
  switch (action.type) {
    case socketSagaActions.SET_SOCKET_CONNECTION:
      return {
        wss: action.payload,
      };
    case socketSagaActions.REMOVE_SOCKET_CONNECTION:
      return {
        wss: null,
      };

    default:
      return state;
  }
};
