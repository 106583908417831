import { ASSIGNEE_LABEL, LABEL, TEXT, URL_DETAIL_LINK } from '@moxie/constants';
import { ILead, IOfficeBranch } from '@shared-components/models';
import { Col, Row, Typography } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { formatDateWithTimezone } from '../../functions';
import { Table } from '../../shared';
import { LeadTableAssigneeCard } from '../contact-add-edit/contact-table-assignee-card';
import { ContactTableElementCard } from '../contact-add-edit/table-elements';
import { useAppSelector } from '@crm/core';
import { useAssignedBranch, useContactBranchFilter } from '@crm/libs/hooks/contacts';
import SelectBranch from 'libs/shared/src/crm-modules/client/select-branch';

const { Text } = Typography;

const ProspectTableList = ({
  getData,
  refresh,
  filterOptions,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: any) => {
  const currentUser = useAppSelector(store => store.auth.user);
  const { filter, handleBranchChange } = useContactBranchFilter();
  const branchesProps = useAssignedBranch()

  const prospectColumns = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internal_id',
      render: (value: string, data: ILead): ReactNode => {
        return (
          <Link to={`${URL_DETAIL_LINK}${data?.id}/applications`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.company_code + '-' + value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'first_name',
      width: 150,
      sorter: () => 0,
      render: (_text: string, row: ILead, index: number): ReactElement => (
        <ContactTableElementCard
          id={row.id}
          firstName={row.first_name || ''}
          email={row.email || ''}
          lastName={row.last_name}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
        />
      ),
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      render: (_text: string, row: ILead): ReactNode => {
        return (
          <Row className="lead-table__phone">
            <Col>
              {row?.country_code}&nbsp;
              {row?.phone}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.BRANCH,
      dataIndex: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'assignee_user_id',
      width: 150,
      render: (text: string, data: ILead): ReactElement => {
        return (
          <LeadTableAssigneeCard data={data} triggerRefresh={triggerRefresh} />
        );
      },
    },
    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updated_at',
      sorter: () => 0,
      render: (text: string, { user_timezone }: { user_timezone: string }) => {
        return (
          <div className="lead-table__created-at">
            <span>
              {user_timezone && formatDateWithTimezone(text, user_timezone)}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <Table
        filterContent={<SelectBranch user={currentUser} onChange={handleBranchChange} {...branchesProps} />}
        filterOptions={filterOptions}
        tableName={TEXT.PROSPECTS}
        refresh={refresh}
        size={'small'}
        columns={prospectColumns}
        triggerRefresh={triggerRefresh}
        action={getData}
        filter={filter}
        className="lead-table"
      />
    </div>
  );
};

export { ProspectTableList };
