/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { EditOutlined } from '@ant-design/icons';
import { leadActions, useAppSelector } from '@crm/core';
// import Can, { PermissionAction } from '@lyra/privilege';
import {
  CANCEL_BTN,
  CLOSE_BTN,
  CREATE_NEW_LEAD,
  EDIT,
  EDIT_LEAD_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import {
  DataAsKeyInResponse,
  IAddEditProps,
  IContact,
  ILead,
} from '@shared-components/models';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { LeadForm } from '../forms/lead';
import { RootState } from 'apps/crm/src/core/store';
import isEqual from 'lodash/isEqual';

const LeadAddEdit: React.FC<IAddEditProps<any>> = ({
  isOpen,
  onClose,
  submitted,
  setSubmitted,
  initialData,
  isView,
  disabled,
  setDisabled,
  handleView,
  triggerRefresh,
  isEdit,
}: IAddEditProps<any>) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const socket = useAppSelector((state) => state.socket.wss);

  const currentUser = useAppSelector((store: RootState) => store.auth.user);

  const handleSubmitButtonClick = async () => {
    try {
      setDisabled?.(true);
      setSubmitted?.(true);
      const validateFields = await form.validateFields();
      validateFields && form.submit();
    } catch (err) {
      setSubmitted?.(false);
    }
  };

  const handleSubmit = async (data: ILead) => {
    if (isEdit) {
      const prevData = { ...initialData };

      const prevFormValues = {
        first_name: prevData.first_name,
        last_name: prevData.last_name,
        email: prevData.email,
        phone: prevData.phone,
      };

      const updatedFormValues = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
      };
      dispatch(
        leadActions.editLeadAction(
          { id: initialData.id, ...data },
          (res: DataAsKeyInResponse<IContact>) => {
            if (triggerRefresh) {
              if (triggerRefresh) {
                // TODO:: rename variable
                res.data.crm_assignee_id = data.assignee_user_id;
                data.crm_assignee_id = data.assignee_user_id;
                triggerRefresh(initialData.assignee_user_id, res);

                if (!isEqual(updatedFormValues, prevFormValues)) {
                  socket?.emit('server::basic-detail-updated', {
                    company_id: res.data.company?.id as string,
                    contact_id: res.data?.id as string,
                    crm_user_id: currentUser?.id as string,
                  });
                }

                if (data.crm_assignee_id !== prevData.crm_assignee_id) {
                  socket?.emit('server::assignee-updated', {
                    company_id: res.data.company?.id as string,
                    contact_id: res.data?.id as string,
                    crm_user_id: currentUser?.id as string,
                    crm_receiver_id:
                      data.crm_assignee_id !== ''
                        ? (data.crm_assignee_id as string)
                        : null,
                    removed_assignee: prevData.crm_assignee_id ?? null,
                  });
                }
              }
            }
          }
        )
      );
    } else {
      dispatch(
        leadActions.addLeadRequest(data, (res) => {
          if (triggerRefresh) {
            triggerRefresh();
          }

          socket?.emit('server::assignee-updated', {
            company_id: currentUser?.company_id as string,
            contact_id: res.data?.id as string,
            crm_user_id: currentUser?.id as string,
            crm_receiver_id: res.data?.crm_assignee_id as string,
            removed_assignee: null,
          });
        })
      );
    }
    onClose();
  };

  const fields = form.getFieldsError();

  const hasError = useMemo(() => {
    return fields.some((field) => field.errors.length);
  }, [fields]);

  return (
    <DrawerElem
      title={
        <div>
          {initialData?.id
            ? isView
              ? `View Contact`
              : EDIT_LEAD_TITLE
              ? `View Contact`
              : EDIT_LEAD_TITLE
            : CREATE_NEW_LEAD}

          {isView && handleView && (
            <>
              {
                // Can(
                //   PermissionAction.Update,
                //   new ContactsEntity({
                //     assignee_id: initialData.assignee_id,
                //     followers: initialData.followers?.map((item: any) => item.id),
                //   })
                // ) &&
                !initialData?.archived && (
                  <Button
                    type="primary"
                    onClick={() => handleView(false)}
                    icon={<EditOutlined />}
                    className="edit-action"
                  >
                    {EDIT}
                  </Button>
                )
              }
            </>
          )}
        </div>
      }
      width={720}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {isView ? CLOSE_BTN : CANCEL_BTN}
            </Button>
            {!isView && (
              <Button
                onClick={handleSubmitButtonClick}
                type="primary"
                disabled={disabled || hasError}
              >
                {initialData?.id ? UPDATE_BTN : SAVE_BTN}
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <Spin spinning={submitted}>
        <LeadForm
          form={form}
          isView={isView}
          onSubmit={handleSubmit}
          initialData={initialData}
          setDisabled={setDisabled}
          key={isOpen?.toString()}
        />
      </Spin>
    </DrawerElem>
  );
};

LeadAddEdit.defaultProps = {
  isView: false,
};
export { LeadAddEdit };
