import React, { useEffect, useState } from 'react';
import { Form, Input, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, FormItem } from '@shared-components/elements';
import {
  PASSWORD_LABEL,
  PASSWORD_PLACEHOLDER,
  CONFIRM_PASSWORD_LABEL,
  CONFIRM_PASSWORD_PLACEHOLDER,
  FORM_SIZE,
  CONFIRM_PASSWORD_DIDNT_MATCH,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_REQUIRED,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  TEXT,
  LABEL,
  URL_VERIFY_ACCOUNT_SUCCESS,
  URL_LOGIN,
  PLEASE_SET_YOUR_PASSWORD,
  PROJECT_PORTAL,
  URL_CRM_VERIFY_ACCOUNT_SUCCESS,
} from '@moxie/constants';
import { IAuth } from '@shared-components/models';
import { errorHandler, useQuery } from '@moxie/utils';
import { useHistory } from 'react-router-dom';
import { resendVerifyAccountLink, verifyAccount } from '@lyra/services.api';
import { ApplyIMSLogoDark, successNotificationHandler } from '@moxie/shared';
import jwt_decode from 'jwt-decode';
import {
  resendVerifyAdminAccountLink,
  verifyAdminAccount,
} from '@admin/services.api';
import { resendVerifyCrmAccountLink, verifyCrmAccount } from '@crm/services.api';

const { Title, Paragraph, Text } = Typography;

const VerifyAccountForm: React.FC<any> = ({ portal }: { portal: string }) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const [token] = useState(query.get('token'));
  const [user, setUser] = useState<{
    first_name: string;
    email: string;
    domain: string;
  } | null>(null);
  const handleSubmit = async (data: IAuth) => {
    setLoading(true);
    data.token = token;
    try {
      const responseData = {
        token: token,
        email: user?.email,
        password: data.password,
      };

      const res =
        portal === PROJECT_PORTAL.AGENT_TITLE
          ? await verifyAccount(responseData)
          : portal === PROJECT_PORTAL.CRM_TITLE
            ? await verifyCrmAccount(responseData)
            : await verifyAdminAccount(responseData);
      setLoading(false);

      if (res.data.success) {
        const companyDomain = user?.domain || '';
        localStorage.setItem(LABEL.DOMAIN, companyDomain);
        if (portal === PROJECT_PORTAL.AGENT_TITLE) {
          history.push(URL_VERIFY_ACCOUNT_SUCCESS);
        }
        if (portal === PROJECT_PORTAL.CRM_TITLE) {
          history.push(URL_CRM_VERIFY_ACCOUNT_SUCCESS);
        }
        if (portal === PROJECT_PORTAL.ADMIN_TITLE) {
          history.push(URL_VERIFY_ACCOUNT_SUCCESS);
        }
      }
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  };

  const handleResendVerifyLink = async () => {
    if (!user) return;
    try {
      const data = {
        email: user?.email,
        portal,
      };
      const res =
        portal === PROJECT_PORTAL.AGENT_TITLE
          ? await resendVerifyAccountLink(data)
          : portal === PROJECT_PORTAL.CRM_TITLE
            ? await resendVerifyCrmAccountLink(data)
            : await resendVerifyAdminAccountLink(data);
      setLoading(false);
      if (res.data.success) {
        successNotificationHandler(TEXT.EMAIL_SENT_SUCCESSFUL);
      }
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  };

  useEffect(() => {
    if (!token) history.push(URL_LOGIN);
    const getUser = async (): Promise<void> => {
      try {
        const verifyToken: {
          email: string;
          first_name: string;
          domain: string;
        } = await jwt_decode(token);
        const userObj = {
          first_name: verifyToken?.first_name,
          email: verifyToken?.email,
          domain: verifyToken?.domain,
        };
        setUser(userObj);
      } catch (err) {
        errorHandler(err);
        history.push(URL_LOGIN);
      }
    };
    getUser();
    return () => {
      setUser(null);
    };
  }, [token, setUser]);

  return (
    <>
      <div className="padding-top-2 padding-bottom-2 text-align-center">
        <div className="card-logo-title-container">
          <ApplyIMSLogoDark />
          <Text className="card-logo-title">{portal}</Text>
        </div>
        <Title level={4} className="margin-0">
          {`${portal === PROJECT_PORTAL.ADMIN_TITLE
            ? TEXT.WELCOME_TO_ADMIN
            : TEXT.WELCOME
            }, ${user?.first_name} !`}
        </Title>
        <Title level={5} className="margin-0">
          {PLEASE_SET_YOUR_PASSWORD}
        </Title>
      </div>
      {user && (
        <>
          <Form
            layout="vertical"
            size={FORM_SIZE}
            name="login-form"
            data-testid="auth-form"
            className="auth-layout__form"
            onFinish={handleSubmit}
            initialValues={{
              email: user?.email,
            }}
          >
            <div className="header">
              {user && (
                <FormItem
                  name="email"
                  label={EMAIL_LABEL}
                  className="lyra-label"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    disabled
                    autoComplete="new-email"
                    placeholder={EMAIL_PLACEHOLDER}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </FormItem>
              )}
              <FormItem
                name="password"
                label={PASSWORD_LABEL}
                className="lyra-label"
                rules={[{ required: true, message: PASSWORD_REQUIRED }]}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder={PASSWORD_PLACEHOLDER}
                  prefix={<LockOutlined />}
                />
              </FormItem>
              <FormItem
                name="passwordConfirm"
                label={CONFIRM_PASSWORD_LABEL}
                className="lyra-label"
                rules={[
                  { required: true, message: CONFIRM_PASSWORD_REQUIRED },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(CONFIRM_PASSWORD_DIDNT_MATCH)
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder={CONFIRM_PASSWORD_PLACEHOLDER}
                  prefix={<LockOutlined />}
                />
              </FormItem>
            </div>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              className="btn__setPassword"
            >
              {TEXT.SET_PASSWORD}
            </Button>
          </Form>
          <div className="padding-top-2">
            <Paragraph
              className="cursor-pointer"
              onClick={handleResendVerifyLink}
              type="secondary"
            >
              {TEXT.RESEND_EMAIL_VERIFICATION}
            </Paragraph>
          </div>
        </>
      )}
    </>
  );
};

export { VerifyAccountForm };
