import React, { useState } from 'react';
import Switch from 'antd/es/switch';
import {
  useFetchNotifications,
  useToggleNotificationStatusMutation,
  useUpdateAllNotificationAsReadMutation,
} from '@crm/libs/hooks/users';
import { Notification as INotification } from '@model/notification';
import Button from 'antd/es/button';
import Empty from 'antd/es/empty';
import { TEXT, URL_NOTIFICATION } from '@moxie/constants';
import Notification from './notification';
import { Col, Divider, Row, Typography } from 'antd';

const NotificationMenu = (): JSX.Element => {
  const limit = 10;
  const [showUnRead, setUnRead] = useState(false);
  const { notifications } = useFetchNotifications(limit);

  const updateNotificationAsReadMutation = useUpdateAllNotificationAsReadMutation();
  const toggleMutation = useToggleNotificationStatusMutation();

  const filterNotifications = React.useMemo(() => {
    if (showUnRead) {
      return notifications.filter(
        (notification: INotification) => !notification.is_read
      );
    }
    return notifications;
  }, [showUnRead, notifications]);

  const markNotificationAsRead = (notification_id: string) => {
    toggleMutation.mutate({
      is_read: true,
      notification_id,
    });
  };
  const markAllNotificationAsRead = () => {
    updateNotificationAsReadMutation.mutate();
  };

  const toggleNotificationStatus = (
    notification_id: string,
    is_read: boolean
  ) => {
    toggleMutation.mutate({
      is_read: !is_read,
      notification_id,
    });
  };

  return (
    <Row className="notification-menu">
      <Row className="padding-1">
        <Typography.Title level={5}>{TEXT.NOTIFICATIONS}</Typography.Title>
        <Divider className="notification-divider" />
      </Row>
      {
        notifications.length ? (
          <Row
            justify="space-between"
            className="padding-left-1 padding-right-1 padding-bottom-1"
          >
            <Col>
              <Button
                type="text"
                className="text-primary"
                size="small"
                onClick={markAllNotificationAsRead}
              >
                Mark all as read
              </Button>
            </Col>
            <Col>
              <div className="notification-menu__read">
                <span>Only show unread</span>
                <Switch
                  checked={showUnRead}
                  size="small"
                  onChange={() => setUnRead(!showUnRead)}
                />
              </div>
            </Col>
          </Row>
        )
          :
          null
      }

      {!filterNotifications.length ? (
        <Row align="middle" justify="center">
          <Empty
            description="No Notifications"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </Row>
      ) : (
        <Row id="scrollNotification" >
            {filterNotifications.map(
                (notification: INotification, index: number) => {
                  return (
                    <Notification
                      key={index}
                      item={notification}
                      notifications={filterNotifications}
                      handleMarkReadUnread={toggleNotificationStatus}
                      handleMarkRead={markNotificationAsRead}
                    />
                  );
                }
              )}
        </Row>
      )}

      <Divider className="margin-0" />

      <Row className="notification-menu__footer">
        <a
          href={`${window.location.origin}${URL_NOTIFICATION}`}
          className="margin-auto"
        >
          <Button
            type="text"
            color="primary"
            className="ant-btn-ghost crm-view-all-notification-btn"
          >
            View All Notifications
          </Button>
        </a>
      </Row>
    </Row>
  );
};

export default NotificationMenu;
