import {
  CaretRightOutlined,
  ClockCircleOutlined,
  EditOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { officeVisitActions, serviceActions, useAppSelector } from '@crm/core';
import { fetchPrevVisit } from '@crm/services.api';
import {
  ACTIVITY_LOG,
  ASSIGNEE_LABEL,
  ASSIGN_TO_BTN,
  CANCEL_BTN,
  CONTACT_VISIT_RESPONSE_MESSAGE,
  DRAWER_WIDTH,
  FORM_SIZE,
  GUTTER,
  LABEL,
  PREVIOUS_VISITS,
  SESSION_NOTE_REQUIRED,
  UNASSIGNED_LABEL,
  UPDATE_BTN,
  URL_DETAIL_LINK,
  USER_ADD_PLACEMENT,
  VISIT_DETAILS,
  VISIT_PURPOSE,
} from '@moxie/constants';
import {
  DrawerElem,
  TableUserNameCard,
  UnassignedCard,
  UserSelectBox,
} from '@shared-components/elements';
import { Editor } from '@shared-components/forms';
import {
  IOfficeVisit,
  IOfficeVisitDetailsDrawerComponent,
} from '@shared-components/models';
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Form,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OfficeVisitActivityLog } from './office-visit-activity-log';
import { UserCard } from './user-card';
import { Link } from 'react-router-dom';
import { useFetchPrimaryBranchUsers } from '../hook/useFetchPrimaryBranchUsers';
import { Countdown } from './office-visit-table-column';
import { errorNotificationHandler } from 'libs/shared/src/functions';
import { removeDefTags } from './removeTagFn';

const antIcon = <LoadingOutlined className="font-large" spin />;

const WaitAfterSessionStart = ({
  session_start,
  created_at,
}: {
  session_start: Date | moment.Moment | undefined;
  created_at: Date;
}) => {
  const difference = moment.duration(
    moment(session_start).diff(moment(created_at))
  );
  return (
    <>
      <Typography.Text>
        {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
        {difference.get('m')}m
      </Typography.Text>
    </>
  );
};

const OfficeVisitDetailsDrawer: React.FC<IOfficeVisitDetailsDrawerComponent> = ({
  visible,
  onClose,
  setDisabled,
  form,
  submitForm,
}: IOfficeVisitDetailsDrawerComponent) => {
  const { initialData, isInitialDataLoading } = useAppSelector((state) => ({
    initialData: state.office_visits.singleData,
    isInitialDataLoading: state.office_visits.loading,
  }));

  const [isEditing, setIsEditing] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [prevVisits, setPrevVisits] = useState([]);
  const [prevVisitsLoading, setPrevVisitsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [noteDisabled, setNoteDisabled] = useState<boolean>(true);

  const { users } = useFetchPrimaryBranchUsers();

  const { user, currentUserBranchId } = useAppSelector((state) => ({
    user: state.auth.user,
    currentUserBranchId: state.auth.user?.branch_id,
  }));

  const dispatch = useDispatch();

  const socket = useAppSelector((state) => state.socket.wss);
  const branches = useAppSelector((state) => state.office_branches.allData);

  const checkCanAttendEndSession = (): boolean => {
    if (currentUserBranchId === initialData?.branch_id) {
      return !(user?.id === initialData?.assignee?.id);
    }
    return true;
  };

  const editNotesClick = () => {
    setIsEditing(true);
  };

  const handleEditNotes = (data: any) => {
    if (data && noteValue !== data) {
      setNoteValue(data);
      setNoteDisabled(false);
    } else {
      setNoteDisabled(true);
    }
  };

  const cancelEditor = () => {
    setNoteDisabled(true);
    setIsEditing(false);
  };

  const handleSaveNotes = () => {
    const sessionNote = removeDefTags(noteValue);
    if (sessionNote.length === 0) {
      setNoteDisabled(true);
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(initialData.id, {
          session_notes: noteValue,
        })
      );
      setIsEditing(false);
      dispatch(officeVisitActions.getOfficeVisitRequest(initialData.id));
    }
  };

  const handleAttendSession = () => {
    const updateData: IOfficeVisit = {
      status: 'Attending',
      session_start: moment(),
      assignee_id: user?.id,
    };
    dispatch(
      officeVisitActions.updateOfficeVisitRequest(
        initialData.id,
        updateData,
        () => {
          triggerRefresh();
          socket?.emit('server::office-visit-updated', {
            assignee: initialData.assignee.id as string,
            branch: branches.find(
              (branch) => branch.id === initialData.branch_id
            )?.name as string,
            contact_id: initialData.contact_id,
            crm_user_id: user?.id as string,
            type: 'attended',
          });
        }
      )
    );
    dispatch(officeVisitActions.closeOfficeVisitDetailsDrawer());
  };

  const handleEndSession = async () => {
    const sessionNote = removeDefTags(noteValue);
    const oldNote = removeDefTags(initialData?.session_notes);
    if (sessionNote.length === 0 && oldNote.length === 0) {
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
      dispatch(officeVisitActions.openOfficeVisitDetailsDrawer());
    } else {
      let updateData: IOfficeVisit = {
        status: 'Completed',
        session_end: moment(),
      };
      if (!noteDisabled) {
        updateData = {
          ...updateData,
          session_notes: sessionNote.length > 0 ? noteValue : initialData?.session_notes,
        }
      }
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData.id,
          updateData,
          triggerRefresh
        )
      );
      setNoteValue('');
      dispatch(officeVisitActions.closeOfficeVisitDetailsDrawer());
    }
    setIsEditing(false);
  };

  const editAssigneeClick = () => {
    setIsEdit(true);
  };

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const handleEditCancel = () => {
    setIsEdit(false);
    setNoteDisabled(true);
  };

  const updateAssignee = (assigneeId: any) => {
    const removedAssignee = initialData?.assignee?.id ?? null;
    if (assigneeId === 'null' || !assigneeId) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData?.id,
          {
            assignee_id: null,
          },
          () => {
            triggerRefresh();
            socket?.emit('server::office-visit-updated', {
              assignee: assigneeId,
              removed_assignee: removedAssignee,
              branch: branches.find(
                (branch) => branch.id === initialData.branch_id
              )?.name as string,
              contact_id: initialData.contact.id,
              crm_user_id: user?.id as string,
              type: 'changed',
            });
          }
        )
      );
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData?.id,
          {
            assignee_id: assigneeId,
          },
          () => {
            socket?.emit('server::office-visit-updated', {
              assignee: assigneeId,
              removed_assignee: removedAssignee,
              branch: branches.find(
                (branch) => branch.id === initialData.branch_id
              )?.name as string,
              contact_id: initialData.contact.id,
              crm_user_id: user?.id as string,
              type: 'changed',
            });
          }
        )
      );
    }

    dispatch(officeVisitActions.getOfficeVisitRequest(initialData?.id));
    dispatch(officeVisitActions.officeVisitTableRefresh());
    handleEditCancel();
  };

  const handleFieldsChange = (allFields: any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  const difference = moment.duration(
    moment(initialData.session_end).diff(moment(initialData.session_start))
  );

  const contactFullName =
    initialData?.contact?.first_name + ' ' + initialData?.contact?.last_name;

  useEffect(() => {
    const getPrevVisits = async (id: string) => {
      setPrevVisitsLoading(true);
      const res = await fetchPrevVisit(id);
      setPrevVisits(res?.data?.data);
      setPrevVisitsLoading(false);
    };

    if (initialData?.id) {
      getPrevVisits(initialData.id);
      form.setFieldsValue(initialData);
    }
  }, [initialData?.id]);

  useEffect(() => {
    dispatch(serviceActions.getServicesRequest());
  }, []);


  const handleClose = () => {
    setIsEditing(false);
    onClose();
  }

  return (
    <DrawerElem
      visible={visible}
      onClose={handleClose}
      title={
        initialData.status === 'Waiting' ? (
          <div>
            {VISIT_DETAILS}
            <Tag color="warning" className="margin-left-2">
              {initialData.status}
            </Tag>
          </div>
        ) : (
          <div>
            {VISIT_DETAILS}
            <Tag className="green-btn margin-left-2">{initialData.status}</Tag>
          </div>
        )
      }
      width={DRAWER_WIDTH}
      placement={USER_ADD_PLACEMENT}
    >
      <Spin spinning={isInitialDataLoading}>
        <Form
          layout="vertical"
          size={FORM_SIZE}
          onFinish={submitForm}
          onFieldsChange={handleFieldsChange}
        >
          <Row gutter={GUTTER} className="margin-bottom-1">
            <Col className="display-flex" span={10}>
              <div className="profile__info__item profile__info__user">
                <Avatar
                  size="default"
                  className="initial_capital margin-right-1"
                >
                  {initialData?.contact?.first_name?.substring(0, 1)}
                  {initialData?.contact?.last_name
                    ?.substring(0, 1)
                    .toUpperCase()}
                </Avatar>
                <div className="profile__info__user__detail initial_capital">
                  <Link
                    to={`${URL_DETAIL_LINK}${initialData?.contact?.id}/activities`}
                  >
                    <Typography.Title
                      level={3}
                      className="name-fixed-width text-primary"
                      ellipsis={
                        contactFullName.length > 30
                          ? { tooltip: contactFullName }
                          : false
                      }
                    >
                      {contactFullName}
                    </Typography.Title>
                  </Link>
                  <Tag className="visit-details-tag">
                    {initialData?.contact?.status}
                  </Tag>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <Typography.Text className="text-primary">
                {VISIT_PURPOSE}
              </Typography.Text>
              <br />
              <Typography.Text>
                {initialData?.visit_purpose?.visit_purpose}
              </Typography.Text>
            </Col>
            <Col span={6}>
              {initialData.status === 'Attending' ? (
                <Link to={removeDefTags(noteValue).length > 0 ? `/office-visit/completed` : `/office-visit/attending`}>
                  <Button
                    className="emerald-green-btn margin-right-1"
                    onClick={handleEndSession}
                    disabled={checkCanAttendEndSession()}
                    data-testid="crm-officevisitdetaildrawer-endbtn"
                  >
                    <CheckCircleOutlined />
                    {LABEL.END_SESSION}
                  </Button>
                </Link>
              ) : initialData?.status === 'Waiting' ? (
                <Link to={`/office-visit/attending`}>
                  <Button
                    className="green-btn margin-right-1"
                    onClick={handleAttendSession}
                    disabled={checkCanAttendEndSession()}
                    data-testid="crm-officevisitdetaildrawer-attendbtn"
                  >
                    <CaretRightOutlined />
                    {LABEL.ATTEND_SESSION}
                  </Button>
                </Link>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={24}>
              <Row className="visit-details-title">
                <h4 className="margin-left-1 margin-top-1">
                  {LABEL.CHECK_IN_DETAILS}
                </h4>
              </Row>
              <Row>
                <Col span={5}>
                  <h4>{LABEL.DATE}</h4>
                  <Typography.Text>
                    {moment(initialData.created_at).format('DD/MM/yyyy')}
                  </Typography.Text>
                </Col>

                <Col span={5}>
                  <h4>{LABEL.SESSION_START}</h4>
                  {initialData.session_start ? (
                    <Typography.Text>
                      {moment(initialData.session_start).format('h:mm A')}
                    </Typography.Text>
                  ) : (
                    <p>-</p>
                  )}
                  <h4 className="margin-top-1">{LABEL.SESSION_END}</h4>
                  {initialData.session_end ? (
                    <Typography.Text>
                      {moment(initialData.session_end).format('h:mm A')}
                    </Typography.Text>
                  ) : (
                    <p>-</p>
                  )}
                </Col>
                <Col span={7}>
                  <Row className="wait-time">
                    <Col className="display-flex" span={24}>
                      <div className="profile__info__item profile__info__user">
                        <ClockCircleOutlined className="wait-time-icon" />
                        <div>
                          <h4>{LABEL.WAIT_TIME}</h4>
                          {initialData?.status === 'Waiting' ? (
                            <Countdown data={initialData?.created_at} />
                          ) : (
                            <WaitAfterSessionStart
                              session_start={initialData?.session_start}
                              created_at={initialData?.created_at}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="wait-time">
                    <Col className="display-flex" span={24}>
                      <div className="profile__info__item profile__info__user">
                        <ClockCircleOutlined className="wait-time-icon" />
                        <div>
                          <h4>{LABEL.ATTEND_TIME}</h4>
                          {initialData.status !== 'Waiting' &&
                            initialData.status !== 'Attending' ? (
                            <Typography.Text>
                              {difference.get('h') !== 0 &&
                                difference.get('h') + 'h'}{' '}
                              {difference.get('m')}m
                            </Typography.Text>
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={7}>
                  <h4>{ASSIGNEE_LABEL}</h4>
                  {initialData.status === 'Waiting' ? (
                    <Row>
                      <>
                        {initialData?.assignee?.id && !isEdit ? (
                          <div
                            className="cursor-pointer"
                            data-testid="crm-officevisitdetaildrawer-assignee"
                            onClick={editAssigneeClick}
                          >
                            <TableUserNameCard
                              index={initialData?.assignee?.id}
                              firstName={initialData?.assignee?.first_name}
                              lastName={initialData?.assignee?.last_name}
                              email={initialData?.assignee?.email}
                              id={initialData?.assignee?.id}
                              actionBar={false}
                            />
                          </div>
                        ) : isEdit ? (
                          <Row className="lead-table__name user-name-card">
                            <Col className="align-items-center user-name-card__avatar">
                              <Tooltip title="unassigned">
                                <Avatar
                                  icon={<UserOutlined />}
                                  alt="unassigned"
                                />
                              </Tooltip>
                            </Col>
                            <Col>
                              <Form layout="inline">
                                <Space direction="horizontal">
                                  <Form.Item
                                    name="assignee_user_id"
                                    className="inline_input_width"
                                  >
                                    <UserSelectBox
                                      defaultOpen={true}
                                      placeholder={ASSIGN_TO_BTN}
                                      showArrow={false}
                                      bordered={false}
                                      onChange={updateAssignee}
                                      onBlur={handleEditCancel}
                                      autoFocus={true}
                                      selected_id={
                                        initialData?.assignee?.id
                                          ? initialData.assignee.id
                                          : ''
                                      }
                                      showUnassigned={false}
                                      users={users}
                                    />
                                  </Form.Item>
                                </Space>
                              </Form>
                            </Col>
                          </Row>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={editAssigneeClick}
                          >
                            <UnassignedCard />
                          </div>
                        )}
                      </>
                    </Row>
                  ) : (
                    <div>
                      <UserCard
                        firstName={initialData?.assignee?.first_name}
                        lastName={initialData?.assignee?.last_name}
                        email={initialData?.assignee?.email}
                        showEmailAsLink={false}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={GUTTER}>
            <Col span={24}>
              <Row align='middle' className="visit-details-title">
                <h4 className="margin-left-1 margin-top-1">
                  {LABEL.SESSION_NOTES}
                </h4>
                <EditOutlined
                  className="margin-left-2"
                  onClick={editNotesClick}
                />
              </Row>
              <Card size="small">
                <Row gutter={GUTTER}>
                  <Col span={24}>
                    {isEditing ? (
                      <>
                        <Editor
                          type="text"
                          onChange={handleEditNotes}
                          value={initialData.session_notes}
                        />
                        <div className="padding-top-2 text-align-right">
                          <Space direction="horizontal">
                            <Button
                              className="lead-margin-right"
                              onClick={cancelEditor}
                            >
                              {CANCEL_BTN}
                            </Button>
                            <Button
                              type="primary"
                              onClick={handleSaveNotes}
                              htmlType="submit"
                              disabled={noteDisabled}
                            >
                              {UPDATE_BTN}
                            </Button>
                          </Space>
                        </div>
                      </>
                    ) :
                      removeDefTags(initialData.session_notes).length > 0 ?
                        (
                          <Row>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${initialData.session_notes ?? ''}`,
                              }}
                            ></p>
                          </Row>
                        ) :
                        (
                          <Typography.Text italic={true} className='text-grey' >{SESSION_NOTE_REQUIRED}</Typography.Text>
                        )
                    }
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {form.getFieldValue('contact_id') && (
            <Row gutter={GUTTER}>
              <Col span={24}>
                <Row className=" visit-details-title">
                  <h4 className="margin-left-1 margin-top-1">
                    {PREVIOUS_VISITS}
                  </h4>
                </Row>
                <Card size="small" className="prev-visit-card">
                  {prevVisitsLoading ? (
                    <div className="prev-visit-loader align-items-center">
                      <Spin indicator={antIcon} />
                    </div>
                  ) : !prevVisitsLoading && prevVisits.length !== 0 ? (
                    <>
                      <Row>
                        <Col span={12}>
                          <h3 className="font-weight-normal">
                            {ASSIGNEE_LABEL}
                          </h3>
                        </Col>
                        <Col span={9}>
                          <h3 className="font-weight-normal color-primary">
                            {VISIT_PURPOSE}
                          </h3>
                        </Col>
                        <Col span={3}></Col>
                      </Row>
                      {prevVisits.map((item: any) => {
                        const fullName =
                          item?.assignee?.first_name +
                          ' ' +
                          item?.assignee?.last_name;
                        return (
                          <Row className="margin-bottom-2" key={item?.id}>
                            <Col className="display-flex" span={12}>
                              {item?.assignee ? (
                                <div className="profile__info__item profile__info__user">
                                  <Avatar
                                    size="default"
                                    className="initial_capital margin-right-1"
                                  >
                                    {item?.assignee?.first_name?.substring(
                                      0,
                                      1
                                    )}
                                    {item?.assignee?.last_name?.substring(0, 1)}
                                  </Avatar>
                                  <div className="profile__info__user__detail initial_capital">
                                    <Typography.Title
                                      level={3}
                                      className="name-fixed-width"
                                      ellipsis={
                                        fullName.length > 30
                                          ? { tooltip: fullName }
                                          : false
                                      }
                                    >
                                      {fullName}
                                    </Typography.Title>
                                    <Tag>{item?.assignee?.branch?.name}</Tag>
                                  </div>
                                </div>
                              ) : (
                                <div className="profile__info__item profile__info__user">
                                  <Avatar
                                    size="default"
                                    icon={<UserOutlined />}
                                    className="initial_capital margin-right-1"
                                    alt="assignee"
                                  />
                                  <div className="profile__info__user__detail initial_capital">
                                    <Typography.Title
                                      level={3}
                                      className="name-fixed-width"
                                    >
                                      {UNASSIGNED_LABEL}
                                    </Typography.Title>
                                  </div>
                                </div>
                              )}
                            </Col>
                            <Col span={9}>
                              <Typography.Text className="text-primary">
                                {item?.service?.name}
                              </Typography.Text>
                              <br />
                              <Typography.Text>
                                {item?.visit_purpose?.visit_purpose}
                              </Typography.Text>
                            </Col>
                            <Col span={3}>
                              <Typography.Text
                                type="secondary"
                                className="ant-row-end"
                              >
                                {moment(item?.created_at).format('DD/MM/yyyy')}
                              </Typography.Text>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <div className="prev-visit-loader align-items-center">
                      <Empty
                        description={
                          CONTACT_VISIT_RESPONSE_MESSAGE.PREVIOUS_VISIT_NOT_FOUND
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <Row gutter={GUTTER}>
            <Col span={24}>
              <Row className="visit-details-title">
                <h4 className="margin-left-1 margin-top-1">{ACTIVITY_LOG}</h4>
              </Row>
              <Row>
                <OfficeVisitActivityLog
                  id={initialData?.id}
                  initialData={initialData}
                />
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </DrawerElem>
  );
};

export { OfficeVisitDetailsDrawer };
