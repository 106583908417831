import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { IAction, IUser } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  userAdd,
  UserData,
  userUpdate,
  deactivateUser,
  activateUser,
  UserListForContacts,
} from '../../libs/services.api/lib/user.api';
import * as action from './user.action';
import * as actionTypes from './user.constant';

const getUsers = function* () {
  try {
    const res: AxiosResponse<any> = yield UserListForContacts();
    yield put(action.fetchUsersSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.fetchUsersFailure(e));
  }
};

const getUser = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield UserData(payload);
      yield put(action.fetchUserSuccess(res.data.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchUserFailure(e));
    }
  }
};

const addUser = function* ({ payload, triggerRefresh }: IAction<IUser>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield userAdd(payload);
      yield put(action.addUserSuccess(response.data.data));
      if (response) {
        triggerRefresh?.();
        successNotificationHandler(response.data.message);
      }
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addUserFailure(err));
  }
};

const getFollowerUsers = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield UserData(payload);
      yield put(action.fetchFollowerUsersSuccess(res?.data?.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchFollowerUsersFailure(e));
    }
  }
};

function* editUser({ payload, triggerRefresh }: IAction<IUser>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield userUpdate(payload);
      yield put({ type: action.editUserSuccess, payload: response.data });
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (e) {
    errorHandler(e);
    yield put({ type: action.editUserFailure(e) });
  }
}

function* deactivateUserData({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deactivateUser(payload);
      yield put(action.deactivateUserSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh?.();
    }
  } catch (err) {
    errorHandler(err);
    yield put({ type: action.deactivateUserFailure });
  }
}

function* activateUserData({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield activateUser(payload);
      yield put(action.activateUserSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh?.();
    }
  } catch (err) {
    errorHandler(err);
    yield put({ type: action.activateUserFailure });
  }
}

export default function* rootSaga(): unknown {
  yield takeLatest(actionTypes.GET_USERS_REQUEST, getUsers);
  yield takeLatest(actionTypes.GET_USER_REQUEST, getUser);
  yield takeLatest(actionTypes.ADD_USER_REQUEST, addUser);
  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, editUser);
  yield takeLatest(actionTypes.DEACTIVATE_USER_REQUEST, deactivateUserData);
  yield takeLatest(actionTypes.ACTIVATE_USER_REQUEST, activateUserData);
  yield takeLatest(actionTypes.GET_FOLLOWER_USERS_REQUEST, getFollowerUsers);
}
