import store from './store';

export { themeActions } from './theme/theme.reducer';
export { authActions } from './auth/auth.reducer';
export * as userActions from './user/user.action';
export * as serviceActions from './service/service.action';
export * as officeVisitActions from './office-visit/office-visit.action';
export * as rolePermissionActions from './role_permissions/role-permission.action';
export * as officeBranchActions from './office/office-branch.action';
export * as institutionActions from './institutions/institution.action';
export * as workflowTypeActions from './workflow-type/workflow-type.action';
export * as workflowActions from './workflow/workflow.action';
export * as contactActions from './contact/contact.action';
export * as leadActions from './lead/lead.action';
export * as subjectDisciplineActions from './subject-disciplines/subject-discipline.action';
export * as documentAction from './documents/document.action';
export * as institutionBranchesActions from './branch-institution/branch-institution.action';
export * as productTypeActions from './product-type/product-type.action';
export * as productActions from './product/product.action';
export * as agentPartnerAction from './agent-partner/agent-partner.action';
export * as countryServiceActions from './country/country-service.action';
export { googleActions } from './google/google.reducer';
export { useAppSelector, useAppDispatch } from './use-store';

export { store };
