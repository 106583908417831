import { IAction } from '@shared-components/models';
import {
  ADD_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  ADD_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  ADD_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  ADD_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
  CLEAR_CONTACT_EDUCATION_BACKGROUND_FETCH,
  CLEAR_CONTACT_FETCH,
  CLEAR_CONTACT_PROFESSIONAL_EXPERIENCE_FETCH,
  DELETE_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  DELETE_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
  GET_CONTACT_DETAILS_FAILURE,
  GET_CONTACT_DETAILS_REQUEST,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  GET_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  GET_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  GET_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  GET_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
  UPDATE_CONTACT_ADDRESS_INFO_FAILURE,
  UPDATE_CONTACT_ADDRESS_INFO_REQUEST,
  UPDATE_CONTACT_ADDRESS_INFO_SUCCESS,
  UPDATE_CONTACT_ASSIGNEE_FAILURE,
  UPDATE_CONTACT_ASSIGNEE_REQUEST,
  UPDATE_CONTACT_ASSIGNEE_SUCCESS,
  UPDATE_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  UPDATE_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  UPDATE_CONTACT_EMERGENCY_DETAILS_FAILURE,
  UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST,
  UPDATE_CONTACT_EMERGENCY_DETAILS_SUCCESS,
  UPDATE_CONTACT_FOLLOWERS_FAILURE,
  UPDATE_CONTACT_FOLLOWERS_REQUEST,
  UPDATE_CONTACT_FOLLOWERS_SUCCESS,
  UPDATE_CONTACT_PASSPORT_VISA_INFO_FAILURE,
  UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST,
  UPDATE_CONTACT_PASSPORT_VISA_INFO_SUCCESS,
  UPDATE_CONTACT_PERSONAL_INFO_FAILURE,
  UPDATE_CONTACT_PERSONAL_INFO_REQUEST,
  UPDATE_CONTACT_PERSONAL_INFO_SUCCESS,
  UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
} from './contact.constant';

export const getContactDetailsRequest = (id: string): IAction<string> => ({
  type: GET_CONTACT_DETAILS_REQUEST,
  payload: id,
});

export const getContactDetailsSuccess = (payload: any): IAction => ({
  type: GET_CONTACT_DETAILS_SUCCESS,
  payload,
});

export const getContactDetailsFailure = (payload: any): IAction => ({
  type: GET_CONTACT_DETAILS_FAILURE,
  payload,
});

// eslint-disable-next-line no-unused-vars
export const updateContactAssigneeRequest = (
  payload: any,
  triggerRefresh: (...payload: any[]) => void
): IAction => ({
  type: UPDATE_CONTACT_ASSIGNEE_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactAssigneeSuccess = (payload: any): IAction => ({
  type: UPDATE_CONTACT_ASSIGNEE_SUCCESS,
  payload,
});

export const updateContactAssigneeFailure = (payload: any): IAction => ({
  type: UPDATE_CONTACT_ASSIGNEE_FAILURE,
  payload,
});

// eslint-disable-next-line no-unused-vars
export const updateContactPersonalInfoRequest = (
  payload: any,
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: UPDATE_CONTACT_PERSONAL_INFO_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactPersonalInfoSuccess = (payload: any): IAction => ({
  type: UPDATE_CONTACT_PERSONAL_INFO_SUCCESS,
  payload,
});

export const updateContactPersonalInfoFailure = (payload: any): IAction => ({
  type: UPDATE_CONTACT_PERSONAL_INFO_FAILURE,
  payload,
});

// eslint-disable-next-line no-unused-vars
export const updateContactAddressInfoRequest = (
  payload: any,
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: UPDATE_CONTACT_ADDRESS_INFO_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactAddressInfoSuccess = (): IAction => ({
  type: UPDATE_CONTACT_ADDRESS_INFO_SUCCESS,
});

export const updateContactAddressInfoFailure = (payload: any): IAction => ({
  type: UPDATE_CONTACT_ADDRESS_INFO_FAILURE,
  payload,
});

export const updateContactPassportVisaInfoRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactPassportVisaInfoSuccess = (): IAction => ({
  type: UPDATE_CONTACT_PASSPORT_VISA_INFO_SUCCESS,
});

export const updateContactPassportVisaInfoFailure = (
  payload: any
): IAction => ({
  type: UPDATE_CONTACT_PASSPORT_VISA_INFO_FAILURE,
  payload,
});

export const updateContactEmergencyDetailsRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (...payload: any[]) => void
): IAction => ({
  type: UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactEmergencyDetailsSuccess = (): IAction => ({
  type: UPDATE_CONTACT_EMERGENCY_DETAILS_SUCCESS,
});

export const updateContactEmergencyDetailsFailure = (
  payload: any
): IAction => ({
  type: UPDATE_CONTACT_EMERGENCY_DETAILS_FAILURE,
  payload,
});

// eslint-disable-next-line no-unused-vars
export const updateContactFollowersRequest = (
  payload: any,
  triggerRefresh: (...payload: any[]) => void
): IAction => ({
  type: UPDATE_CONTACT_FOLLOWERS_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactFollowersSuccess = (payload: any): IAction => ({
  type: UPDATE_CONTACT_FOLLOWERS_SUCCESS,
  payload,
});

export const updateContactFollowersFailure = (payload: any): IAction => ({
  type: UPDATE_CONTACT_FOLLOWERS_FAILURE,
  payload,
});

export const clearContactFetch = (): IAction => ({
  type: CLEAR_CONTACT_FETCH,
});

export const addContactEducationBackgroundRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction<string> => ({
  type: ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  payload,
  triggerRefresh,
});

export const addContactEducationBackgroundSuccess = (
  payload: any
): IAction => ({
  type: ADD_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  payload,
});

export const addContactEducationBackgroundFailure = (
  payload: any
): IAction => ({
  type: ADD_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const getContactEducationBackgroundRequest = (
  id: string
): IAction<string> => ({
  type: GET_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  payload: id,
});

export const getContactEducationBackgroundSuccess = (
  payload: any
): IAction => ({
  type: GET_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
  payload,
});

export const getContactEducationBackgroundFailure = (
  payload: any
): IAction => ({
  type: GET_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const updateContactEducationBackgroundRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactEducationBackgroundSuccess = (): IAction => ({
  type: UPDATE_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
});

export const updateContactEducationBackgroundFailure = (
  payload: any
): IAction => ({
  type: UPDATE_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const deleteContactEducationBackgroundRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
  payload,
  triggerRefresh,
});

export const deleteContactEducationBackgroundSuccess = (): IAction => ({
  type: DELETE_CONTACT_EDUCATION_BACKGROUND_SUCCESS,
});

export const deleteContactEducationBackgroundFailure = (
  payload: any
): IAction => ({
  type: DELETE_CONTACT_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const clearContactEducationBackgroundFetch = (): IAction => ({
  type: CLEAR_CONTACT_EDUCATION_BACKGROUND_FETCH,
});

export const addContactProfessionalExperienceRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  payload,
  triggerRefresh,
});

export const addContactProfessionalExperienceSuccess = (): IAction => ({
  type: ADD_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
});

export const addContactProfessionalExperienceFailure = (
  payload: any
): IAction => ({
  type: ADD_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  payload,
});

export const getContactProfessionalExperienceRequest = (
  contact_id: string
): IAction => ({
  type: GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  id: contact_id,
});

export const getContactProfessionalExperienceSuccess = (
  payload: any
): IAction => ({
  type: GET_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
  payload,
});

export const getContactProfessionalExperienceFailure = (
  payload: any
): IAction => ({
  type: GET_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  payload,
});

export const updateContactProfessionalExperienceRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  payload,
  triggerRefresh,
});

export const updateContactProfessionalExperienceSuccess = (): IAction => {
  return {
    type: UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
  };
};

export const updateContactProfessionalExperienceFailure = (
  payload: any
): IAction => ({
  type: UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  payload,
});

export const deleteContactProfessionalExperienceRequest = (
  payload: any,
  // eslint-disable-next-line no-unused-vars
  triggerRefresh: (payload: any) => void
): IAction => ({
  type: DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
  payload,
  triggerRefresh,
});

export const deleteContactProfessionalExperienceSuccess = (): IAction => ({
  type: DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS,
});

export const deleteContactProfessionalExperienceFailure = (
  payload: any
): IAction => ({
  type: DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE,
  payload,
});

export const clearContactProfessionalExperienceFetch = (): IAction => ({
  type: CLEAR_CONTACT_PROFESSIONAL_EXPERIENCE_FETCH,
});
