import axiosInstance from "@crm/libs/services.api/lib/axios"
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { useMutation } from "@tanstack/react-query"
import { AxiosResponse } from 'axios';

interface Body {
    id: string;
    payload: {
        status: 'approve' | 'reject',
        branch_id?: string
    }
}
const useUpdateEnquiryStatusMutation = () => {

    return useMutation({
        mutationKey:['update-enquiry-status'],
        mutationFn:(body: Body) => axiosInstance.put(`crm/contact-inquiry/${body.id}`,body.payload),
        onSuccess(response: AxiosResponse) {
            successNotificationHandler(response.data.message)
        },
        onError() {
            errorNotificationHandler('Failed to updated status please try again')
        }
    })
}
export default useUpdateEnquiryStatusMutation
