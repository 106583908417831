import { IProductFilterContext, ProductFilter } from "@model/product";
import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as qs from 'qss';
import { countryServiceActions, serviceActions, useAppSelector } from "@crm/core";
import { useDispatch } from "react-redux";

export const ProductFilterContext = createContext<IProductFilterContext>({
    setDisciplines: () => [],
    setProductSubTypes: () => [],
    disciplines: [],
    productSubTypes: [],
    filter: {},
    setFilter: () => ({}),
    countriesLoading: false,
    handleServiceChange: (val: string) => { return },
    selectedService: undefined,
    country: undefined,
    countries: [],
    setSelectedService: (val: string) => { return },
    selectedCountry: undefined,
    searchInput: undefined,
    onSearch: (val: string | undefined) => { return },
    search: undefined,
    onSearchChange: (val: string | undefined) => { return },
    onCountryChange: (val: string | undefined) => { return },
    resetFilters: (resetServiceAndCountry?: boolean) => { return },
    setSelectedCountry: (val: string | undefined) => { return }
});

interface Query {
    disciplines?: string | string[];
    productSubTypes?: string | string[];
    service?: string;
    country?: string;
    s?: string;
}

const ProductFilterProvider: React.FC = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()

    const [filter, setFilter] = useState<ProductFilter>({});
    const [country, setCountry] = useState<string | undefined>();
    const [disciplines, setDisciplines] = useState<string[]>([]);
    const [productSubTypes, setProductSubTypes] = useState<string[]>([]);
    const [search, setSearch] = useState<undefined | string>(undefined);
    const [searchInput, setSearchInput] = useState<string | undefined>();
    const [selectedService, setSelectedService] = useState<string | undefined>();
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
    const services = useAppSelector((state) => state.services.allData);
    const [initialFilter, setInitialFilter] = useState<Query>({});
    const { loading: countriesLoading } = useAppSelector(
        (state) => state.country_by_service
    );

    const [countries, setCountries] = useState<{ name: string }[]>([])

    useEffect(() => {
        dispatch(serviceActions.getProductServicesRequest());

        const queryString = qs.decode<Query>(location.search.replace(/[?]/g, ''))
        setInitialFilter(queryString);
        const val: ProductFilter = {}
        if (Object.values(queryString).length) {

            if (typeof queryString.disciplines === 'string') {
                val.disciplines = [queryString.disciplines];
            }
            else val.disciplines = queryString.disciplines;

            if (typeof queryString.productSubTypes === 'string') {
                val.productSubTypes = [queryString.productSubTypes];
            }
            else val.productSubTypes = queryString.productSubTypes

            setFilter(val)

            if (val.disciplines?.length) {
                setDisciplines(val.disciplines);
            }
            if (val.productSubTypes?.length) {
                setProductSubTypes(val.productSubTypes)
            }

            if (queryString.s) {
                setSearchInput(queryString.s);
                setSearch(queryString.s);
            }

            if (queryString.service) {
                setSelectedService(queryString.service)
            }
            if (queryString.country) {
                setSelectedCountry(queryString.country);
            }
        }
    }, []);


    useEffect(() => {
        if (services.length > 0 && !selectedService) {
            setSelectedService(services[0].id);
        }
    }, [services]);

    useEffect(() => {
        const query = qs.encode({
            country: selectedCountry,
            service: selectedService,
            s: search,
            ...filter
        });
        history.replace(location.pathname + '?' + query)

    }, [filter, selectedCountry, selectedService, search])


    useEffect(() => {
        setSelectedCountry(undefined);
        if (selectedService) {
            dispatch(
                countryServiceActions.getCountryByServiceRequest(selectedService, (countries) => {
                    setCountries(countries);
                    if (countries.length) {
                        const name = countries[0].name;
                        setSelectedCountry(selectedService === initialFilter?.service && initialFilter?.country !== name ? initialFilter?.country ?? name : name);
                    } else {
                        setSelectedCountry(undefined)
                    }
                })
            );
        }
    }, [selectedService, initialFilter]);

    const handleServiceChange = (value: string) => {
        setSelectedService(value);
        resetFilters();
        if (countries.length > 0) {
            setCountry(countries[0].name);
        } else setCountry(undefined);
    };

    const onCountryChange = (val: string | undefined) => {
        setSelectedCountry(val);
        resetFilters();
    }

    const resetFilters = (resetServiceAndCountry?: boolean) => {
        setDisciplines([]);
        setProductSubTypes([]);
        setFilter({});
        setSearch(undefined);
        setSearchInput(undefined);
        if (resetServiceAndCountry) {
            setSelectedService(services.at(0)?.id);
            setSelectedCountry(countries.at(0)?.name);
        }
    }

    return (
        <ProductFilterContext.Provider value={{
            disciplines,
            filter,
            setFilter,
            setDisciplines,
            productSubTypes,
            setProductSubTypes,
            countriesLoading,
            handleServiceChange,
            country,
            selectedService,
            countries,
            setSelectedService,
            selectedCountry,
            searchInput,
            search,
            onSearch: (val: string | undefined) => setSearch(val),
            onSearchChange: (val: string | undefined) => setSearchInput(val),
            onCountryChange,
            resetFilters,
            setSelectedCountry,
        }}>
            {children}
        </ProductFilterContext.Provider>
    )

}

export default ProductFilterProvider


