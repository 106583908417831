import * as action from './office-visit.action';
import * as actionTypes from './office-visit.constant';
import { IAction, IOfficeVisit } from '@shared-components/models';
import {
  officeVisitAdd,
  officeVisitFetch,
  officeVisitUpdate,
} from '../../libs/services.api';
import { AxiosResponse } from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { errorHandler } from '@moxie/utils';
import { successNotificationHandler } from '@moxie/shared';

const addOfficeVisit = function* ({
  payload,
  triggerRefresh,
}: IAction<IOfficeVisit>) {
  try {
    if (payload) {
      const res: AxiosResponse = yield officeVisitAdd(payload);
      yield put(action.addOfficeVisitSuccess());
      successNotificationHandler(res?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addOfficeVisitFailure(err));
  }
};

const getOfficeVisit = function* ({ id }: IAction<IOfficeVisit>) {
  try {
    if (id) {
      const res: AxiosResponse = yield officeVisitFetch(id);
      yield put(action.getOfficeVisitSuccess(res?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getOfficeVisitFailure(err));
  }
};

const updateOfficeVisit = function* ({
  id,
  payload,
  triggerRefresh,
}: IAction<IOfficeVisit>) {
  try {
    if (id && payload) {
      const res: AxiosResponse = yield officeVisitUpdate(id, payload);
      yield put(action.updateOfficeVisitSuccess());
      successNotificationHandler(res?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateOfficeVisitFailure(err));
  }
};

export default function* rootSaga(): unknown {
  yield takeLatest(actionTypes.ADD_OFFICE_VISIT_REQUEST, addOfficeVisit);
  yield takeEvery(actionTypes.GET_OFFICE_VISIT_REQUEST, getOfficeVisit);
  yield takeLatest(actionTypes.UPDATE_OFFICE_VISIT_REQUEST, updateOfficeVisit);
}
