import { API_ENDPOINT_URL, API_URL } from '@moxie/constants';
import { IResponse } from '@shared-components/models';
import axios from './axios';
import { errorHandler } from '@moxie/utils';

const saveDocuments = async (body: FormData): Promise<IResponse> => {
  return await axios.post(`${API_URL.CRM_DOCUMENTS}/contact`, body);
};

const saveApplicationStageDocs = async (body: FormData): Promise<IResponse> => {
  return await axios.post(
    `${API_URL.CRM_DOCUMENTS}/workflow-application-stage`,
    body
  );
};

const getAllDocuments = async (id: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_DOCUMENTS}/contact?id=${id}`);
};

const getSingleDocument = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL.CRM_DOCUMENTS}/download-file/${id}`
    );
    return response?.data;
  } catch (err) {
    errorHandler(err);
  }
};

const getAllDocumentType = async (
  id: string,
  docType: string
): Promise<any> => {
  return axios.get(`${API_URL.CRM_DOCUMENTS}/${id}/${docType}`);
};

const getDocumentCategory = async (): Promise<any> => {
  return axios.get(`${API_ENDPOINT_URL.CHECKLISTS}/category`);
};

const getDocumentCategoryType = async (
  categoryName: string | undefined
): Promise<any> => {
  return axios.get(`${API_ENDPOINT_URL.CHECKLISTS}/type/${categoryName}`);
};

const getDocumentsByCategory = async (
  id: string,
  docType: any
): Promise<any> => {
  return axios.get(`${API_URL.CRM_DOCUMENTS}/${id}/${docType}`);
};

const updateDocument = async (id: string, body: any) => {
  return axios.put(`${API_URL.CRM_DOCUMENTS}/${id}`, body);
};

export {
  saveDocuments,
  getAllDocuments,
  getSingleDocument,
  getAllDocumentType,
  getDocumentCategory,
  getDocumentCategoryType,
  getDocumentsByCategory,
  updateDocument,
  saveApplicationStageDocs,
};
