import axiosInstance from "@crm/libs/services.api/lib/axios"
import { Prettify } from "@model/data.model"
import { useQuery } from "@tanstack/react-query"

export interface ICrmSubType {
    id: string;
    name: string;
    product_type_id: string
    updated_at: string | Date;
}

export interface ICrmProductType {
    id: string;
    name: string;
    service_id: string;
    sub_type_level: Array<ICrmSubType>;
    created_at: string | Date;
}

export interface ICrmService {
    id: string;
    name?: string;
    description?: string;
    product_type: Array<ICrmProductType>;
    created_at?: string | Date;
    updated_at?: string | Date;
}

const useContactFormServices = (contactFormId: string) => {
    const { data, isLoading } = useQuery({
        queryKey:['form-services',contactFormId],
        queryFn:() => axiosInstance.get<{data: Prettify<Required<ICrmService>>[]}>(`/crm/contact-inquiry/${contactFormId}/services`).then(({ data }) => data.data)
    })

    return {
        services: data ?? [],
        isLoading,
    }
}

export default useContactFormServices
