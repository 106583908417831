import axios from './axios';
import { API_URL } from '@moxie/constants';
import { IResponse, IWorkflowType } from '@shared-components/models';

const addCRMWorkflowType = async (data: IWorkflowType): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_WORKFLOW_TYPE}`, data);
};

const updateCRMWorkflowType = async (
  data: IWorkflowType
): Promise<IResponse> => {
  return axios.put(`${API_URL.CRM_WORKFLOW_TYPE}/${data.id}`, data);
};

const deleteCRMWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_WORKFLOW_TYPE}/${id}`);
};

const getOneCRMWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${id}`);
};

const getCRMWorkflowTypeList = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${params}`);
};
const getCRMWorkflowTypeByServiceId = async (
  id: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${id}/services/${params}`);
};

export {
  addCRMWorkflowType,
  updateCRMWorkflowType,
  deleteCRMWorkflowType,
  getOneCRMWorkflowType,
  getCRMWorkflowTypeList,
  getCRMWorkflowTypeByServiceId,
};
