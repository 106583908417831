/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { Button, Table } from '@shared-components/elements';
import {
  ADD_WORKFLOW,
  DELETE_BTN,
  LABEL,
  MANAGE_WORKFLOW_TYPES,
  TEXT,
  URL_CRM_WORKFLOW_VIEW,
  URL_SERVICE,
  URL_WORKFLOW_TYPES,
  VIEW_BTN,
} from '@moxie/constants';
import { Link, useParams } from 'react-router-dom';
import {
  FormOutlined,
  PlusOutlined,
  LeftOutlined,
  EyeOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import {
  ITableProps,
  IWorkflow,
  IWorkflowTableProps,
  IWorkflowType,
} from '@shared-components/models';
import { useQuery } from '@moxie/utils';
import { getCRMWorkflowsByServiceId } from 'apps/crm/src/libs/services.api/lib/workflow.api';

const WorkflowTypeTitleMenu = ({ data, deleteData, showDrawer }: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data.id)}>
        <Space direction="horizontal">
          <EyeOutlined />
          {VIEW_BTN}
        </Space>
      </Menu.Item>
      {data.sync_id === null ? (
        <Menu.Item key={2} danger onClick={deleteData}>
          <Space direction="horizontal">
            <DeleteOutlined />
            {DELETE_BTN}
          </Space>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

export const columns = (props: ITableProps) => {
  const { deleteData, showDrawer } = props;
  return [
    {
      title: TEXT.SN,
      key: 'id',
      dataIndex: 'id',
      width: 50,
      render: (_data: string, _row: IWorkflow, index: number): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: LABEL.WORKFLOW,
      width: 400,
      sorter: () => 0,
      render(data: IWorkflow) {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
        };
        return (
          <>
            {data.sync_id === null ? (
              <Row justify="space-between">
                <Col span={19} className="align-items-center initial_capital">
                  <Link
                    data-testid="workflowlink"
                    to={`${URL_CRM_WORKFLOW_VIEW}/${data?.id}`}
                  >
                    {data.workflowType!.name!.length > 31 ? (
                      <Tooltip title={data.workflowType!.name}>
                        <Typography.Text className="text-primary-color table-title-ellipsis">
                          {`${data?.workflowType?.name}`}
                        </Typography.Text>
                      </Tooltip>
                    ) : (
                      <Typography.Text className="text-primary-color">
                        {`${data?.workflowType?.name}`}
                      </Typography.Text>
                    )}
                  </Link>
                </Col>

                <Col span={2} className="align-items-center">
                  <Dropdown overlay={WorkflowTypeTitleMenu(menuActions)}>
                    <Button
                      type="text"
                      className="table-cell-action-btn"
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={16} className="align-items-center">
                  <Link
                    data-testid="workflowlink"
                    to={`${URL_CRM_WORKFLOW_VIEW}/${data?.id}`}
                  >
                    {data.workflowType!.name!.length > 31 ? (
                      <Tooltip title={data.workflowType!.name}>
                        <Typography.Text className="text-primary-color table-title-ellipsis">
                          {`${data?.workflowType?.name}`}
                        </Typography.Text>
                      </Tooltip>
                    ) : (
                      <Typography.Text className="text-primary-color">
                        {`${data?.workflowType?.name}`}
                      </Typography.Text>
                    )}
                  </Link>
                </Col>
                <Col span={6}>
                  <Row justify="space-between" align="middle">
                    <Col span={3}>
                      <Tag className="tag-processing">System Default</Tag>
                    </Col>

                    <Col span={1} className="align-items-center">
                      <Dropdown overlay={WorkflowTypeTitleMenu(menuActions)}>
                        <Button
                          type="text"
                          className="table-cell-action-btn margin-left-5-pixel"
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        );
      },
    },

    {
      title: LABEL.SERVICE,
      width: 300,
      dataIndex: 'workflowType',
      render(workflowType: IWorkflowType) {
        return (
          <div className="initial_capital">{workflowType?.service?.name}</div>
        );
      },
    },
    {
      title: LABEL.COUNTRY,
      width: 300,
      dataIndex: 'country',
      render(country: string) {
        return country;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 200,
      dataIndex: 'created_at',
      render(date: string) {
        return moment(date).format('MMM DD, YYYY');
      },
    },
  ];
};

const WorkflowTableList: React.FC<IWorkflowTableProps> = ({
  refreshTable,
  showDrawer,
  deleteData,
}: IWorkflowTableProps) => {
  const { serviceId }: { serviceId: string } = useParams();

  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
  };

  const getWorkflowsList = async (params: string) => {
    return getCRMWorkflowsByServiceId(serviceId!, params);
  };
  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={TEXT.WORKFLOW}
        action={getWorkflowsList}
        columns={columns(columnActions)}
        refresh={refreshTable}
        filterOptions={true}
        tableButtons={
          <Space>
            <Link to={URL_SERVICE}>
              <Button icon={<LeftOutlined />}>{TEXT.SERVICES}</Button>
            </Link>
            <Link to={`/service/${serviceId}${URL_WORKFLOW_TYPES}`}>
              <Button type="ghost" icon={<FormOutlined />}>
                {MANAGE_WORKFLOW_TYPES}
              </Button>
            </Link>
            <Button
              type="primary"
              title={ADD_WORKFLOW}
              typeof="button"
              onClick={() => showDrawer && showDrawer()}
            >
              <PlusOutlined /> {ADD_WORKFLOW}
            </Button>
          </Space>
        }
      />
    </div>
  );
};
export { WorkflowTableList };
