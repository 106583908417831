import axiosInstance from '@crm/libs/services.api/lib/axios';
import { ContactInquiryFormFields } from '@model/contact-form';
import { errorNotificationHandler } from '@moxie/shared';
import { useMutation } from '@tanstack/react-query';

import { AxiosError } from 'axios';

interface MutationPayload {
  payload: ContactInquiryFormFields;
  contactFormId: string;
}
const useContactInquiryMutation = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation({
    mutationFn({ payload, contactFormId }: MutationPayload) {
      return axiosInstance.post(
        `/crm/contact-inquiry/${contactFormId}`,
        payload
      );
    },
    onSuccess(data) {
      return data;
    },
    onError(error: AxiosError) {
      errorNotificationHandler(error?.response?.data?.message);
    },
  });
};

export default useContactInquiryMutation;
