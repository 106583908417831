import axios from './axios';
import {
  ILead,
  IProductResponse,
  IResponse,
  IUpdateMultipleLead,
} from '@shared-components/models';
import { AxiosRequestConfig } from 'axios';
import { AM_API_URL, API_URL } from '@moxie/constants';

const downloadTemplate = async (): Promise<any> => {
  return axios.get(API_URL.DOWNLOAD_TEMPLATE);
};

const uploadFile = async (body: unknown): Promise<IResponse> => {
  return axios.post(API_URL.UPLOAD_FILE, body);
};

const uploadAvatar = async (
  data: FormData,
  config: AxiosRequestConfig
): Promise<IResponse> => {
  return axios.post(API_URL.UPLOAD_IMAGE, data, config);
};

const importData = async (body: unknown): Promise<IResponse> => {
  return axios.post(API_URL.IMPORT_DATA, body);
};

const postLead = async (data: ILead): Promise<ILead> => {
  return axios.post(API_URL.URL_ADD_LEAD, data);
};

const deleteLeads = async (data: string[]): Promise<ILead> => {
  return axios.delete(API_URL.URL_DELETE_LEAD, { data: { delete_ids: data } });
};

const updateLead = async (data: ILead): Promise<ILead> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.URL_EDIT_LEAD}${id}`, data);
};

const multipleUpdateLead = async (
  data: IUpdateMultipleLead
): Promise<ILead> => {
  return axios.post(API_URL.URL_MULTIPLE_EDIT_LEAD, data);
};

const getAllLead = async (params = ''): Promise<any> => {
  return axios.get(`contact${params}`);
};

const getAllContacts = async (params = ''): Promise<any> => {
  return axios.get(`contact?${params}`);
};

const getArchived = async (params = ''): Promise<any> => {
  return axios.get(`contact/archived?${params}`);
};

const getAssigned = async (params = ''): Promise<any> => {
  return axios.get(`contact/assigned?${params}`);
};

const getClientByAssigneeList = async (branchId?: string): Promise<any> => {
  const params = { id: branchId };
  return axios.get('contact/list/assignee', { params });
};
const getContactByBranch = async (branchId?: string): Promise<any> => {
  const params = { id: branchId };
  return axios.get(`contacts/branch`, { params });
};

const getEligibleProducts = async (
  data: {
    contact_id: string;
  },
  url: string
): Promise<IProductResponse> => {
  return axios.get(
    `${API_URL.PRODUCTS}/eligible/lists/${data.contact_id}${url}`
  );
};

const getAllProducts = async (params = ''): Promise<IProductResponse> => {
  return axios.get(`${API_URL.PRODUCTS}/list/${params}`);
};

const getAllInstitutes = async (params = ''): Promise<IProductResponse> => {
  return axios.get(`${AM_API_URL.INSTITUTION_LIST}/${params}`);
};

const postGenerateLead = async (data: any): Promise<ILead> => {
  return axios.post(API_URL.URL_GENERATE_LEAD, data);
};

const checkLeadEmail = (email: string): Promise<any> => {
  return axios.get(`${API_URL.URL_ADD_LEAD}/check-email/${email}`);
};
const checkContactEmail = (data: any): Promise<any> => {
  return axios.post(`${API_URL.URL_ADD_LEAD}/valid`, data);
};

export {
  downloadTemplate,
  uploadFile,
  importData,
  uploadAvatar,
  updateLead,
  postLead,
  getAllLead,
  getAllProducts,
  getEligibleProducts,
  deleteLeads,
  multipleUpdateLead,
  getAllInstitutes,
  postGenerateLead,
  checkLeadEmail,
  getContactByBranch,
  getClientByAssigneeList,
  checkContactEmail,
  getAllContacts,
  getArchived,
  getAssigned,
};
