import { IAction, IProduct } from '@shared-components/models';
import * as actionTypes from './product.constant';

export interface IProductState {
  products: IProduct[];
  product: IProduct;
  error: boolean | null;
  loading: boolean;
}

const initialProductState: IProductState = {
  products: [],
  product: {},
  error: null,
  loading: false,
};

export const PRODUCTS_FEATURE_KEY = 'products';

export const productReducer = (
  state = initialProductState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case actionTypes.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload,
      };
    case actionTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
