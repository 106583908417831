import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Select, Space, Spin, Tag, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { CONTACT_RESPONSE_MESSAGE, TEXT, URL_CONTACT_DETAIL } from '@moxie/constants';
import { Option } from '@shared-components/elements';
import debounce from 'lodash.debounce';
import { canViewContactStatus } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';

function SearchBar<
  ValueType extends {
    key?: any;
    label: any;
    value: any;
  } = any
>({ fetchOptions, debounceTimeout = 800, ...props }: any) {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const fetchRef = React.useRef(0);
  const history = useHistory();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setIsFetching(true);
      setSearchText(value);
      fetchOptions(value).then((res: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(res?.data?.data || []);
        setIsFetching(false);
      });
    };
    setSearchText('');
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleSelect = async (value: any) => {
    setSelectedOption('');
    setSearchText('');
    if (value) {
      const viewStatus = await canViewContactStatus(value);
      if (viewStatus) {
        setSelectedOption(value);
        history.push(`${URL_CONTACT_DETAIL}${value}/activities`);
      } else {
        errorNotificationHandler(CONTACT_RESPONSE_MESSAGE.NOT_AUTHORIZED_TO_VIEW);
      }
    }
    return;
  };

  return (
    <Select<ValueType>
      showSearch
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSelect={handleSelect}
      value={selectedOption}
      onSearch={debounceFetcher}
      autoClearSearchValue
      notFoundContent={
        isFetching && (
          <div className="full-width">
            <Spin size="small" />
          </div>
        )
      }
      {...props}
    >
      {searchText.length > 0 && options.length !== 0
        ? options?.map((item: any) => {
          return (
            <Option
              key={item?.id}
              value={item?.id}
            >
              <SearchBarProfileCard
                firstName={item?.first_name}
                lastName={item?.last_name}
                email={item?.email}
                countryCode={item?.country_code}
                phone={item?.phone}
                status={item?.status}
                branchName={item?.branch?.name}
                archivedContact={item?.archived}
                id={item?.internal_id}
              />
            </Option>
          );
        })
        : !isFetching &&
        searchText.length > 0 && (
          <Option value={''} disabled>
            <div className="dashboard-header__search-more">
              <Space direction="horizontal">
                <Text>{TEXT.NO_CONTACTS_FOUND}</Text>
              </Space>
            </div>
          </Option>
        )}
    </Select>
  );
}
export { SearchBar };

const SearchBarProfileCard = ({
  id,
  firstName,
  lastName,
  email,
  countryCode,
  phone,
  status,
  branchName,
  archivedContact,
}: any) => {
  return (
    <>
      <Row
        className="contact-name-card padding-top-1 padding-bottom-1"
        justify="start"
      >
        <Col span={2}>
          <div className="align-items-center user-name-card__avatar">
            <Avatar size={'large'} className="initial_capital">
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        </Col>
        <Col span={21}>
          <Row justify="start" className="padding-bottom-1 margin-left-1">
            {status ? (
              <>
                <Col md={19} lg={18} className="contact-profile-name">
                  <Text className="text-primary-color initial_capital">
                    {`${firstName} ${lastName}`}
                  </Text>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <Text className="profile__title margin-left-1">{id}</Text>
                </Col>
                <Col md={5} lg={6} className="status-tag-position">
                  {archivedContact === true ? (
                    <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                  ) : (
                    <Tag>{status}</Tag>
                  )}
                </Col>
              </>
            ) : archivedContact === true ? (
              <>
                <Col span={21} className="contact-profile-name">
                  <Text className="text-primary-color initial_capital">
                    {`${firstName} ${lastName}`}
                  </Text>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <Text className="profile__title margin-left-1">{id}</Text>
                </Col>
                <Col span={3}>
                  <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                </Col>
              </>
            ) : (
              '-'
            )}
          </Row>
          <Row justify="space-between" className="margin-left-1">
            <Col md={19} lg={18}>
              <Row>
                <Col>
                  <MailOutlined className="table-mail-icon" />
                </Col>
                <Col>
                  {email.length > 25 ? (
                    <Tooltip title={email} placement="left">
                      <Text className="contact-profile-email" ellipsis={true}>
                        {email?.toLocaleLowerCase()}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="contact-profile-text">
                      {email?.toLocaleLowerCase()}
                    </Text>
                  )}
                </Col>
                <Col>
                  <Text className="contact-profile-text margin-left-1">|</Text>
                  <PhoneOutlined
                    rotate={90}
                    className="table-mail-icon margin-left-1"
                  />
                  <Text className="contact-profile-text">
                    {countryCode}&nbsp;
                    {phone}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col md={5} lg={6} className="profile-branch-position">
              <Text className="contact-profile-title">Branch:</Text>
              {branchName.length > 15 ? (
                <Tooltip title={branchName} placement="bottom">
                  <Text
                    className="contact-profile-branch padding-left-1"
                    ellipsis={true}
                  >
                    {branchName}
                  </Text>
                </Tooltip>
              ) : (
                <Text className="contact-profile-text padding-left-1">
                  {branchName}
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
