/* eslint-disable no-unused-vars */
import { postRegistration, postAmRegistration } from '@luzon/services.api';
import { errorHandler } from '@moxie/utils';
import { IRegistration } from '@shared-components/models';
import { Registration } from '@shared-components/modules';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RegistrationPage: React.FC = () => {
  const history = useHistory();
  const AM = 'marketing'
  const handleSubmit = async (
    data: IRegistration,
    setLoading: (bool: boolean) => void
  ) => {
    try {
      setLoading(true);
      let res: any;
      if(data.feature === AM){
        res = await postAmRegistration(data);
      }else{
        res = await postRegistration(data);
      }
      
      if (res?.status === 201) history.push('/registration-success');
      setLoading(false);
    } catch (err) {
      errorHandler(err);
      setLoading(false);
    }
  };

  return <Registration onSubmit={handleSubmit} />;
};

export default RegistrationPage;
