import { getAssociatedOfficeBranches } from '@crm/services.api';
import { useQuery } from '@tanstack/react-query';

export const useAssignedBranch = () => {
    const {data, isLoading} = useQuery({
        queryFn:() => getAssociatedOfficeBranches(),
        queryKey:['user-associated-branches'],
    })

    return {
        branches: data?.data ?? [],
        isLoading
    }
}

