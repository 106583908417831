import React from 'react';
import { Form, Input, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Button, FormItem } from '@shared-components/elements';
import { IAuth, ILoginForm } from '@shared-components/models';
import {
  URL_LOGIN,
  PASSWORD_LABEL,
  PASSWORD_PLACEHOLDER,
  CONFIRM_PASSWORD_LABEL,
  CONFIRM_PASSWORD_PLACEHOLDER,
  FORM_SIZE,
  CONFIRM_PASSWORD_DIDNT_MATCH,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_REQUIRED,
} from '@moxie/constants';

const { Paragraph } = Typography;

const Auth: React.FC<ILoginForm<IAuth>> = ({
  onSubmit,
  loading,
}: ILoginForm<IAuth>) => {
  const handleSubmit = (data: IAuth) => {
    onSubmit(data);
  };

  return (
    <Form
      layout="vertical"
      size={FORM_SIZE}
      name="login-form"
      data-testid="auth-form"
      className="auth-layout__form"
      onFinish={handleSubmit}
    >
      <div className="header">
        <FormItem
          name="password"
          label={PASSWORD_LABEL}
          className="lyra-label"
          rules={[{ required: true, message: PASSWORD_REQUIRED }]}
        >
          <Input.Password
            placeholder={PASSWORD_PLACEHOLDER}
            prefix={<LockOutlined></LockOutlined>}
          ></Input.Password>
        </FormItem>
        <FormItem
          name="passwordConfirm"
          label={CONFIRM_PASSWORD_LABEL}
          className="lyra-label"
          rules={[
            { required: true, message: CONFIRM_PASSWORD_REQUIRED },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(CONFIRM_PASSWORD_DIDNT_MATCH));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={CONFIRM_PASSWORD_PLACEHOLDER}
            prefix={<LockOutlined></LockOutlined>}
          ></Input.Password>
        </FormItem>
      </div>
      <div className="footer">
        <Space direction="vertical" align="center">
          <Button type="primary" htmlType="submit" loading={loading}>
            Reset
          </Button>
        </Space>
      </div>
      <div className="padding-top-2">
        <Link to={URL_LOGIN}>
          <Paragraph type="secondary">Back to Sign In</Paragraph>
        </Link>
      </div>
    </Form>
  );
};

export { Auth };
