import React, { useEffect, useState } from 'react';
import {
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  GUTTER,
  INTAKES,
  LABEL,
  NOTES_LABEL,
  PLACEHOLDER,
  PRODUCT_DETAILS,
  PRODUCT_INFORMATION,
  PRODUCT_NAME_REQUIRED,
  REVENUE_TYPE,
  TEXT,
} from '@moxie/constants';
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Spin,
} from 'antd';
import {
  IGetPopupContainer,
  IInstituteInterface,
  ProductFormProps,
} from '@shared-components/models';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  getInstitutionByService,
  getServices,
  getSubjectDisciplines,
  getWorkflowsByInstitution,
  productServices,
} from '@admin/services.api';
import { Editor } from 'libs/shared/src/modules/forms/editor';
import { formExtraNotes, preventMinus } from 'libs/shared/src/functions';
import { useAppSelector } from '@admin/core';
const { Option } = Select;
const { Panel } = Collapse;

const ProductForm = ({
  form,
  initialData,
  onSubmit,
  setDisabled,
  isEdit,
}: ProductFormProps) => {
  const [subjectDisciplineData, setSubjectDisciplineData] = useState<any>([]);
  const [subjectAreasData, setSubjectAreasData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceId, setServiceId] = useState<String>('');

  const [subTypeLevelData, setSubTypeLevelData] = useState([]);
  const [workflowsData, setWorkflowsData] = useState([]);
  const [institutionData, setInstitutionData] = useState<IInstituteInterface>(
    []
  );
  const [institutionBranchesData, setInstitutionBranchesData] = useState([]);
  const [productTypeData, setProductTypeData] = useState<any>([]);
  const [formDataLoading, setFormDataLoading] = useState<boolean>(false);
  const productLoading = useAppSelector((state) => state.product.loading);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [institutionLoading, setInstitutionLoading] = useState<boolean>(false);
  const [branchesLoading, setBranchesLoading] = useState<boolean>(false);
  const [workflowLoading, setWorkflowLoading] = useState<boolean>(false);
  const [productTypeLoading, setProductTypeLoading] = useState<boolean>(false);
  const [degreeLevelLoading, setDegreeLevelLoading] = useState<boolean>(false);
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false);
  const [subjectAreaLoading, setSubjectAreaLoading] = useState<boolean>(false);

  const setFieldsValue = async () => {
    setInstitutionBranchesData(initialData?.institution?.branches);
    const workflowsByInstitution = await getWorkflowsByInstitution(
      initialData.institution.id
    );
    setWorkflowsData(workflowsByInstitution.data.data);
    setSubjectAreasData(
      subjectDisciplineData.find(
        (item: any) => item.id === initialData?.subject_disciplines_id
      )?.subject_areas || []
    );
    const branchesList = initialData?.institutionBranches?.map(
      (item: any) => item?.institution_branch_id
    );
    const workflowList = initialData?.institution_workflows?.map(
      (item: any) => item?.id
    );
    const intakeList = initialData?.institutionBranches[0]?.intakes.map(
      (item: any) => item?.intake
    );
    setSubTypeLevelData(initialData?.productType?.sub_type_level);
    form.setFieldsValue({
      product_duration_month: initialData.product_duration_month,
      product_duration_week: initialData.product_duration_week,
      product_duration_year: initialData.product_duration_year,
      branches: branchesList,
      workflows: workflowList,
      service: initialData?.institution?.service_id,
      institution_id: !institutionLoading ? initialData?.institution?.id : '',
      intakes: intakeList,
      name: initialData?.name,
      product_sub_type_id: initialData.product_sub_type_id,
      product_type_id: initialData.product_type_id,
      program_link: initialData?.program_link,
      revenue_type: initialData?.revenue_type,
      description: initialData.description,
      subject_disciplines_id: initialData?.subject_disciplines_id,
      subject_area_id: initialData?.subject_area_id,
      identification_system_code: initialData?.identification_system_code,
    });
    setFormDataLoading(false);
  };

  const getSubjectDiscipline = async () => {
    setDisciplineLoading(true);
    const res = await getSubjectDisciplines();
    setSubjectDisciplineData(res?.data?.data);
    setDisciplineLoading(false);
  };

  const getService = async () => {
    setServiceLoading(true);
    const res = await getServices();
    setServiceData(res?.data?.data);
    setServiceLoading(false);
    if (initialData.id) {
      const res = await getInstitutionByService(
        initialData?.institution?.service_id
      );
      setInstitutionData(res?.data?.data);
      setInstitutionBranchesData(initialData?.institution?.branches);
      setWorkflowsData(initialData?.institution?.workflows);
    }
  };

  const getProductTypes = async () => {
    setProductTypeLoading(true);
    const res = await productServices.apiGetProductTypes();
    setProductTypeData(res?.data?.data);
    setProductTypeLoading(false);
  };

  const getInstitution = async (id: string) => {
    setInstitutionLoading(true);
    const res = await getInstitutionByService(id);
    setInstitutionData(res?.data?.data);
    setInstitutionLoading(false);
    !isEdit &&
      form.setFieldsValue({
        branches: undefined,
        workflows: undefined,
        institution_id: undefined,
      });
  };

  const handleChange = (data: string) => {};
  const getSubjectAreas = (data: any) => {
    setSubjectAreaLoading(true);
    setSubjectAreasData(
      subjectDisciplineData.find((item: any) => item.id === data)
        ?.subject_areas || []
    );
    setSubjectAreaLoading(false);

    form.setFieldsValue({
      subject_area_id: undefined,
    });
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length === 1) {
      setDisabled(false);
    }
  };

  const getInstitutionBranches = async (data: string) => {
    setBranchesLoading(true);
    const selectedData =
      institutionData &&
      institutionData.find((item: IInstituteInterface) => item?.id === data);
    setInstitutionBranchesData(selectedData?.branches);
    setBranchesLoading(false);
    setWorkflowLoading(true);
    const res = await getWorkflowsByInstitution(data);
    setWorkflowsData(res?.data?.data);
    setWorkflowLoading(false);
    form.setFieldsValue({
      branches: undefined,
      workflows: undefined,
    });
  };

  const handleProductType = async (value: any) => {
    form.resetFields(['product_sub_type_id']);
    setDegreeLevelLoading(true);
    setSubTypeLevelData(
      productTypeData
        ?.map((item: any) => {
          if (item.id === value) {
            return item.sub_type_level;
          }
        })
        .filter((item: any) => item !== (null || undefined))[0]
    );
    setDegreeLevelLoading(false);
  };

  useEffect(() => {
    if (isEdit && productLoading) setFormDataLoading(true);
    getService();
    if (isEdit && initialData?.id) {
      getInstitution(initialData?.institution?.service_id);
      setFieldsValue();
    } else {
      form.setFieldsValue({
        product_duration_month: 0,
        product_duration_week: 0,
        product_duration_year: 0,
      });
    }
    getSubjectDiscipline();
    getProductTypes();
  }, [initialData, form]);

  return formDataLoading ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      scrollToFirstError
      onFieldsChange={onFieldsChange}
    >
      <Collapse
        defaultActiveKey={['1']}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{PRODUCT_DETAILS}</span>} key="1">
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                label={LABEL.NAME}
                name="name"
                rules={[
                  { required: true, max: 50 },
                  {
                    whitespace: true,
                    type: 'string',
                    message: PRODUCT_NAME_REQUIRED,
                  },
                ]}
              >
                <Input className="note-title-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div id="product_service" className="relative">
                <Form.Item
                  label={LABEL.SERVICE}
                  name="service"
                  rules={[{ required: true }]}
                >
                  <Select
                    value={initialData?.service}
                    disabled={isEdit}
                    onChange={getInstitution}
                    placeholder={PLACEHOLDER.SELECT_SERVICE}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_service')
                    }
                  >
                    {serviceLoading ? (
                      <Option disabled value={''}>
                        <Spin spinning size="small" className="full-width" />
                      </Option>
                    ) : (
                      serviceData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="product_institution" className="relative">
                <Form.Item
                  label={LABEL.INSTITUTION}
                  name="institution_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={getInstitutionBranches}
                    disabled={isEdit}
                    value={initialData?.institution?.id}
                    placeholder={PLACEHOLDER.SELECT_INSTITUTION}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_institution')
                    }
                  >
                    {institutionLoading ? (
                      <Option disabled value={''}>
                        <Spin spinning size="small" className="full-width" />
                      </Option>
                    ) : (
                      institutionData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <div id="product_branches" className="relative">
                <Form.Item
                  label={LABEL.BRANCHES}
                  name="branches"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="note-title-input"
                    placeholder={PLACEHOLDER.SELECT_BRANCH}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_branches')
                    }
                    mode="multiple"
                  >
                    {workflowLoading ? (
                      <Option value={''} disabled>
                        <Spin spinning size="small" className="full-width" />
                      </Option>
                    ) : (
                      institutionBranchesData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.name}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="product_workflow" className="relative">
                <Form.Item
                  label={LABEL.WORKFLOW}
                  name="workflows"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="note-title-input"
                    placeholder={PLACEHOLDER.SELECT_WORKFLOW}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_workflow')
                    }
                    mode="multiple"
                  >
                    {workflowLoading ? (
                      <Option value={''} disabled>
                        <Spin spinning size="small" className="full-width" />
                      </Option>
                    ) : (
                      workflowsData &&
                      workflowsData.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.workflow?.workflowType?.name} (
                            {data?.workflow?.country})
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                name="identification_system_code"
                rules={[{ max: 15 }]}
                label={LABEL.CRICOS_COURSE_CODE}
              >
                <Input className="note-title-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div id="product_type" className="relative">
                <Form.Item
                  label={LABEL.PRODUCT_TYPE}
                  name="product_type_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="note-title-input"
                    value={initialData?.product_type_id}
                    onChange={handleProductType}
                    placeholder={PLACEHOLDER.PRODUCT_TYPE}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_type')
                    }
                  >
                    {productTypeLoading ? (
                      <Option value={''} disabled>
                        <Spin spinning className="full-width" size="small" />
                      </Option>
                    ) : (
                      productTypeData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="product_sub_type_level" className="relative">
                <Form.Item
                  label={LABEL.PRODUCT_SUB_TYPE}
                  name="product_sub_type_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    className="note-title-input"
                    value={initialData?.product_sub_type_id}
                    placeholder={PLACEHOLDER.PRODUCT_SUB_TYPE}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_sub_type_level')
                    }
                  >
                    {degreeLevelLoading ? (
                      <Option disabled value={''}>
                        <Spin spinning className="full-width" size="small" />
                      </Option>
                    ) : (
                      subTypeLevelData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <Form.Item
                label={LABEL.COMMISSION_TYPE}
                name="revenue_type"
                rules={[{ required: true }]}
              >
                <Radio.Group className="note-title-input">
                  <Radio value="Commission" key="1" checked={true}>
                    {REVENUE_TYPE.COMMISSION_FROM_PARTNER}
                  </Radio>
                  <Radio key="2" value="Contact fee">
                    {REVENUE_TYPE.REVENUE_FROM_CONTACT}
                  </Radio>
                  ))
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Collapse
        defaultActiveKey={['1']}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{PRODUCT_INFORMATION}</span>} key="1">
          <Row gutter={GUTTER}>
            <Col span={12}>
              <div id="product_discipline" className="relative">
                <Form.Item label={LABEL.SUBJECT} name="subject_disciplines_id">
                  <Select
                    onChange={getSubjectAreas}
                    className="note-title-input"
                    value={initialData?.subject_disciplines_id}
                    placeholder={LABEL.SELECT_DISCIPLINE}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_discipline')
                    }
                  >
                    {disciplineLoading ? (
                      <Option value={''} disabled>
                        <Spin spinning className="full-width" size="small" />
                      </Option>
                    ) : (
                      subjectDisciplineData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.discipline}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="product_subject_area" className="relative">
                <Form.Item
                  label={LABEL.SUBJECT_AREA}
                  name="subject_area_id"
                  extra={
                    !form.getFieldValue('subject_disciplines_id')
                      ? formExtraNotes('Discipline')
                      : ''
                  }
                >
                  <Select
                    className="note-title-input"
                    value={initialData?.subject_area_id}
                    placeholder={LABEL.SELECT_SUBJECT_AREA}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_subject_area')
                    }
                  >
                    {subjectAreaLoading ? (
                      <Option value={''} disabled>
                        <Spin spinning className="full-width" size="small" />
                      </Option>
                    ) : (
                      subjectAreasData &&
                      subjectAreasData.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col span={16}>
              <p className="ant-form-text padding-bottom-1">{TEXT.DURATIONS}</p>
              <br></br>
              <Row className="product-addon">
                <Col span={8}>
                  <Form.Item name="product_duration_year">
                    <Input
                      min={0}
                      onKeyPress={preventMinus}
                      type="number"
                      addonAfter={TEXT.YEARS}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'product_duration_month'}>
                    <Input
                      min={0}
                      type="number"
                      onKeyPress={preventMinus}
                      width={30}
                      addonAfter={TEXT.MONTHS}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="product_duration_week">
                    <Input
                      min={0}
                      onKeyPress={preventMinus}
                      type="number"
                      addonAfter={TEXT.WEEKS}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <div id="product_intake" className="relative">
                <Form.Item label={LABEL.INTAKES} name="intakes">
                  <Select
                    className="note-title-input"
                    placeholder={PLACEHOLDER.INTAKES}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_intake')
                    }
                    mode={'multiple'}
                  >
                    {INTAKES.map((data) => {
                      return (
                        <Option value={data?.value} key={data?.value}>
                          {data?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12} className="padding-top-3">
              <Form.Item>
                <Checkbox>{TEXT.SELECT_ALL_INTAKES}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={TEXT.PROGRAM_LINK} name="program_link">
                <Input
                  placeholder={TEXT.PROGRAM_LINK}
                  className="note-title-input"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={DESCRIPTION_LABEL} name="description">
                <Editor
                  value="description"
                  placeholder={DESCRIPTION_PLACEHOLDER}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
};
export { ProductForm };
