import axios from './axios';
import { IOfficeVisit, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const officeVisitAdd = (payload: IOfficeVisit): Promise<IResponse> => {
  return axios.post(`${API_URL.OFFICE_VISIT}`, payload);
};

const officeVisitFetch = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_VISIT}/${id}`);
};

const officeVisitList = (params = ``): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_VISIT}/${params}`);
};

const officeVisitUpdate = (
  id: string,
  payload: IOfficeVisit
): Promise<IResponse> => {
  return axios.put(`${API_URL.OFFICE_VISIT}/${id}`, payload);
};

const fetchPrevVisit = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_VISIT}/${id}/previous-visits/`);
};

const fetchPrevVisitByContact = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_VISIT}/previous-visits/contact/${id}`);
};

const getAllVisitActivityLogs = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.VISIT_ACTIVITY_LOG}/${id}`);
};

const getOfficeVisitsByContact = async (
  contactId: string,
  params?: any
): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_VISIT}/contact/${contactId}`, {
    params,
  });
};

export {
  officeVisitAdd,
  officeVisitFetch,
  officeVisitUpdate,
  officeVisitList,
  fetchPrevVisit,
  fetchPrevVisitByContact,
  getAllVisitActivityLogs,
  getOfficeVisitsByContact,
};
