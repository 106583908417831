import { productActions } from '@crm/core';
import { PRODUCT_RESPONSE_MESSAGE } from '@moxie/constants';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { useMutation } from '@tanstack/react-query';
import { deleteCRMProduct } from 'apps/crm/src/libs/services.api/lib/product.api';
import { useDispatch } from 'react-redux';

export const useDeleteProduct = (
  currentPage: any,
  search: any,
  selectedService: any,
  selectedCountry: any
) => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (productId: string) => deleteCRMProduct(productId!),
    onSuccess: () => {
      successNotificationHandler(
        `${PRODUCT_RESPONSE_MESSAGE.DELETE_SUCCESS}`,
        1.5
      );
      dispatch(
        productActions.getProductsRequest({
          page: currentPage,
          s: search,
          service: selectedService,
          country: selectedCountry,
        })
      );
    },
    onError: () => {
      errorNotificationHandler(
        `${PRODUCT_RESPONSE_MESSAGE.CANNOT_DELETE}`,
        1.5
      );
    },
  });

  const deleteProduct = (data: any) => {
    mutation.mutate(data);
  };

  return {
    deleteProduct,
  };
};
