import {
  IAction,
  IInstitutionDetail,
  IInstitutionPayload,
} from '@shared-components/models';
import {
  CLEAR_BRANCH_FETCH,
  GET_BRANCHES_BY_INSTITUTION_FAILURE,
  GET_BRANCHES_BY_INSTITUTION_REQUEST,
  GET_BRANCHES_BY_INSTITUTION_SUCCESS,
} from './branch-institution.constant';

export const getBranchesByInstitutionRequest = (
  id: string
): IAction<string> => ({
  type: GET_BRANCHES_BY_INSTITUTION_REQUEST,
  payload: id,
});

export const getBranchesByInstitutionSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: GET_BRANCHES_BY_INSTITUTION_SUCCESS,
  payload,
});

export const getBranchesByInstitutionFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: GET_BRANCHES_BY_INSTITUTION_FAILURE,
  payload,
});

export const clearBranchFetch = () => ({
  type: CLEAR_BRANCH_FETCH,
});
