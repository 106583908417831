import { AxiosResponse } from 'axios';
import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import * as institutionBranches from './branch-institution.constant';
import * as institutionBranchesActions from './branch-institution.action';
import { apiGetInstitutionBranches } from '../../libs/services.api/lib';

export function* branchesByInstitution({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield apiGetInstitutionBranches(payload);
      yield put(
        institutionBranchesActions.getBranchesByInstitutionSuccess(
          response.data.data
        )
      );
    }
  } catch (e) {
    errorHandler(e);
    yield put(institutionBranchesActions.getBranchesByInstitutionFailure(e));
  }
}

export default function* branchesByInstitutionSaga(): unknown {
  yield takeLatest(
    institutionBranches.GET_BRANCHES_BY_INSTITUTION_REQUEST,
    branchesByInstitution
  );
}
