import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TEXT } from '@moxie/constants';
import { OverViewHeader } from '@shared-components/elements';
import {
  DashboardLayout,
  UserProfile,
  UserTabSection,
} from '@shared-components/modules';
import { useDispatch } from 'react-redux';

import { errorHandler } from '@moxie/utils';
import { authActions, useAppSelector } from '@crm/core';

export const useProfile = (): [loading: boolean] => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchLeadData = () => {
      try {
        setLoading(true);
        dispatch(authActions.getAuthUser());
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeadData();
  }, [dispatch, history]);

  return [loading];
};

const DashboardProfile: React.FC = () => {
  const statUser = useAppSelector((state) => state.auth.user);
  useProfile();
  return (
    <DashboardLayout>
      <OverViewHeader title={TEXT.PROFILE} />
      {statUser && <UserProfile data={statUser} />}
      {statUser && <UserTabSection lead={statUser} />}
    </DashboardLayout>
  );
};

export default DashboardProfile;
