import { API_URL } from '@moxie/constants';
import { axiosInstance as axios } from './axios';

const getSubjectDisciplines = async () => {
  return axios.get(`${API_URL.CRM_SUBJECT_DISCIPLINES}`);
};

const getSubjectAreas = async (id: string) => {
  return axios.get(`${API_URL.CRM_SUBJECT_DISCIPLINES}/subject-area/${id}`);
};

const getEducationDegreeLevel = async () => {
  return axios.get(`${API_URL.EDUCATION_BACKGROUND_LEVELS}`);
};

export { getSubjectDisciplines, getSubjectAreas, getEducationDegreeLevel };
