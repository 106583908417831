import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import themeSaga from '../theme/theme.saga';
import authSaga from '../auth/auth.saga';
import userSaga from '../user/user.saga';
import serviceSaga from '../service/service.saga';
import rolePermissionSaga from '../role_permissions/role-permission.saga';
import officeBranchSaga from '../office/office-branch.saga';
import officeVisitSaga from '../office-visit/office-visit.saga';
import institutionSaga from '../institutions/institution.saga';
import crmWorkflowTypeSaga from '../workflow-type/workflow-type.saga';
import leadSaga from '../lead/lead.saga';
import crmWorkflowSaga from '../workflow/workflow.saga';
import contactSaga from '../contact/contact.saga';
import subjectDisciplineSaga from '../subject-disciplines/subject-discipline.saga';
import documentSaga from '../documents/document.saga';
import branchesByInstitutionSaga from '../branch-institution/branch-institution.saga.';
import productTypeByServiceIdSaga from '../product-type/product-type.saga';
import productSaga from '../product/product.saga';
import agentPartnerSaga from '../agent-partner/agent-partner.saga';
import countryByServiceSaga from '../country/country-service.saga.';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<unknown>>,
  void
> {
  yield all(
    [
      themeSaga,
      authSaga,
      userSaga,
      serviceSaga,
      rolePermissionSaga,
      officeBranchSaga,
      officeVisitSaga,
      institutionSaga,
      crmWorkflowTypeSaga,
      leadSaga,
      crmWorkflowSaga,
      contactSaga,
      subjectDisciplineSaga,
      documentSaga,
      branchesByInstitutionSaga,
      productTypeByServiceIdSaga,
      productSaga,
      agentPartnerSaga,
      countryByServiceSaga,
    ].map(fork)
  );
}
