import { useAppSelector } from '@crm/core';
import {
    addEnglishTestScore,
    deleteEnglishTestScore,
    updateEnglishTestScore,
} from '@crm/services.api';
import {
    errorNotificationHandler,
    successNotificationHandler,
} from '@moxie/shared';
import {
    IAddEnglishTestScore,
    IUpdateEnglishTestScore,
} from '@shared-components/models';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useContactParam from './useContactParam';

export const useIeltsMutation = () => {
    const contact_id = useContactParam();
    const queryClient = useQueryClient();

    const socket = useAppSelector(state => state.socket.wss);
    const user = useAppSelector(state => state.auth.user);

    return useMutation({
        mutationFn: (body: IAddEnglishTestScore) => addEnglishTestScore(body),
        onSuccess: async ({ data }) => {
            successNotificationHandler(data?.message);
            queryClient.invalidateQueries({
                queryKey: ['englishTestScore'],
            });

            socket?.emit('server::profile-updated', {
                activities_type_id: data.data.id,
                activities_type: "language-test",
                activities_action: 'created',
                crm_user_id: user?.id,
                contact_id: contact_id,
                company_id: user?.company_id,
                data: data,
            });
        },
        onError: () => {
            errorNotificationHandler('Error adding IELTS score.');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['ielts'] });
        },
    });
};

export const useIeltsUpdate = () => {
    const contact_id = useContactParam();

    const queryClient = useQueryClient();

    const socket = useAppSelector(state => state.socket.wss);
    const user = useAppSelector(state => state.auth.user);

    return useMutation({
        mutationFn: (body: IUpdateEnglishTestScore) => updateEnglishTestScore(body),
        onSuccess: async ({ data }) => {
            successNotificationHandler(data?.message);
            queryClient.invalidateQueries({
                queryKey: ['englishTestScore'],
            });
            socket?.emit('server::profile-updated', {
                activities_type_id: data.data.id,
                activities_type: "language-test",
                activities_action: 'updated',
                crm_user_id: user?.id,
                contact_id: contact_id,
                company_id: user?.company_id,
                data: data,
            });
        },
        onError: () => {
            errorNotificationHandler('Error updating IELTS score.');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['updateIelts'] });
        },
    });
};

export const useIeltsDelete = () => {
    const contact_id = useContactParam();
    const queryClient = useQueryClient();

    const socket = useAppSelector(state => state.socket.wss);
    const user = useAppSelector(state => state.auth.user);

    return useMutation({
        mutationFn: (id: string) => deleteEnglishTestScore(id),
        onSuccess: async ({ data }) => {
            successNotificationHandler(data?.message);
            queryClient.invalidateQueries({
                queryKey: ['englishTestScore'],
            });

            data.name = 'ielts';
            socket?.emit('server::profile-updated', {
                activities_type: "language-test",
                activities_action: 'deleted',
                crm_user_id: user?.id,
                contact_id: contact_id,
                company_id: user?.company_id,
                data: { data },
            });
        },
        onError: () => {
            errorNotificationHandler('Error deleting IELTS score.');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['deleteIelts'] });
        },
    });
};
