import { IAction, ILead } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './lead.action';
import * as action from './lead.constant';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import {
  deleteLead,
  postLead,
  updateLead,
  archiveLead,
} from '@crm/services.api';

export const addLead = function* ({ payload, triggerRefresh }: IAction<ILead>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postLead(payload);
      yield put(actionTypes.addLeadSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh(response.data);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTypes.addLeadFailure(err));
  }
};

export const deleteLeadData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteLead(payload);
      yield put(actionTypes.deleteLeadSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTypes.deleteLeadFailure(err));
  }
};

export const archiveLeadData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield archiveLead(
        payload?.id,
        payload?.data
      );
      yield put(actionTypes.archiveLeadSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh?.();
    }
  } catch (err) {
    errorHandler(err);
    yield put({ type: actionTypes.archiveLeadFailure });
  }
};

function* editLead({ payload, triggerRefresh }: IAction<ILead>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateLead(payload);
      yield put({ type: actionTypes.editLeadSuccess, payload: response.data });
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh(response.data);
    }
  } catch (e) {
    errorHandler(e);
    yield put({ type: actionTypes.editLeadFailure(e) });
  }
}

const leadSaga = function* (): unknown {
  yield takeLatest(action.ADD_LEAD_REQUEST, addLead);
  yield takeLatest(action.DELETE_LEAD_REQUEST, deleteLeadData);
  yield takeLatest(action.UPDATE_LEAD_REQUEST, editLead);
  yield takeLatest(action.ARCHIVE_LEAD_REQUEST, archiveLeadData);
};

export default leadSaga;
