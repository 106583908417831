import React, { useEffect } from 'react';
import { WorkflowDetailComponent } from '@crm/shared';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { authActions, workflowActions } from '@crm/core';

const WorkflowDetail = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(authActions.getAuthUser());
      dispatch(workflowActions.getCRMWorkflowRequest(id));
      dispatch(workflowActions.getWorkflowStagesRequest(id));
    }

    return () => {
      dispatch(workflowActions.clearCRMWorkflowFetch());
      dispatch(workflowActions.clearWfStageChecklist());
    };
  }, [id, dispatch]);

  return <WorkflowDetailComponent />;
};

export { WorkflowDetail };
