import { IAction, IPayload, IProduct } from '@shared-components/models';
import {
  CLEAR_PRODUCT_SUB_TYPE_FETCH,
  CLEAR_PRODUCT_TYPE_FETCH,
  FETCH_PRODUCT_SUB_TYPES_FAILURE,
  FETCH_PRODUCT_SUB_TYPES_REQUEST,
  FETCH_PRODUCT_SUB_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPE_BY_SERVICE_ID_FAILURE,
  FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST,
  FETCH_PRODUCT_TYPE_BY_SERVICE_ID_SUCCESS,
} from './product-type.constant';

// export const getProductTypeByServiceIdRequest = (
//   id: string
// ): IAction<string> => ({
//   type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST,
//   id,
// });

// export const getProductTypeByServiceIdSuccess = (
//   payload: any[]
// ): IAction<any[]> => ({
//   type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_SUCCESS,
//   payload,
// });

// export const getProductTypeByServiceIdFailure = (
//   payload: IPayload<IProduct>
// ): IAction => ({
//   type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_FAILURE,
//   payload,
// });

export const getProductTypeByServiceIdRequest = (): IAction<string> => ({
  type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST,
});

export const getProductTypeByServiceIdSuccess = (
  payload: any[]
): IAction<any[]> => ({
  type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_SUCCESS,
  payload,
});

export const getProductTypeByServiceIdFailure = (
  payload: IPayload<IProduct>
): IAction => ({
  type: FETCH_PRODUCT_TYPE_BY_SERVICE_ID_FAILURE,
  payload,
});

export const clearProductTypeFetch = () => ({
  type: CLEAR_PRODUCT_TYPE_FETCH,
});

export const getProductSubTypesRequest = (id: string): IAction<string> => ({
  type: FETCH_PRODUCT_SUB_TYPES_REQUEST,
  id,
});

export const getProductSubTypesSuccess = (payload: any[]): IAction<any[]> => ({
  type: FETCH_PRODUCT_SUB_TYPES_SUCCESS,
  payload,
});

export const getProductSubTypesFailure = (
  payload: IPayload<IProduct>
): IAction => ({
  type: FETCH_PRODUCT_SUB_TYPES_FAILURE,
  payload,
});

export const clearProductSubTypeFetch = () => ({
  type: CLEAR_PRODUCT_SUB_TYPE_FETCH,
});
