import axios from './axios';
import { ILeadApplicationForm, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getLeadApplications = async (
  contactId: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/contacts/${contactId}/${params}`);
};

const getActiveContactApplications = async (
  contactId: string,
): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/active/contacts/${contactId}/`);
};

const getApplication = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/${id}`);
};

const addLeadApplication = async (
  body: ILeadApplicationForm
): Promise<IResponse> => {
  return axios.post(`${API_URL.APPLICATIONS}`, body);
};

const updateLeadApplication = async (
  body: ILeadApplicationForm
): Promise<IResponse> => {
  return axios.put(`${API_URL.APPLICATIONS}/update`, body);
};

const getServices = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.SERVICES}/list`);
};

const getServiceWorkflows = async (serviceId: string): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/${serviceId}${API_URL.SERVICES}`);
};

const getWorkflowByServiceCountry = async (
  serviceId: string,
  country: string
) => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/${serviceId}/services/${country}`);
};

const getInstitutionByServiceCountry = async (
  serviceId: string,
  country: string
): Promise<IResponse> => {
  return axios.get(
    `${API_URL.APPLICATIONS}${API_URL.INSTITUTIONS}/${serviceId}/${country}`
  );
};

const getInstitutionProducts = async (
  institutionBranchId: string
): Promise<IResponse> => {
  return axios.get(
    `${API_URL.PRODUCTS}/${institutionBranchId}/institution-branches`
  );
};

const deleteApplication = async (id: string) => {
  return axios.delete(`${API_URL.APPLICATIONS}/${id}`);
};

const getChecklistDocuments = async (
  checklistId: number,
  wfAppStageId: string,
  applicationId: string
) => {
  return axios.get(
    `${API_URL.WORKFLOW_CHECKLIST_DOCUMENTS}/${checklistId}/${wfAppStageId}/${applicationId}`
  );
};

const saveChecklistDocuments = async (body: any): Promise<IResponse> => {
  return axios.post(`${API_URL.WORKFLOW_CHECKLIST_DOCUMENTS}`, body);
};

const uploadAndSaveChecklistDocuments = async (
  body: FormData
): Promise<IResponse> => {
  return axios.post(`${API_URL.CHECKLIST_DOCUMENTS}`, body);
};

const getSelectedStageChecklistData = async (
  appId: string,
  wfAppStageId: string
): Promise<any> => {
  return axios.get(`${API_URL.APPLICATIONS}/${appId}/${wfAppStageId}`);
};

export {
  getApplication,
  getLeadApplications,
  addLeadApplication,
  updateLeadApplication,
  getServices,
  getServiceWorkflows,
  getInstitutionProducts,
  deleteApplication,
  getChecklistDocuments,
  uploadAndSaveChecklistDocuments,
  getSelectedStageChecklistData,
  saveChecklistDocuments,
  getWorkflowByServiceCountry,
  getInstitutionByServiceCountry,
  getActiveContactApplications
};
