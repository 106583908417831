import { TEXT } from '@moxie/constants';
import { ITableNameCardProps } from '@shared-components/models';
import { Avatar, Button, Col, Dropdown, Row, Tooltip, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MailOutlined, MoreOutlined, LinkOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '@moxie/shared';

export const UserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  index,
  actionBar,
  link,
  menu,
  showEmailAsLink,
  showMailIcon,
  size,
  handleEdit,
}: ITableNameCardProps) => {
  const [ellipsis] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  return (
    <Row className="lead-table__name user-name-card">
      <div className="align-items-center user-name-card__avatar">
        {link ? (
          <Link to={link}>
            <Avatar size={size} className="initial_capital">
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Link>
        ) : (
          <div onClick={handleEdit && handleEdit}>
            <Avatar size={size} className="initial_capital">
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        )}
      </div>
      <Col className="user-name-card__name">
        <div key={index}>
          <Col className='initial_capital'>
            {link ? (
              <Link to={link}>
                <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, tooltip: `${capitalizeFirstLetter(firstName as string)} ${capitalizeFirstLetter(lastName as string)}` }} className="text-primary-color initial_capital para-ellipsis-height">{`${firstName} ${lastName}`}</Typography.Paragraph>
              </Link>
            ) : (
              <>
                {showMailIcon ? (
                  <Col
                    onClick={handleEdit && handleEdit}
                    className="lead__hover-color"
                  >
                    <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, tooltip: `${capitalizeFirstLetter(firstName as string)} ${capitalizeFirstLetter(lastName as string)}` }} className="text-primary-color initial_capital para-ellipsis-height">{`${firstName} ${lastName}`}</Typography.Paragraph>
                  </Col>
                ) : (
                  <Col onClick={handleEdit && handleEdit}>
                    <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, tooltip: `${capitalizeFirstLetter(firstName as string)} ${capitalizeFirstLetter(lastName as string)}` }} className="text-primary-color initial_capital para-ellipsis-height">{`${firstName} ${lastName}`}</Typography.Paragraph>
                  </Col>
                )}
              </>
            )}
          </Col>
          <Col>
            <Row className="email_div">
              {showEmailAsLink === true ? (
                <>
                  {showMailIcon && (
                    <Col>
                      <a href={`mailto:${email}`}>
                        {' '}
                        <MailOutlined className="table-mail-icon" />{' '}
                      </a>
                    </Col>
                  )}
                  <Col lg={19}>
                    <small className="table-elements-email-container">
                      <a
                        className="text-primary-color"
                        href={`mailto:${email}`}
                      >
                        <Paragraph
                          ellipsis={
                            ellipsis ? { tooltip: `${email}` } : undefined
                          }
                          className="text-primary-color"
                        >
                          {email?.toLowerCase()}
                        </Paragraph>
                      </a>
                    </small>
                  </Col>
                </>
              ) : (
                <Col lg={18}>
                  <small>
                    <Paragraph
                      className="assigned-user-email text-primary-color"
                      ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                    >
                      {email?.toLowerCase()}
                    </Paragraph>
                  </small>
                </Col>
              )}
              {showEmailAsLink && (
                <Col className="hide">
                  <Tooltip
                    className=" margin-left-1 "
                    placement="right"
                    color={isCopied ? '#8EAF74' : '#9B9D9A '}
                    title={
                      isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                    }
                  >
                    <LinkOutlined
                      className="copy_email_icon"
                      onClick={() => copyToClipboard(`${email}`)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </Col>
      {actionBar && menu !== null && (
        <Col className="user-name-card__action">
          {menu && (
            <Dropdown overlay={menu}>
              <Button
                type="text"
                className="table-cell-action-btn"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          )}
        </Col>
      )}
    </Row>
  );
};
