import axiosInstance from '@crm/libs/services.api/lib/axios';
import { IPaginateRes } from '@model/api-response.model';
import { ContactInquiry } from '@model/contact-form';
import { useQuery } from '@tanstack/react-query';

const useContactInquiry = (
  type: string,
  page?: number,
  limit?: number,
  search?: string
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['inquiries', type, page, limit, search],
    queryFn: () =>
      axiosInstance
        .get<IPaginateRes<ContactInquiry>>('/crm/contact-inquiry', {
          params: {
            type,
            page,
            limit,
            status: 'pending',
            s: search,
          },
        })
        .then((res) => res.data),
  });

  return {
    ...data,
    isLoading,
    refetch,
  };
};

export default useContactInquiry;
