import React, { useEffect, useState } from 'react';
import Avatar from 'antd/es/avatar';
import Tooltip from 'antd/es/tooltip';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Notification as INotification } from '@model/notification';
import { Row } from 'antd';

interface Props {
  notifications: INotification[];
  item: INotification;
  handleMarkRead: (id: string) => void;
  handleMarkReadUnread: (id: string, is_read: boolean) => void;
}
const Notification: React.FC<Props> = (props) => {
  const { item, notifications, handleMarkRead, handleMarkReadUnread } = props;
  const [showRead, setShowRead] = useState(false);
  useEffect(() => {
    const notification = notifications?.filter(
      (item: any) => item?.is_read === false
    );
    if (notification?.length > 0) {
      setShowRead(true);
    } else {
      setShowRead(false);
    }
  }, [notifications, showRead]);

  return (
    <>
      <div
        className={`notification-menu__item ${!item.is_read ? 'unread' : ''}`}
        key={item.id}
      >
        <a href={`${window.location.origin}${item?.url}`}>
          <div
            className="align-items-center"
            onClick={() => handleMarkRead(item.id)}
          >
            <div className="notification-menu__item_avatar">
              <Avatar>
                {item?.crmUser?.first_name?.substring(0, 1)?.toUpperCase()}
                {item?.crmUser?.last_name?.substring(0, 1)?.toUpperCase()}
              </Avatar>
            </div>
            <Row align="middle" className="notification-message-height"> 
              <Row align="middle">
                {ReactHtmlParser(item.message)}
                </Row>

              <Row align="middle" className="notification-menu__item_date">
                <span>{moment(item.created_at).fromNow()}</span>
              </Row>
            </Row>
          </div>
        </a>
        <Tooltip
          title={item.is_read ? 'Mark as unread' : 'Mark as read'}
          placement="right"
          mouseEnterDelay={1}
        >
          <div
            className={`notification-menu__item_read_container ${
              item.is_read ? '' : 'active'
            }`}
            onClick={() => {
              handleMarkReadUnread(item.id, item.is_read);
            }}
          >
            <div className="read-marker">
              <span></span>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default Notification;
