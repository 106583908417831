import { getSubjectAreas, getSubjectDisciplines } from '@crm/services.api';
import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as subjectDisciplineAction from './subject-discipline.action';
import * as subjectDiscipline from './subject-discipline.constant';

export function* getSubjectDiscipline() {
  try {
    const response: AxiosResponse = yield getSubjectDisciplines();
    yield put(
      subjectDisciplineAction.getSubjectDisciplineSuccess(response?.data?.data)
    );
  } catch (error) {
    errorHandler(error);
    yield put(subjectDisciplineAction.getSubjectDisciplineFailure(error));
  }
}

export function* getSubjectArea(payload: any) {
  try {
    const response: AxiosResponse = yield getSubjectAreas(payload.payload);
    yield put(
      subjectDisciplineAction.getSubjectAreasSuccess(response?.data?.data)
    );
  } catch (error) {
    errorHandler(error);
    yield put(subjectDisciplineAction.getSubjectAreasFailure(error));
  }
}

export default function* subjectDisciplineSaga(): unknown {
  yield takeLatest(
    subjectDiscipline.GET_SUBJECT_DISCIPLINE_REQUEST,
    getSubjectDiscipline
  );
  yield takeLatest(subjectDiscipline.GET_SUBJECT_AREAS_REQUEST, getSubjectArea);
}
