import { IAction } from '@shared-components/models';
import * as actionTypes from './agent-partner.constant';

const initialAgentPartnerState = {
  loading: false,
  error: null,
  allData: [],
  singleData: {},
  subAgentData: [],
  superAgentData: [],
};

export const AGENT_PARTNER_FEATURE_KEY = 'agent_partner';

export const agentPartnerReducer = (
  state = initialAgentPartnerState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.GET_SUB_AGENT_BRANCHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SUB_AGENT_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        subAgentData: action.payload,
        error: null,
      };
    case actionTypes.GET_SUB_AGENT_BRANCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_SUPER_AGENT_BRANCHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SUPER_AGENT_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        superAgentData: action.payload,
        error: null,
      };
    case actionTypes.GET_SUPER_AGENT_BRANCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
