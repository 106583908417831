import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CRM_COOKIE_ACCESS } from '@moxie/constants';
import { Spin } from 'antd';
import { CrmDashboardLayout } from '@crm/dashboard';
import { useDispatch } from 'react-redux';
import { authActions } from '@crm/core';

const CrmProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  const [access] = useState(Cookies.get(`${CRM_COOKIE_ACCESS}`));
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authActions.getAuthUser())
  },[]);
  return (
    <Route
      {...rest}
      render={(props) =>
        !access ? (
          <Redirect to="/" />
        ) : (
          <CrmDashboardLayout>
            <React.Suspense
              fallback={
                <div className="page-load">
                  <Spin />
                </div>
              }
            >
              <Component {...props} />
            </React.Suspense>
          </CrmDashboardLayout>
        )
      }
    />
  );
};

export { CrmProtectedRoute };
