import { fetchProductTypesWithSubTypes } from "@crm/libs/services.api/lib/product-type.api";
import { useQuery } from "@tanstack/react-query";

const useProductTypes = () => {
    const {data, isLoading } = useQuery({
        queryKey: ['productTypes'],
        queryFn:() => fetchProductTypesWithSubTypes()
    });

    return {
        productTypes: data ?? [],
        isLoading
    }
}

export default useProductTypes;
