import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
} from '@moxie/constants';
import { popConfirm } from '@shared-components/elements';
import { Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';

export const ProductMenu = ({
  deleteProduct,
  setIsOpen,
  setIsEdit,
  setProductId,
}: any) => {
  const canAccess = useCanAccess();
  const handleEdit = () => {
    setIsEdit(true);
    setIsOpen(true);
    setProductId();
  };

  const onDelete = () => {
    deleteProduct();
    setProductId();
  };

  const handleDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_PRODUCT,
      `${CONFIRM_MESSAGE.DELETE_PRODUCT}?`,
      onDelete,
      true
    );
  };

  const menuItems = [
    canAccess('product','update') && (<Menu.Item
      key={1}
      icon={<EditOutlined />}
      data-testid="editproduct"
      onClick={handleEdit}
    >
      <Space direction="horizontal">{EDIT_BTN}</Space>
    </Menu.Item>),
    canAccess('product','delete') && (
      <Menu.Item
        key={2}
        danger
        onClick={handleDelete}
        data-testid="deleteproduct"
      >
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    )

  ].filter(Boolean) as JSX.Element[];

  const menu = (
    <Menu>
      {menuItems.map((comp, index) => <React.Fragment key={index}>
        {comp}
      </React.Fragment>)}
    </Menu>
  );

  if(!menuItems.length) {
    return null;
  }
  return (
    <div>
      <Dropdown overlay={menu}>
        <Button
          type="text"
          className="table-cell-action-btn"
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </div>
  );
};
