import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssigneeCard, errorNotificationHandler } from '@moxie/shared';
import { RootState } from 'apps/crm/src/core/store';
import { leadActions, userActions } from '@crm/core';
import { TEXT } from '@moxie/constants';
import { DataAsKeyInResponse, IContact } from '@model/module.props';

const LeadTableAssigneeCard = ({ data, triggerRefresh }: any) => {
  const dispatch = useDispatch();
  const [assignee, setAssignee] = useState(true);
  const [hasRole] = useState<boolean>(true);
  const users = useSelector((store: RootState) => store.users.allData);
  
  useEffect(() => {
    dispatch(userActions.fetchUsers());
  }, []);

  const handleSubmit = (values: string) => {
    const previous_assigned_user = data.crm_assignee_id;
    if (values) {
      const assignedData = {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        country_code: data.country_code,
        email: data.email,
        assignee_user_id: values,
        avatar: data.avatar,
      };
      data.id &&
        dispatch(
          leadActions.editLeadAction(
            assignedData,
            (res: DataAsKeyInResponse<IContact>) => {
              if (triggerRefresh) {
                res.data.crm_assignee_id = values
                triggerRefresh(previous_assigned_user, res)
              }
            }
          )
        );
      setAssignee(true);
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  return (
    <AssigneeCard
      data={data}
      handleSubmit={handleSubmit}
      assignee={assignee}
      setAssignee={setAssignee}
      hasRole={hasRole}
      users={users}
    />
  );
};

export { LeadTableAssigneeCard };
