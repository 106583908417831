import { useAppSelector } from '@crm/core';
import { DataAsKeyInResponse, IContact } from '@model/module.props';
import { useState } from 'react';

export const useAssigneeChanged = () => {
    const socket = useAppSelector(state => state.socket.wss);
    const user = useAppSelector(state => state.auth.user);

    const [refresh, setRefresh] = useState(false);

   const triggerRefresh = (previous_assigned_user_id?: string,response?: DataAsKeyInResponse<IContact>) => {
    setRefresh((prev) => !prev);
    if(response && previous_assigned_user_id !== response.data.crm_assignee_id) {
      socket?.emit('server::profile-updated',{
        activities_type: "contact",
        activities_action: 'assigned',
        crm_user_id: user?.id,
        previous_assigned_user_id: previous_assigned_user_id !=='' ? previous_assigned_user_id : null,
        assigned_user_id:response.data.crm_assignee_id !== '' ? response.data.crm_assignee_id : null ,
        contact_id: response.data.id,
        company_id: user?.company_id,
        data: response,
      })

    }
  };

  return { 
    refresh, 
    triggerRefresh
  }
}
