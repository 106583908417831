import { API_URL } from '@moxie/constants';
import {
  ContactBranch,
  IAddEnglishTestScore,
  IContact,
  IContactImport,
  IEducation,
  IOfficeBranch,
  IProfessionalExperience,
  IResponse,
  IUpdateEnglishTestScore,
  IUser,
  User,
} from '@shared-components/models';
import axios from './axios';
import { CancelToken } from 'axios';

const getcontactList = (params = '', cancelToken?: CancelToken): Promise<IResponse> => {
  if (params.charAt(0) === '?') {
    return axios.get(`${API_URL.CRM_CONTACT}${params}`, {cancelToken});
  } else {
    return axios.get(`${API_URL.CRM_CONTACT}?${params}`, {cancelToken});
  }
};

const getAllCRMContacts = (
  search: string,
  queryParams?: any
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_CONTACT}/all`, {
    params: { s: search, ...queryParams },
  });
};

const getMyContacts = (params = '', cancelToken?: CancelToken): Promise<any> => {
  return axios.get(`${API_URL.CRM_CONTACT}/my-contacts?${params}`, {cancelToken});
};

const getArchivedContact = (params = '', cancelToken?: CancelToken): Promise<any> => {
  return axios.get(`${API_URL.CRM_CONTACT}/archived?${params}`, {cancelToken});};

const checkLeadEmail = (email: string): Promise<any> => {
  return axios.get(`${API_URL.URL_CRM_ADD_LEAD}/check-email/${email}`);
};

const getContactDetails = (id: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_CONTACT}/${id}`);
};

const updateCrmContactAssignee = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_CONTACT}/${id}/assignee`, data);
};
const updateContactPersonalInfoData = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_CONTACT}/${id}/personal-info`, data);
};

const updateCrmContactFollowers = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_CONTACT}/${id}/followers`, data);
};

const updateContactAddressInfoData = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_CONTACT}/${id}/address-info`, data);
};

const updatePassportVisaInfoData = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_CONTACT}/${id}/passport-visa-info`, data);
};

const updateEmergencyContactDetails = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return axios.put(
    `${API_URL.CRM_CONTACT}/${id}/emergency-contact-details`,
    data
  );
};

const addContactEducationBackground = (body: any): Promise<any> => {
  return axios.post(`${API_URL.ADD_CONTACT_EDUCATION_BACKGROUNDS}`, body);
};

const getContactEducationBackground = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CONTACT_EDUCATION_BACKGROUNDS}/${id}`);
};

const updateContactEducationBackground = (
  body: IEducation
): Promise<IResponse> => {
  return axios.put(`${API_URL.CONTACT_EDUCATION_BACKGROUNDS}/${body.id}`, body);
};

const deleteContactEducationBackground = async (
  body: any
): Promise<IResponse> => {
  const { contact_id, education_background_id } = body;
  return axios.delete(
    `${API_URL.URL_CRM_EDUCATION_BACKGROUNDS}/${contact_id}/${education_background_id}`
  );
};

const getContactEducationLevels = (): Promise<IResponse> => {
  return axios.get(`${API_URL.URL_CRM_EDUCATION_BACKGROUNDS}/levels`);
};

const addContactProfessionalExperience = async (
  body: IProfessionalExperience
): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_CONTACT_PROFESSIONAL_EXPERIENCE}/add`, body);
};

const getAllContactProfessionalExperience = async (
  contact_id: string
): Promise<IResponse> => {
  return axios.get(
    `${API_URL.CRM_CONTACT_PROFESSIONAL_EXPERIENCE}/${contact_id}/all`
  );
};

const updateContactProfessionalExperience = async (
  body: any
): Promise<IResponse> => {
  const { id } = body;
  delete body.id;
  return axios.put(
    `${API_URL.CRM_CONTACT_PROFESSIONAL_EXPERIENCE}/${id}`,
    body
  );
};

const deleteContactProfessionalExperience = async (
  body: any
): Promise<IResponse> => {
  const { contact_id, professional_experience_id } = body;
  return axios.delete(
    `${API_URL.CRM_CONTACT_PROFESSIONAL_EXPERIENCE}/${contact_id}/${professional_experience_id}`
  );
};

const addEnglishTestScore = async (
  body: IAddEnglishTestScore
): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_CONTACT}/add_english_test_score`, body);
};

const updateEnglishTestScore = async (
  body: IUpdateEnglishTestScore
): Promise<IResponse> => {
  return axios.put(
    `${API_URL.CRM_CONTACT}/english-test-score/${body.id}`,
    body
  );
};

const getEnglishTestScore = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_CONTACT}/${id}/english-test-score`);
};

const deleteEnglishTestScore = async (id: string) => {
  return axios.delete(`${API_URL.CRM_CONTACT}/english-test-score/${id}`);
};

const getContactBranches = async (
  id: string
): Promise<IResponse<ContactBranch[]>> => {
  return (await axios.get(`${API_URL.CRM_CONTACT}/${id}/branches`)).data;
};

const fetchContactApplicationAssignees = async (id: string) => {
  return (
    await axios.get<IResponse<User[]>>(
      `${API_URL.CRM_CONTACT}/${id}/application-assignees`
    )
  ).data;
};

const fetchPossibleUsersInContact = async (
  contactId: string
): Promise<IResponse<IUser[]>> => {
  const { data } = await axios.get(`${API_URL.CRM_CONTACT}/users`, {
    params: {
      contactId,
    },
  });
  return data;
};

const updateBranches = async (contact_id: string, branches: string[]) => {
  const { data } = await axios.put<IResponse<IContact>>(
    `${API_URL.CRM_CONTACT}/${contact_id}/branches`,
    { branches }
  );
  return data;
};
const fetchContactActivities = async (
  contact_id: string,
  currentPage = 1
): Promise<IResponse> => {
  const { data } = await axios.get(
    `${API_URL.CRM_CONTACT}/${contact_id}/activities`,
    {
      params: {
        currentPage,
        limit: 10,
      },
    }
  );
  return data;
};

const fetchContactApplicationActivities = async (
  contact_id: string,
  application_id: string,
  currentPage = 1
) => {
  return (
    await axios.get(
      `${API_URL.CRM_CONTACT}/${contact_id}/applications/${application_id}/activities`,
      {
        params: {
          currentPage,
          limit: 10,
        },
      }
    )
  ).data;
};

const canViewContactStatus = async (contactId: string) => {
  return (await axios.get(`${API_URL.CRM_CONTACT}/${contactId}/can-view`)).data;
};

const getContactsReport = async (query: any) => {
  const response = await axios.get(`${API_URL.CRM_CONTACT}/report?${query}`);
  return response;
};

const fetchContactAssignees = () => axios.get<{ users: IUser[] }>(`${API_URL.CRM_CONTACT}/assignees`);

const fetchContactStates = () => axios.get<{ states: string[] }>(`${API_URL.CRM_CONTACT}/states`);

const fetchContactCountries = () => axios.get<{ countries: string[] }>(`${API_URL.CRM_CONTACT}/countries`);

const fetchContactPassportCountries = () => axios.get<{ passportCountries: string[] }>(`${API_URL.CRM_CONTACT}/passport-countries`);

const fetchContactBranches = () => axios.get<{ branches: IOfficeBranch[]}>(`${API_URL.CRM_CONTACT}/branches`)

const verifyEmailExists = async (body: string[]) => {
 return (await axios.post(`${API_URL.CRM_CONTACT}/verify-emails`, body)).data;
}

const importContacts = async (body: IContactImport[]) => {
  return axios.post(`${API_URL.CRM_CONTACT}/import`, body);
}

export {
  getcontactList,
  getAllCRMContacts,
  getMyContacts,
  getArchivedContact,
  checkLeadEmail,
  getContactDetails,
  updateCrmContactAssignee,
  updateCrmContactFollowers,
  updateEmergencyContactDetails,
  updateContactPersonalInfoData,
  updateContactAddressInfoData,
  updatePassportVisaInfoData,
  addContactEducationBackground,
  getContactEducationBackground,
  updateContactEducationBackground,
  deleteContactEducationBackground,
  getContactEducationLevels,
  addContactProfessionalExperience,
  getAllContactProfessionalExperience,
  updateContactProfessionalExperience,
  deleteContactProfessionalExperience,
  addEnglishTestScore,
  getEnglishTestScore,
  updateEnglishTestScore,
  deleteEnglishTestScore,
  getContactBranches,
  fetchContactActivities,
  fetchContactApplicationActivities,
  updateBranches,
  fetchPossibleUsersInContact,
  fetchContactApplicationAssignees,
  canViewContactStatus,
  getContactsReport,
  fetchContactAssignees,
  fetchContactPassportCountries,
  fetchContactCountries,
  fetchContactStates,
  fetchContactBranches,
  verifyEmailExists,
  importContacts
};
