import { officeVisitActions, useAppSelector } from '@crm/core';
import {
  TableUserNameCard,
  UnassignedCard,
  UserSelectBox,
} from '@shared-components/elements';
import { IOfficeVisit } from '@shared-components/models';
import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CaretRightOutlined,
  CheckCircleOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ASSIGN_TO_BTN, SESSION_NOTE_REQUIRED, URL_DETAIL_LINK } from '@moxie/constants';
import { UserCard } from './user-card';
import { Link } from 'react-router-dom';
import { UserNameCard } from '../../contact-add-edit/user-name-card';
import { removeDefTags } from './removeTagFn';
import { errorNotificationHandler } from '@moxie/shared';
import { useFetchPrimaryBranchUsers } from '../hook/useFetchPrimaryBranchUsers';

export const Countdown: React.FC<any> = ({ data }: { data: any }) => {
  const initialDifference = moment.duration(moment().diff(moment(data)));
  const [difference, setDifference] = useState(initialDifference);

  const count = () =>
    setInterval(() => {
      setDifference(moment.duration(moment().diff(moment(data))));
    }, 1000);

  useState(() => {
    count();

    return () => {
      clearInterval(count());
    };
  });

  return (
    <>
      <Typography.Text>
        {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
        {difference.get('m')}m
      </Typography.Text>
    </>
  );
};

export const OfficeVisitAction: React.FC<any> = ({ data }: { data: any }) => {
  const { user, currentUserBranchId } = useAppSelector((state) => ({
    user: state.auth.user,
    currentUserBranchId: state.auth.user?.branch_id,
  }));
  const dispatch = useDispatch();

  const socket = useAppSelector((state) => state.socket.wss);
  const branches = useAppSelector((state) => state.office_branches.allData);

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const handleView = () => {
    dispatch(officeVisitActions.getOfficeVisitRequest(data?.id));
    dispatch(officeVisitActions.openOfficeVisitDetailsDrawer());
  };

  const handleAttendSession = () => {
    const updateData: IOfficeVisit = {
      status: 'Attending',
      session_start: moment(),
      assignee_id: user?.id,
    };
    dispatch(
      officeVisitActions.updateOfficeVisitRequest(data.id, updateData, () => {
        if (user) {
          socket?.emit('server::office-visit-updated', {
            assignee: data.assignee.id as string,
            branch: branches.find((branch) => branch.id === data.branch_id)
              ?.name as string,
            contact_id: data.contact.id,
            crm_user_id: user?.id as string,
            type: 'attended',
          });
        }
        triggerRefresh();
      })
    );
  };

  const handleEndSession = async (data: any) => {
    const sessionNote = await removeDefTags(data?.session_notes);
    if (sessionNote.length === 0) {
      handleView();
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
    } else {
      const updateData: IOfficeVisit = {
        status: 'Completed',
        session_end: moment(),
      };
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          data.id,
          updateData,
          triggerRefresh
        )
      );
    }
  };

  const checkCanAttendEndSession = (): boolean => {
    if (currentUserBranchId === data?.branch_id) {
      return !(user?.id === data?.assignee?.id);
    }
    return true;
  };

  const checkAssigneeLogged = (): boolean => {
    return !(user?.id === data?.assignee?.id);
  };

  return (
    <div className="display-flex">
      {data?.status === 'Waiting' && (
        <Link to={`/office-visit/attending`}>
          <Button
            disabled={checkCanAttendEndSession()}
            onClick={handleAttendSession}
            className="green-btn margin-right-1"
            data-testid="crm-officevisitattendbutton"
          >
            <CaretRightOutlined />
            Attend
          </Button>
        </Link>
      )}
      {data?.status === 'Attending' && (
        <Link to={removeDefTags(data?.session_notes).length > 0 ? `/office-visit/completed` : `/office-visit/attending`}>
          <Button
            disabled={checkAssigneeLogged()}
            onClick={() => handleEndSession(data)}
            className="emerald-green-btn margin-right-1"
            data-testid="crm-officevisitendbutton"
          >
            <CheckCircleOutlined />
            End Session
          </Button>
        </Link>
      )}
      <Button onClick={handleView} data-testid="crm-officevisitviewdetails">
        <ProfileOutlined />
        View Details
      </Button>
    </div>
  );
};

const OfficeVisitAssigneeCard: React.FC<any> = ({ data }: any) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { users: userList } = useFetchPrimaryBranchUsers();
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const branches = useAppSelector((state) => state.office_branches.allData);

  const editAssigneeClick = () => {
    setIsEdit(true);
  };

  const handleEditCancel = () => {
    setIsEdit(false);
  };

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const updateAssignee = (assigneeId: any) => {
    const removedAssignee = data?.assignee?.id ?? null;
    if (assigneeId === 'null' || !assigneeId) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          data?.id,
          { assignee_id: null },
          () => {
            triggerRefresh();
            socket?.emit('server::office-visit-updated', {
              assignee: assigneeId,
              removed_assignee: removedAssignee,
              branch: branches.find((branch) => branch.id === data.branch_id)
                ?.name as string,
              contact_id: data.contact.id,
              crm_user_id: user?.id as string,
              type: 'changed',
            });
          }
        )
      );
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          data?.id,
          { assignee_id: assigneeId },
          () => {
            triggerRefresh();
            socket?.emit('server::office-visit-updated', {
              assignee: assigneeId,
              removed_assignee: removedAssignee,
              branch: branches.find((branch) => branch.id === data.branch_id)
                ?.name as string,
              contact_id: data.contact.id,
              crm_user_id: user?.id as string,
              type: 'changed',
            });
          }
        )
      );
    }
  };

  return (
    <>
      {data?.assignee?.id && !isEdit ? (
        <div className="cursor-pointer" onClick={editAssigneeClick}>
          <TableUserNameCard
            index={data?.assignee?.id}
            firstName={data?.assignee?.first_name}
            lastName={data?.assignee?.last_name}
            email={data?.assignee?.email}
            id={data?.assignee?.id}
            actionBar={false}
          />
        </div>
      ) : isEdit ? (
        <Row
          className="lead-table__name user-name-card"
          style={{ width: '8rem' }}
        >
          <Col className="align-items-center user-name-card__avatar">
            <Tooltip title="unassigned">
              <Avatar icon={<UserOutlined />} alt="unassigned" />
            </Tooltip>
          </Col>
          <Col>
            <Form layout="inline">
              <Space direction="horizontal">
                <Form.Item
                  name="assignee_user_id"
                  className="inline_input_width"
                >
                  <UserSelectBox
                    defaultOpen={true}
                    placeholder={ASSIGN_TO_BTN}
                    showArrow={false}
                    bordered={false}
                    onChange={updateAssignee}
                    onBlur={handleEditCancel}
                    autoFocus={true}
                    selected_id={data?.assignee?.id ? data?.assignee.id : ''}
                    showUnassigned={false}
                    users={userList}
                  />
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      ) : (
        <div className="cursor-pointer" onClick={editAssigneeClick}>
          <UnassignedCard />
        </div>
      )}
    </>
  );
};

const waitingColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Arrival Time',
    dataIndex: 'created_at',
    render: (created_at: any): ReactElement => {
      return (
        <Typography.Text>{moment(created_at).format('h:mm A')}</Typography.Text>
      );
    },
  },
  {
    title: 'Wait Time',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.first_name}
          lastName={contact?.last_name}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    render: (data: any): ReactElement => {
      return <OfficeVisitAssigneeCard data={data} />
    }
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visit_purpose',
    render: (data: any): ReactElement => {
      return <Typography.Text>{data?.visit_purpose}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => <OfficeVisitAction data={row} />,
  },
];

const attendingColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Session Start Time',
    dataIndex: 'session_start',
    render: (session_start: any): ReactElement => (
      <Typography.Text>
        {moment(session_start).format('h:mm A')}
      </Typography.Text>
    ),
  },
  {
    title: 'Session Run Time',
    dataIndex: 'session_start',
    render: (data: any): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.first_name}
          lastName={contact?.last_name}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    render: (data: any): ReactElement => (
      <UserCard
        firstName={data?.assignee?.first_name}
        lastName={data?.assignee?.last_name}
        email={data?.assignee?.email}
        showEmailAsLink={true}
      />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visit_purpose',
    render: (data: any): ReactElement => {
      return <Typography.Text>{data?.visit_purpose}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => {
      return <OfficeVisitAction data={row} />
    },
  },
];

const completedColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Session Start Time',
    dataIndex: 'session_start',
    render: (session_start: any): ReactElement => {
      return (
        <Typography.Text>
          {moment(session_start).format('h:mm A')}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Session End Time',
    dataIndex: 'session_end',
    render: (session_end: any): ReactElement => {
      return (
        <Typography.Text>
          {moment(session_end).format('h:mm:ss A')}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Duration',
    render: (data: any): ReactElement => {
      const { session_start, session_end } = data;
      const difference = moment.duration(
        moment(session_end).diff(moment(session_start))
      );
      return (
        <Typography.Text>
          {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
          {difference.get('m')}m
        </Typography.Text>
      );
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.first_name}
          lastName={contact?.last_name}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    render: (data: any): ReactElement => (
      <UserCard
        firstName={data?.assignee?.first_name}
        lastName={data?.assignee?.last_name}
        email={data?.assignee?.email}
        showEmailAsLink={true}
      />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visit_purpose',
    render: (data: any): ReactElement => {
      return <Typography.Text>{data?.visit_purpose}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => <OfficeVisitAction data={row} />,
  },
];

const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Arrival Time',
    dataIndex: 'session_start',
    render: (session_start: any): ReactElement => {
      return session_start ? (
        <Typography.Text>
          {moment(session_start).format('h:mm A')}
        </Typography.Text>
      ) : (
        <Typography.Text>Not attended </Typography.Text>
      );
    },
  },
  {
    title: 'Wait Time',
    dataIndex: 'created_at',
    render: (data: any): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.first_name}
          lastName={contact?.last_name}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    render: (data: any): ReactElement => (
      <UserCard
        firstName={data?.assignee?.first_name}
        lastName={data?.assignee?.last_name}
        email={data?.assignee?.email}
        showEmailAsLink={true}
      />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visit_purpose',
    render: (data: any): ReactElement => {
      return <Typography.Text>{data?.visit_purpose}</Typography.Text>;
    },
  },
];

export { waitingColumns, attendingColumns, completedColumns, columns };
