import React, { useEffect } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { IWorkflowFormComponent } from '@shared-components/models';
import { FormItem } from '@shared-components/elements';
import {
  GUTTER,
  LABEL,
  PLACEHOLDER,
  WORKFLOW_TYPE_INFORMATION,
} from '@moxie/constants';

const WorkflowTypeForm: React.FC<IWorkflowFormComponent> = ({
  form,
  onSubmit,
  initialData,
  serviceData,
  handleFieldsChange,
  setDisabled,
}: IWorkflowFormComponent) => {
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  const handleNameChange = (data: any) => {
    initialData.name === data ? setDisabled(true) : setDisabled(false);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={handleFieldsChange}
        onFinish={onSubmit}
      >
        <div className="form_section_header">{WORKFLOW_TYPE_INFORMATION}</div>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <FormItem
              name="service_id"
              initialValue={serviceData.id}
              label={LABEL.SERVICE}
            >
              <Select
                defaultValue={serviceData.id}
                disabled
                options={[
                  {
                    value: serviceData.id,
                    label: serviceData.name,
                  },
                ]}
              />
            </FormItem>
          </Col>
          <Col span="12">
            <Form.Item
              name="name"
              label={LABEL.WORKFLOW_TYPE_NAME}
              rules={[{ required: true }]}
            >
              <Input
                name="name"
                placeholder={PLACEHOLDER.WORKFLOW_TYPE}
                onChange={handleNameChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { WorkflowTypeForm };
