import { IAction } from '@shared-components/models';
import * as agentPartnerConst from './agent-partner.constant';

export const getSubAgentBranchesRequest = (id: string): IAction<any> => ({
  type: agentPartnerConst.GET_SUB_AGENT_BRANCHES_REQUEST,
  id,
});

export const getSubAgentBranchesSuccess = (payload: any): IAction<any> => ({
  type: agentPartnerConst.GET_SUB_AGENT_BRANCHES_SUCCESS,
  payload,
});

export const getSubAgentBranchesFailure = (payload: any): IAction<any> => ({
  type: agentPartnerConst.GET_SUB_AGENT_BRANCHES_FAILURE,
  payload,
});

export const getSuperAgentBranchesRequest = (id: string): IAction<any> => ({
  type: agentPartnerConst.GET_SUPER_AGENT_BRANCHES_REQUEST,
  id,
});
export const getSuperAgentBranchesSuccess = (payload: any): IAction<any> => ({
  type: agentPartnerConst.GET_SUPER_AGENT_BRANCHES_SUCCESS,
  payload,
});

export const getSuperAgentBranchesFailure = (payload: any): IAction<any> => ({
  type: agentPartnerConst.GET_SUPER_AGENT_BRANCHES_FAILURE,
  payload,
});
