import { API_URL } from '@moxie/constants';
import { IResponse, ProductType } from '@shared-components/models';
import axios from './axios';

// const getProductTypeByServiceId = async (
//   id: string,
//   params = ''
// ): Promise<IResponse> => {
//   return axios.get(`${API_URL.CRM_PRODUCT_TYPE_BY_SERVICE_ID}/${id}/${params}`);
// };

const getProductTypeByServiceId = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_PRODUCT_TYPE_BY_SERVICE_ID}`);
};

const getProductSubTypes = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_PRODUCT_SUB_TYPES}/${id}`);
};

const fetchProductTypesWithSubTypes = async () => {
  const { data } =await axios.get<IResponse<ProductType[]>>(`${API_URL.CRM_PRODUCT_TYPE_WITH_SUB_TYPE}`)
  return data.data
}

export { getProductTypeByServiceId, getProductSubTypes, fetchProductTypesWithSubTypes };
