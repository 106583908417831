import { useQuery } from "@tanstack/react-query";
import { fetchPrimaryBranchUsers } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";

export const useFetchPrimaryBranchUsers = () => {
    const {data, isLoading} = useQuery({
        queryKey: ['primary-branch-users'],
        queryFn: async () => await fetchPrimaryBranchUsers(),
        enabled: true,
        onError: () => {
            errorNotificationHandler('Error fetching users');
        }
    })
    return {
        users: data?.data?.data ?? [],
        usersLoading: isLoading
    }
}