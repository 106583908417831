import {
  addContactEducationBackground,
  addContactProfessionalExperience,
  deleteContactEducationBackground,
  deleteContactProfessionalExperience,
  getAllContactProfessionalExperience,
  getContactDetails,
  getContactEducationBackground,
  updateContactAddressInfoData,
  updateContactEducationBackground,
  updateContactPersonalInfoData,
  updateContactProfessionalExperience,
  updateCrmContactAssignee,
  updateCrmContactFollowers,
  updateEmergencyContactDetails,
  updatePassportVisaInfoData,
} from '@crm/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as contactAction from './contact.action';
import * as contact from './contact.constant';

// type UpdatePersonalInformation = Required<Pick<IAction<any>,'socket' | 'payload' | 'user'>>

export function* getContactDetailsInfo(payload: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getContactDetails(payload?.payload);
      yield put(contactAction.getContactDetailsSuccess(response?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(contactAction.getContactDetailsFailure(err));
  }
}

export function* updateContactAssigneeData({
  payload,
  triggerRefresh,
}: IAction) {
  try {
    if (payload) {
      const { id } = payload;
      const response: AxiosResponse = yield updateCrmContactAssignee(payload);

      yield put(
        contactAction.updateContactAssigneeSuccess(response?.data?.data)
      );
      yield put(contactAction.getContactDetailsRequest(id));
      if (triggerRefresh) {
        triggerRefresh(response.data);
      }
      yield successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(contactAction.updateContactAssigneeFailure(err));
  }
}

export function* updateContactFollowersData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const response: AxiosResponse = yield updateCrmContactFollowers(payload);
      yield put(
        contactAction.updateContactFollowersSuccess(response?.data?.data)
      );
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(response?.data?.message);

      if (triggerRefresh) {
        triggerRefresh(response.data);
      }
    }
  } catch (err) {
    errorHandler(err);
    yield put(contactAction.updateContactFollowersFailure(err));
  }
}

export function* updatePersonalInfoData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const { data }: AxiosResponse = yield updateContactPersonalInfoData(
        payload
      );

      yield put(contactAction.updateContactPersonalInfoSuccess(data?.data));
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(data?.message);
      triggerRefresh(data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactPersonalInfoFailure(error));
  }
}

export function* updateAddressInfoData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const { data }: AxiosResponse = yield updateContactAddressInfoData(
        payload
      );
      yield put(contactAction.updateContactAddressInfoSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(data?.message);
      triggerRefresh(data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactAddressInfoFailure(error));
  }
}

export function* updatePassportVisaInfo({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const { data }: AxiosResponse = yield updatePassportVisaInfoData(payload);
      yield put(contactAction.updateContactPassportVisaInfoSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(data?.message);
      triggerRefresh(data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactPassportVisaInfoFailure(error));
  }
}

export function* updateContactEmergencyDetailsInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const { data }: AxiosResponse = yield updateEmergencyContactDetails(
        payload
      );
      yield put(contactAction.updateContactEmergencyDetailsSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(data?.message);
      triggerRefresh(data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactEmergencyDetailsFailure(error));
  }
}

export function* getContactEducationBackgroundInfo({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getContactEducationBackground(
        payload
      );
      yield put(
        contactAction.getContactEducationBackgroundSuccess(response?.data?.data)
      );
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.getContactEducationBackgroundFailure(error));
  }
}

export function* addContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addContactEducationBackground(
        payload
      );
      yield put(
        contactAction.addContactEducationBackgroundSuccess(response?.data?.data)
      );
      yield successNotificationHandler(response?.data?.message);
      yield getContactEducationBackgroundInfo({
        payload: payload?.parent_id,
      });
      triggerRefresh(response.data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.addContactEducationBackgroundFailure(error));
  }
}

export function* updateContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateContactEducationBackground(
        payload
      );
      yield put(contactAction.updateContactEducationBackgroundSuccess());
      yield successNotificationHandler(response?.data?.message);
      yield getContactEducationBackgroundInfo({ payload: payload?.parent_id });
      triggerRefresh(response.data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactEducationBackgroundFailure(error));
  }
}

export function* deleteContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteContactEducationBackground(
        payload
      );
      yield put(contactAction.deleteContactEducationBackgroundSuccess());
      yield successNotificationHandler(response?.data?.message);
      yield getContactEducationBackgroundInfo({
        payload: payload?.contact_id,
      });
      triggerRefresh(response.data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.deleteContactEducationBackgroundFailure(error));
  }
}

export function* addContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addContactProfessionalExperience(
        payload
      );
      yield put(contactAction.addContactProfessionalExperienceSuccess());
      yield getAllContactProfessionalExperienceInfo({
        id: payload?.parent_id,
      });
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh(response.data);
    }
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.addContactProfessionalExperienceFailure(error));
  }
}

export function* getAllContactProfessionalExperienceInfo({ id }: any) {
  try {
    const response: AxiosResponse = yield getAllContactProfessionalExperience(
      id
    );
    yield put(
      contactAction.getContactProfessionalExperienceSuccess(
        response?.data?.data
      )
    );
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.getContactProfessionalExperienceFailure(error));
  }
}

export function* updateContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    const response: AxiosResponse = yield updateContactProfessionalExperience(
      payload
    );
    yield put(contactAction.updateContactProfessionalExperienceSuccess());
    yield getAllContactProfessionalExperienceInfo({
      id: payload?.parent_id,
    });
    yield successNotificationHandler(response?.data?.message);
    triggerRefresh(response.data);
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.updateContactProfessionalExperienceFailure(error));
  }
}

export function* deleteContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    const { contact_id } = payload;
    const response: AxiosResponse = yield deleteContactProfessionalExperience(
      payload
    );
    yield put(contactAction.deleteContactProfessionalExperienceSuccess());
    yield getAllContactProfessionalExperienceInfo({
      id: contact_id,
    });
    yield successNotificationHandler(response?.data?.message);
    triggerRefresh(response.data);
  } catch (error) {
    errorHandler(error);
    yield put(contactAction.deleteContactProfessionalExperienceFailure(error));
  }
}

export default function* contactSaga(): unknown {
  yield takeLatest(contact.GET_CONTACT_DETAILS_REQUEST, getContactDetailsInfo);
  yield takeLatest(
    contact.UPDATE_CONTACT_ASSIGNEE_REQUEST,
    updateContactAssigneeData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_FOLLOWERS_REQUEST,
    updateContactFollowersData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PERSONAL_INFO_REQUEST,
    updatePersonalInfoData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_ADDRESS_INFO_REQUEST,
    updateAddressInfoData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST,
    updatePassportVisaInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST,
    updateContactEmergencyDetailsInfo
  );
  yield takeLatest(
    contact.GET_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    getContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    addContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    updateContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    deleteContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    addContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    getAllContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    updateContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    deleteContactProfessionalExperienceInfo
  );
}
