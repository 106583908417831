import React from 'react';
import { Spin } from 'antd';
import { ISearchableSelect, IUser } from '@shared-components/models';
import { Option, SearchableSelect } from '@moxie/shared';
import { UNASSIGNED_LABEL } from '@moxie/constants';
import { useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import { UnassignedCard, UserNameCard } from './user-name-card';

const UserSelectBox: React.FC<ISearchableSelect> = ({
  showUnassigned,
  ...props
}: ISearchableSelect) => {
  const crm_users = useAppSelector((store: RootState) => store.users.allData);
  const userList = props.users ? props.users : crm_users;

  return (
    <SearchableSelect
      {...props}
      optionLabelProp="label"
      optionFilter="search_prop"
    >
      {!userList ? (
        <Option value="">
          <Spin spinning />
        </Option>
      ) : (
        <>
          {!showUnassigned && (
            <Option value="" label={UNASSIGNED_LABEL}>
              <UnassignedCard />
            </Option>
          )}
          {userList?.map((user: IUser, index: number) => {
            return (
              <Option
                disabled={user.disabled || user.id === props.selected_id}
                value={user.id || ''}
                key={user.id || index}
                label={`${user.first_name} ${user.last_name}`}
                search_prop={`${user.first_name} ${user.last_name} ${user.email}`}
                className="padding-left-1"
              >
                <UserNameCard
                  firstName={user.first_name || ''}
                  lastName={user.last_name}
                  email={user.email || ''}
                  index={user.id || ''}
                />
              </Option>
            );
          })}
        </>
      )}
    </SearchableSelect>
  );
};

export { UserSelectBox };
