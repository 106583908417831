import { IAction, IUser } from '@shared-components/models';
import * as actionTypes from './contact.constant';

// TODO:: need to add more types
interface ContactState {
  singleData: any,
  allData: any[],
  loading: boolean,
  error: null,
  assigneeData: IUser,
  followersData: IUser[],
  followers_id: string[],
  contactDetail: any,
  educationBackground: any | any[],
  eduBackgroundLoading: boolean,
  profExpLoading: boolean,
  profExpData: any[],
  activities: any[]
}
const initialContactState:ContactState = {
  singleData: {},
  allData: [],
  loading: false,
  error: null,
  assigneeData: {},
  followersData: [],
  followers_id: [],
  contactDetail: {},
  educationBackground: [],
  eduBackgroundLoading: false,
  profExpLoading: false,
  profExpData: [],
  activities: [],
};

export const CONTACT_FEATURE_KEY = 'contact';

export const contactReducer = (
  state = initialContactState,
  action: IAction<any>
): ContactState => {
  switch (action.type) {
    case actionTypes.GET_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
        assigneeData: action.payload.crm_assignee,
        followersData: action.payload.crm_followers,
        followers_id: action.payload.followers_id,
      };
    case actionTypes.GET_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assigneeData: action.payload.crm_assignee,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contactDetail: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        followersData: action.payload.crm_followers,
        followers_id: action.payload.followers_id,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: {},
        allData: [],
        assigneeData: {},
        followersData: [],
        followers_id: [],
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
        educationBackground: action.payload,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_EDUCATION_BACKGROUND_FETCH:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
        educationBackground: {},
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpData: action.payload,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_PROFESSIONAL_EXPERIENCE_FETCH:
      return {
        ...state,
        profExpLoading: false,
        profExpData: [],
        error: null,
      };

    default:
      return state;
  }
};
