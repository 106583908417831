import { API_URL } from '@moxie/constants';
import {
  IAuthResponse,
  IChangePassword,
  IForgetPassword,
  IAuth,
  IForgetPasswordResponse,
  ILogin,
  ILoginResponse,
  IVerifyAccount,
} from '@shared-components/models';
import axios from './lib/axios';

const login = (body: ILogin): Promise<ILoginResponse> => {
  return axios.post(`${process.env.NX_BASE_URL}${API_URL.LOGIN}`, body);
};

const auth = (body: IAuth): Promise<IAuthResponse> => {
  return axios.post(API_URL.AUTH, body);
};

const logoutUser = () => {
  // TODO: Perform logout action here
  return;
};
const forgetCrmPassword = (
  body: IForgetPassword
): Promise<IForgetPasswordResponse> => {
  return axios.post(API_URL.CRM_FORGET_PASSWORD, body);
};

const changeCRMAccountPasswordAPI = (body: IChangePassword): Promise<any> => {
  return axios.post(API_URL.CHANGE_CRM_ACCOUNT_PASSWORD, body);
};

// auth/resetpassword
const CRMResetPassword = (data: any) => {
  return axios.post(API_URL.AUTH, data);
};

const verifyCrmAccount = (body: IVerifyAccount): Promise<IAuthResponse> => {
  return axios.post(API_URL.EMAIL_VERIFICATION, body);
};

const resendVerifyCrmAccountLink = (
  body: IForgetPassword
): Promise<IAuthResponse> => {
  return axios.post(API_URL.RESEND_EMAIL_VERIFICATION, body);
};

export {
  login,
  logoutUser,
  changeCRMAccountPasswordAPI,
  forgetCrmPassword,
  auth,
  verifyCrmAccount,
  resendVerifyCrmAccountLink,
  CRMResetPassword,
};
