/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, EducationCard, popConfirm } from '@shared-components/elements';
import { EducationBackgroundAddEdit } from '../../education-background-add-edit';
import { Col, Collapse, Divider, Dropdown, Row, Typography } from 'antd';
import {
  ADD_BTN,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  GUTTER,
  LABEL,
  OTHER_TEST_KEY_VALUE,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { RootState } from 'apps/lyra/src/core/store';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  IEducation,
  ILeadLanguageType,
  IOtherTestScore,
  ITestType,
} from '@shared-components/models';
import {
  educationBackgroundActions,
  otherTestScoreActions,
  useAppSelector,
} from '@lyra/core';
import {
  LanguageTestScoreAddEdit,
  OtherTestScoreAddEdit,
} from '@shared-components/modules';
import { languageTestScoreMenu } from './language-test-score-menu-item';
import { languageTestScoreActions } from '@lyra/core';
import { capitalizeFirstLetter } from '@moxie/shared';
import { otherTestScoreMenu } from './other-test-score-menu-item';
import Can, { LeadsEntity, PermissionAction } from '@lyra/privilege';

interface IEducationBackgroundProps {
  id: string | any;
  lead?: any;
}
const { Text } = Typography;

const EducationBackground: React.FC<IEducationBackgroundProps> = ({
  id,
  lead,
}: IEducationBackgroundProps) => {
  const [educationDrawerIsOpen, setEducationDrawer] = useState(false);
  const [languageTestScoreIsOpen, setLanguageTestScore] = useState(false);
  const [otherTestDrawerIsOpen, setOtherTestDrawer] = useState(false);
  const [
    selectedLanguageTestScore,
    setLanguageTestScoreMode,
  ] = useState<string>();
  const [selectedOtherTestScore, setOtherTestScoreMode] = useState<string>();
  const [
    languageTestScoreData,
    setLanguageTestScoreData,
  ] = useState<ITestType>();
  const [
    otherTestScoreData,
    setOtherTestScoreData,
  ] = useState<IOtherTestScore>();
  const [
    educationBackgroundData,
    setEducationBackground,
  ] = useState<IEducation>();
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const {
    socket,
    user,
    educationBackground,
    languageTestScore,
    otherTestScoreList,
  } = useAppSelector((store: RootState) => ({
    socket: store.socket.wss,
    user: store.auth.user,
    educationBackground: store.education_background.educationBackgrounds,
    languageTestScore: store.language_test_score.languageTestScoreList,
    otherTestScoreLoading: store.other_test_score.loading,
    otherTestScoreList: store.other_test_score.otherTestScoreList,
  }));

  const handleEducationDrawerClose = () => {
    setDisabled(true);
    setEducationDrawer(false);
  };

  const handleOtherTestDrawerClose = () => {
    setOtherTestDrawer(false);
    setDisabled(true);
  };

  const handleOtherTestDrawerOpen = () => {
    setOtherTestDrawer(true);
  };

  const handleEducationDrawerOpen = () => {
    setEducationDrawer(true);
  };

  const handleLanguageDrawerClose = () => {
    setLanguageTestScore(false);
    setLanguageTestScoreMode('');
  };

  const handleLanguageDrawerOpen = () => {
    setLanguageTestScore(true);
  };

  const handleDelete = (data: IEducation) => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_EDUCATION_BACKGROUND} ${data.degree_title}`,
      () => {
        dispatch(
          educationBackgroundActions.deleteEducationBackgroundRequest(data.id)
        );
        const dataLeadActivities = {
          user_id: user?.id,
          type_id: data.id,
          data: { data: data },
          parent_id: data.parent_id,
          company_id: user?.company?.id,
        };
        socket.emit('qualification-notification', {
          user_id: user?.id,
          parent_id: lead.id,
          receiver_id: lead?.assignee_id,
          receivers_many: lead?.followers_id,
          profile_tab: 'education-background',
          type: 'education',
          update_type: 'deleted',
        });
        socket?.emit(
          'deleted-education-background-to-server',
          dataLeadActivities
        );
      }
    );
  };

  const handleEdit = (data: IEducation) => {
    setEducationBackground(data);
    handleEducationDrawerOpen();
    setDisabled(true);
  };

  const handleEducationAdd = (event: any) => {
    setEducationBackground(undefined);
    handleEducationDrawerOpen();
    event?.stopPropagation();
  };

  const handleLanguageTestScoreAdd = (event: any) => {
    event?.stopPropagation();
  };

  const handleOtherTestScoreAdd = (event: any) => {
    event?.stopPropagation();
  };

  const handleOtherTestScoreEdit = (data: any, mode: string) => {
    setOtherTestScoreMode(mode);
    setOtherTestScoreData(otherTestScoreList);
    handleOtherTestDrawerOpen();
  };

  const toFormAcceptableForm = (data: ITestType[]) => {
    const returnObj: any = {};
    data.forEach((item: ITestType) => {
      returnObj[item?.name] = item?.score ?? 0;
      returnObj[`${item?.name.toLowerCase()}_test_id`] = item.id;
    });
    return returnObj;
  };

  const handleLanguageTestScoreEdit = (data: ILeadLanguageType) => {
    const dataToEdit = toFormAcceptableForm(data?.test_types || []);
    dataToEdit.overall_score = data.overall_score;
    dataToEdit.id = data.id;
    dataToEdit.test_score_id = data.test_score_id;
    if (data.test_types) dataToEdit.test_id = data.id;
    setLanguageTestScoreData(dataToEdit);
    setLanguageTestScoreMode(data.name?.toUpperCase());
    handleLanguageDrawerOpen();
    setDisabled(true);
  };

  const handleOtherTestScoreDelete = (key: string) => {
    const deleteFunction = () => {
      const data: IOtherTestScore = { id: otherTestScoreList.id };
      if (key === 'gre') data.gre = '0';
      if (key === 'gmat') data.gmat = '0';
      if (key === 'sat_1') data.sat_1 = '0';
      if (key === 'sat_2') data.sat_2 = '0';

      dispatch(
        otherTestScoreActions.deleteOtherTestScoreRequest(
          {
            ...data,
            parent_id: id,
          },
          lead,
          socket,
          user
        )
      );
    };

    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_OTHER_TEST}`, () =>
      deleteFunction()
    );
  };

  const handleLanguageTestScoreDelete = (data: ILeadLanguageType) => {
    popConfirm(
      DELETE_BTN,
      `${
        CONFIRM_MESSAGE.DELETE_EDUCATION_BACKGROUND
      } ${data.name?.toUpperCase()}?`,
      () =>
        dispatch(
          languageTestScoreActions.deleteLanguageTestScoreRequest(
            data,
            user,
            socket,
            lead
          )
        )
    );
  };

  const handleLanguageTestScoreMenuClick = (string: string) => {
    setLanguageTestScoreMode(string);
    setLanguageTestScoreData(undefined);
    handleLanguageDrawerOpen();
  };

  const handleOtherTestScoreMenuClick = (string: string) => {
    setOtherTestScoreMode(string);
    setOtherTestScoreData(undefined);
    handleOtherTestDrawerOpen();
  };

  return (
    <div className="overflow-scroll">
      <Row justify="end">
        <div className="padding-right-2" />
      </Row>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          extra={
            <>
              {
                // Can(
                //   PermissionAction.Create,
                //   new LeadsEntity({
                //     assignee_id: lead?.assignee_id,
                //     followers: lead?.followers?.map((item: any) => item.id),
                //   })
                // ) &&
                !lead?.archived && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleEducationAdd}
                  >
                    {ADD_BTN}
                  </Button>
                )
              }
            </>
          }
          header={
            <Text className="education-background-title" strong>
              {TITLE.EDUCATION_BACKGROUND}
            </Text>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {educationBackground.map((item) => {
              return (
                <Col
                  key={item.id}
                  xxl={{ span: 6 }}
                  span={8}
                  className="margin-bottom-2"
                >
                  <EducationCard
                    lead={lead}
                    onDelete={() => handleDelete(item)}
                    onEdit={() => handleEdit(item)}
                    title={capitalizeFirstLetter(item.degree_title || '')}
                    superscript={capitalizeFirstLetter(item.institution || '')}
                    subscript={
                      `${
                        item.course_start
                          ? `${moment(item.course_start)
                              .format('YYYY MMM')
                              .toString()} - ${moment(item.course_end)
                              .format('YYYY MMM ')
                              .toString()}`
                          : ''
                      }` || ''
                    }
                  >
                    <Row className="padding-bottom-1">
                      <Col span={12}>
                        <strong>
                          {capitalizeFirstLetter(item?.subject_area || '')}
                        </strong>
                      </Col>
                      <Col span={12}>
                        {capitalizeFirstLetter(item?.subject || '')}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>{LABEL.ACADEMIC_SCORE}</Col>
                      <Col span={12}>
                        {item.percentage
                          ? item.percentage + '%'
                          : item.secured_gpa
                          ? item.secured_gpa + '/' + item.total_gpa
                          : TEXT.NOT_AVAILABLE}
                      </Col>
                    </Row>
                  </EducationCard>
                </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          header={
            <Text className="education-background-title" strong>
              {TITLE.ENGLISH_TEST_SCORES}
            </Text>
          }
          extra={
            <>
              {
                // Can(
                //   PermissionAction.Create,
                //   new LeadsEntity({
                //     assignee_id: lead?.assignee_id,
                //     followers: lead?.followers?.map((item: any) => item.id),
                //   })
                // ) &&
                !lead?.archived && (
                  <Dropdown
                    overlayClassName="z-index-zero"
                    overlay={languageTestScoreMenu(
                      handleLanguageTestScoreMenuClick,
                      languageTestScore
                    )}
                    trigger={['click']}
                    disabled={languageTestScore.length >= 3 && true}
                  >
                    <Button
                      onClick={handleLanguageTestScoreAdd}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      {ADD_BTN}
                    </Button>
                  </Dropdown>
                )
              }
            </>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {languageTestScore.map((item: ILeadLanguageType) => {
              return (
                <Col key={item.id} xxl={{ span: 6 }} span={8}>
                  <EducationCard
                    lead={lead}
                    onDelete={() => handleLanguageTestScoreDelete(item)}
                    onEdit={() => handleLanguageTestScoreEdit(item)}
                    title={item.name?.toUpperCase() || ''}
                  >
                    <Row className="padding-bottom-1" gutter={GUTTER}>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.READING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.READING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.WRITING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.WRITING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.LISTENING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.LISTENING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div className="">{LABEL.SPEAKING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.SPEAKING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24}>
                        <Row justify="space-between">
                          <div className="overall_score_label">
                            {LABEL.OVERALL_SCORE}
                          </div>
                          <div className="overall_score">
                            {item.overall_score || '-'}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </EducationCard>
                </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          header={
            <Text className="education-background-title" strong>
              {TITLE.OTHER_TEST_SCORES}
            </Text>
          }
          extra={
            <>
              {
                // Can(
                //   PermissionAction.Create,
                //   new LeadsEntity({
                //     assignee_id: lead?.assignee_id,
                //     followers: lead?.followers?.map((item: any) => item.id),
                //   })
                // ) &&
                !lead?.archived && (
                  <Dropdown
                    overlayClassName="z-index-zero"
                    overlay={otherTestScoreMenu(
                      handleOtherTestScoreMenuClick,
                      otherTestScoreList
                    )}
                    trigger={['click']}
                  >
                    <Button
                      onClick={handleOtherTestScoreAdd}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      {ADD_BTN}
                    </Button>
                  </Dropdown>
                )
              }
            </>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {otherTestScoreList &&
              Object.keys(otherTestScoreList).map((item: string) => {
                if (
                  item === 'sat_1' ||
                  item === 'sat_2' ||
                  item === 'gre' ||
                  item === 'gmat'
                ) {
                  if (
                    otherTestScoreList[item] !== '0' &&
                    otherTestScoreList[item]
                  )
                    return (
                      <Col key={item} xxl={{ span: 6 }} span={8}>
                        <EducationCard
                          lead={lead}
                          onDelete={() => handleOtherTestScoreDelete(item)}
                          onEdit={() =>
                            handleOtherTestScoreEdit(
                              otherTestScoreList,
                              OTHER_TEST_KEY_VALUE[item]
                            )
                          }
                          title={OTHER_TEST_KEY_VALUE[item] || ''}
                        >
                          <Row>
                            <Col span={24}>
                              <Row justify="space-between">
                                <div className="overall_score_label">
                                  {LABEL.OVERALL_SCORE}
                                </div>
                                <div className="overall_score">
                                  {otherTestScoreList[item] || '-'}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </EducationCard>
                      </Col>
                    );
                }
                return null;
              })}
          </Row>
        </Collapse.Panel>
      </Collapse>
      <EducationBackgroundAddEdit
        id={id}
        lead={lead}
        initialData={educationBackgroundData}
        isOpen={educationDrawerIsOpen}
        onClose={handleEducationDrawerClose}
        disabled={disabled}
        setDisabled={setDisabled}
      />
      <LanguageTestScoreAddEdit
        id={id}
        name={selectedLanguageTestScore}
        initialData={languageTestScoreData}
        isOpen={languageTestScoreIsOpen}
        onClose={handleLanguageDrawerClose}
        disabled={disabled}
        setDisabled={setDisabled}
      />
      <OtherTestScoreAddEdit
        id={id}
        name={selectedOtherTestScore}
        initialData={otherTestScoreData}
        isOpen={otherTestDrawerIsOpen}
        onClose={handleOtherTestDrawerClose}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    </div>
  );
};

export { EducationBackground };
