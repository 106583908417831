import axios from './axios';
import { API_ENDPOINT_URL, API_URL } from '@moxie/constants';
import { IResponse, IWorkflow } from '@shared-components/models';

const getAllWorkflows = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW}/all/${params}`);
};

const getCRMWorkflows = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW}/${params}`);
};

const getOneCRMWorkflow = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW}/${id}`);
};

const addCRMWorkflow = async (data: IWorkflow): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_WORKFLOW}`, data);
};

const deleteCRMWorkflow = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_WORKFLOW}/${id}`);
};

const getWorkflowStages = async (workflowId: string): Promise<IResponse> => {
  return axios.get(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/${workflowId}`
  );
};

const addWorkflowStage = async (body: any): Promise<IResponse> => {
  return axios.post(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}`,
    body
  );
};

const deleteWorkflowStage = async (id: string): Promise<IResponse> => {
  const response = await axios.delete(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/${id}`
  );
  return response?.data;
};

const updateWorkflowStage = async (body: any): Promise<IResponse> => {
  return axios.put(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}`,
    body
  );
};

const getWfChecklists = async (wfStageId: string): Promise<IResponse> => {
  return axios.get(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist/${wfStageId}`
  );
};

const addWfStageChecklist = async (body: {
  stageId: string;
  data: Array<number>;
}): Promise<IResponse> => {
  return axios.post(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist`,
    body
  );
};

const deleteWorkflowStageChecklist = async (id: string): Promise<IResponse> => {
  return axios.delete(
    `/crm/${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist/${id}`
  );
};

const getCRMWorkflowsByServiceId = async (
  id: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW}/${id}/workflow/${params}`);
};

export {
  getAllWorkflows,
  getCRMWorkflows,
  getOneCRMWorkflow,
  addCRMWorkflow,
  deleteCRMWorkflow,
  addWorkflowStage,
  getWorkflowStages,
  deleteWorkflowStage,
  updateWorkflowStage,
  getWfChecklists,
  addWfStageChecklist,
  deleteWorkflowStageChecklist,
  getCRMWorkflowsByServiceId,
};
