import React, { useEffect, useState } from 'react';
import { PLACEHOLDER } from '@moxie/constants';
import Select, { SelectValue } from 'antd/lib/select';
import { IUser } from '@model/data.model';
import { Branch } from '@model/api-response.model';
import { useLocation } from 'react-router-dom';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange: (branch: SelectValue) => void;
  user: IUser | null;
  isLoading: boolean;
  branches: Branch[];
}

const SelectBranch: React.FC<Props> = ({ user, onChange, branches, isLoading }) => {
  const [branch, setBranch] = useState<string | undefined>();
  const location = useLocation();
  useEffect(() => {
    if (branches.length > 0 && branches.filter(b => b.id === user?.branch_id)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('branch');
      if (preSelectedBranch) {
        setBranch(preSelectedBranch);
        onChange(preSelectedBranch);
      } else {
        setBranch(user?.branch_id)
        onChange(user?.branch_id)
      }
    }
  }, [branches, user]);

  const onBranchChange = (value: SelectValue) => {
    setBranch(value as string);
    onChange(value);
  }

  return (
    <Select
    showSearch
      loading={isLoading}
      size="middle"
      style={{ 'width': '15.625rem' }}
      value={branch}
      options={branches.map(branch => ({ label: branch.name, value: branch.id }))}
      placeholder={PLACEHOLDER.SELECT_BRANCH}
      onChange={onBranchChange}
      optionFilterProp='label'
    />
  )
}

export default SelectBranch
