import React from 'react';

import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Collapse from 'antd/es/collapse';
import Typography from 'antd/es/typography';

import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import RedoOutlined from '@ant-design/icons/RedoOutlined';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { APPLY_FILTERS_BTN, PRODUCT_TABLE, TEXT } from '@moxie/constants';
import ProductTypeFilter from './product-type-filter';
import DisciplineFilter from './discipline-filter';
import { ProductFilterContext } from '../provider/product-filter-provider';

interface Props {
  onApply?: () => void;
  onReset?: (drawerVisibility: boolean) => void;
}

const ProductFilter: React.FC<Props> = ({ onReset, onApply }) => {
  const {
    setDisciplines,
    setProductSubTypes,
    disciplines,
    productSubTypes,
    resetFilters,
    setFilter,
    filter,
  } = React.useContext(ProductFilterContext);

  const handleApply = () => {
    setFilter({ productSubTypes, disciplines });
    if (onApply) {
      onApply();
    }
  };

  const handleReset = (visibility: boolean) => {
    resetFilters(true);
    if (onReset) {
      onReset(visibility);
    }
  };
  return (
    <Card>
      <div className="">
        <Row justify="space-between">
          <Space size={8}>
            {filter?.disciplines?.length || filter?.productSubTypes?.length ? (
              <FilterFilled />
            ) : (
              <FilterOutlined />
            )}
            <span className="font-bold">{TEXT.FILTERS}</span>
          </Space>
          <Button
            icon={<RedoOutlined />}
            type="link"
            onClick={() => handleReset(true)}
            data-testid="resetbtn"
          >
            {TEXT.RESET_FILTERS}
          </Button>
        </Row>

        <Collapse>
          <Collapse.Panel
            className="filter__panel space-1 filter__header"
            header={
              <Space size={8}>
                <ApartmentOutlined data-testid="producttype" />
                <Typography.Text strong>
                  {PRODUCT_TABLE.PRODUCT_TYPE}
                </Typography.Text>
              </Space>
            }
            key={'product-sub-type'}
          >
            <div className="margin-top-1 product_filter_collapse_panel">
              <ProductTypeFilter
                onChange={setProductSubTypes}
                selectedProductSubTypes={productSubTypes}
              />
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key="discipline"
            className="filter__panel space-1 filter__header"
            header={
              <Space size={8}>
                <AuditOutlined data-testid="discipline" />
                <Typography.Text strong>{TEXT.DISCIPLINE}</Typography.Text>
              </Space>
            }
          >
            <div className="margin-top-1 product_filter_collapse_panel">
              <DisciplineFilter
                selectedDisciplines={disciplines}
                onChange={setDisciplines}
              />
            </div>
          </Collapse.Panel>

          {/* <Collapse.Panel
                    className="filter__panel space-1 filter__header"
                    header={
                            <Space size={8}>
                                <ClockCircleOutlined />
                                <Typography.Text strong>{TEXT.OVERALL_ENGLISH_TEST_SCORE}</Typography.Text>
                            </Space>
                    }
                    key="requirement"
                >
                    <div className="prd">
                        <div className="prd-left">
                            <div className="prd-item">
                                <span>{TEXT.IELTS}</span>
                                <div className="prd-item__slider">
                                    <Slider min={0} max={9} step={0.5} />
                                </div>
                                <InputNumber min={0} max={9} />
                            </div>
                            <div className="prd-item">
                                <span>{TEXT.PTE}</span>
                                <div className="prd-item__slider">
                                    <Slider min={0} max={90} step={1} />
                                </div>
                                <InputNumber min={0} max={90} />
                            </div>
                            <div className="prd-item">
                                <span>{TEXT.TOEFL}</span>
                                <div className="prd-item__slider">
                                    <Slider min={0} max={120} step={1} />
                                </div>
                                <InputNumber min={0} max={120} />
                            </div>
                        </div>
                    </div>
                </Collapse.Panel> */}
        </Collapse>
      </div>
      <div className="text-align-right">
        <Button
          type="primary"
          block
          size="large"
          className="lead-margin-right margin-top-2"
          onClick={handleApply}
          data-testid="applyfilterbtn"
        >
          {APPLY_FILTERS_BTN}
        </Button>
      </div>
    </Card>
  );
};

export default ProductFilter;
