import React, { useState } from 'react';
import { TEXT } from '@moxie/constants';
import { ITableNameCardProps } from '@shared-components/models';
import { Avatar, Col, Row, Tooltip, Typography } from 'antd';
import { MailOutlined, LinkOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';

const Text = Typography.Text;

const UserCard: React.FC<ITableNameCardProps> = ({
    firstName,
    lastName,
    email,
    size,
    showEmailAsLink,
}: ITableNameCardProps) => {
    const [ellipsis] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };
    return (
        <Row className="lead-table__name user-name-card">
            <div className="align-items-center user-name-card__avatar">
                <Avatar size={size} className="initial_capital">
                    {firstName?.substring(0, 1)}
                    {lastName?.substring(0, 1)}
                </Avatar>
            </div>
            <Col className="user-name-card__name">
                <Text className="initial_capital">{`${firstName} ${lastName}`}</Text>

                <Row className="email_div">
                    {showEmailAsLink === true ? (
                        <>
                            <Col>
                                <a href={`mailto:${email}`}>
                                    {' '}
                                    <MailOutlined className="table-mail-icon" />{' '}
                                </a>
                            </Col>

                            <Col lg={19}>
                                <small className="table-elements-email-container">
                                    <a className="text-primary-color" href={`mailto:${email}`}>
                                        <Paragraph
                                            ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                                            className="text-primary-color"
                                        >
                                            {email?.toLowerCase()}
                                        </Paragraph>
                                    </a>
                                </small>
                            </Col>
                        </>
                    ) : (
                        <Col lg={18}>
                            <small>
                                <Paragraph
                                    className="assigned-user-email text-primary-color"
                                    ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                                >
                                    {email?.toLowerCase()}
                                </Paragraph>
                            </small>
                        </Col>
                    )}
                    {showEmailAsLink && (
                        <Col className="hide">
                            <Tooltip
                                className=" margin-left-1 "
                                placement="right"
                                color={isCopied ? '#8EAF74' : '#9B9D9A '}
                                title={
                                    isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                                }
                            >
                                <LinkOutlined
                                    className="copy_email_icon"
                                    onClick={() => copyToClipboard(`${email}`)}
                                />
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export { UserCard };