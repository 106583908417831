import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Typography } from 'antd';
import { ForgetPassword as ForgetPasswordForm } from '@shared-components/forms';
import { ApplyIMSLogoDark } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import {
  IForgetPasswordForm,
  IForgetPasswordResponse,
  ILogin,
} from '@shared-components/models';
import { FORGET_PASSWORD_DESCRIPTION, URL_CHECK_MAIL } from '@moxie/constants';

const { Title, Paragraph } = Typography;

const ForgetPassword: React.FC<IForgetPasswordForm> = ({
  onSubmit,
}: IForgetPasswordForm) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: ILogin) => {
    setLoading(true);
    try {
      const response: IForgetPasswordResponse = await onSubmit(data);
      if (response?.data?.success) {
        history.push(URL_CHECK_MAIL);
      }
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  return (
    <section className={`auth-layout auth-layout--login`}>
      <div className="auth-layout__card">
        <Card className="padding-2">
          <div className="text-align-center">
            <ApplyIMSLogoDark />
            <Title level={3}>Forgot your password?</Title>
            <Paragraph type="secondary">
              {FORGET_PASSWORD_DESCRIPTION}
            </Paragraph>
          </div>
          <ForgetPasswordForm onSubmit={handleSubmit} loading={loading} />
        </Card>
      </div>
    </section>
  );
};

export { ForgetPassword };
