/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { Table } from '@shared-components/elements';
import { getCRMWorkflowTypeByServiceId } from '@crm/services.api';
import {
  ADD_WORKFLOW_TYPE,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  TEXT,
  URL_WORKFLOWS,
} from '@moxie/constants';
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import {
  LeftOutlined,
  PlusOutlined,
  EditOutlined,
  MoreOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  IService,
  ITableProps,
  IWorkflowType,
  IWorkflowTypeTableProps,
} from '@shared-components/models';
import { useQuery } from '@moxie/utils';
import moment from 'moment';

const WorkflowTypeTitleMenu = ({ data, deleteData, showDrawer }: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data.id)}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const columns = (props: ITableProps) => {
  const { deleteData, showDrawer } = props;

  return [
    {
      title: TEXT.SN,
      key: 'id',
      dataIndex: 'id',
      width: 50,
      render: (
        _data: string,
        _row: IWorkflowType,
        index: number
      ): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: LABEL.NAME,
      width: 200,
      sorter: () => 0,
      render(data: IWorkflowType) {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
        };
        return data.sync_id === null ? (
          <Row justify="space-between">
            <Col span={18} className="align-items-center initial_capital">
              {data.name!.length > 31 ? (
                <Tooltip title={data?.name}>
                  <Typography.Text className="text-primary-color table-title-ellipsis">
                    {data.name}
                  </Typography.Text>
                </Tooltip>
              ) : (
                <Typography.Text className="text-primary-color">
                  {data.name}
                </Typography.Text>
              )}
            </Col>

            <Col span={1} className="align-items-center">
              <Dropdown overlay={WorkflowTypeTitleMenu(menuActions)}>
                <Button
                  type="text"
                  className="table-cell-action-btn"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={17} className="align-items-center initial_capital">
              {data.name!.length > 31 ? (
                <Tooltip title={data?.name}>
                  <Typography.Text className="text-primary-color table-title-ellipsis">
                    {data.name}
                  </Typography.Text>
                </Tooltip>
              ) : (
                <Typography.Text className="text-primary-color">
                  {data.name}
                </Typography.Text>
              )}
            </Col>
            <Col span={4} className="margin-right-3">
              <Tag className="tag-processing">System Default</Tag>
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.SERVICE,
      width: 300,
      dataIndex: 'service',
      render(service: IService) {
        return <div className="initial_capital">{service?.name}</div>;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 200,
      dataIndex: 'created_at',
      render(date: string) {
        return moment(date).format('MMM DD, YYYY');
      },
    },
  ];
};

const WorkflowTypeListTable: React.FC<IWorkflowTypeTableProps> = ({
  showDrawer,
  refreshTable,
  deleteData,
}: IWorkflowTypeTableProps) => {
  const { serviceId }: { serviceId: string } = useParams();

  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
  };

  const getWorkflowTypesList = (params: string) => {
    return getCRMWorkflowTypeByServiceId(serviceId!, params);
  };

  return (
    <div className="bg-white padding-top-2">
      <Table
        action={getWorkflowTypesList}
        tableName={TEXT.WORKFLOW_TYPES}
        columns={columns(columnActions)}
        refresh={refreshTable}
        filterOptions={true}
        tableButtons={
          <Space direction="horizontal">
            <Link to={`/service/${serviceId}${URL_WORKFLOWS}`}>
              <Button icon={<LeftOutlined />}>{TEXT.WORKFLOW_LIST}</Button>
            </Link>
            <Button
              type="primary"
              typeof="button"
              onClick={() => showDrawer?.()}
            >
              <PlusOutlined />
              {ADD_WORKFLOW_TYPE}
            </Button>
          </Space>
        }
      />
    </div>
  );
};
export { WorkflowTypeListTable };
