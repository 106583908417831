import { RcFile } from "antd/es/upload";
import axiosInstance from "./axios";
import { IAddEnquiryData, IEnquiryData } from "@model/data.model";
export const uploadFormImage = async (fieldName: string, file: RcFile) => {
    const formData = new FormData();
    formData.append(fieldName, file)
    const { data } = await axiosInstance.post('/crm/contact-form/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return data;
}
export const addEnquiryFormData = async (payload: IAddEnquiryData) => {
    const {data} = await axiosInstance.post('/crm/contact-form/', payload);
    return data;
}
export const getEnquiryForms = async (page: number, limit: number, finalSearchValue: string) => {
    const {data} = await axiosInstance.get(`/crm/contact-form?s=${finalSearchValue}`, {
        params: {
            page,
            limit
        }
    });
    return data;
}
export const updateEnquiryForms = async (payload: IEnquiryData) => {
    const {data} = await axiosInstance.put(`/crm/contact-form/${payload.id}`, payload);
    return data;
}
export const getEnquiryFormById =async (id:string) => {
    const {data} = await axiosInstance.get(`/crm/contact-form/${id}`);
    return data;
}
export const deleteEnquiryForm = async (id:string) => {
    const {data} = await axiosInstance.delete(`/crm/contact-form/${id}`);
    return data;
}

export const getContactEnquiries = async (contactId: string, page: number, limit: number) => {
    const {data} = await axiosInstance.get(`/crm/contact-inquiry/${contactId}`, {
        params: {
            page,
            limit
        }
    });
    return data;
}