import { updateBranches } from "@crm/services.api";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useUpdateBranchMutation = (contactId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (branches: string[]) => updateBranches(contactId,branches),
        onSuccess() {
            successNotificationHandler('Branches updated successfully.')
            queryClient.invalidateQueries({
                queryKey:['contact-branches',contactId]
            });

            queryClient.invalidateQueries({
                queryKey:['contact-assignable-users',contactId]
            });
            
        },
        onError() {
            errorNotificationHandler('Failed to update branches');
        }
    })
}
