import { API_URL } from "@moxie/constants"
import axios from "./axios"
import { IPaginateResponse, IProductFee, IResponse, ProductFeeFieldValues, ProductFeeValidation } from "@model/data.model";


const fetchProductFees = async (productId: string, page: number) => {
    const { data } = await axios.get<IPaginateResponse<IProductFee>>(`${API_URL.CRM_PRODUCT_FEE}/product/${productId}`, {
        params: {
            page,
            limit: 10
        }
    })
    return data;
}

const addProductFee = async (product_id: string, body: ProductFeeFieldValues) => {
  const { data } = await axios.post<IResponse>(API_URL.CRM_PRODUCT_FEE, {
    ...body,
    product_id,
  })
  return data;
}

const updateProductFee = async(fee: string, product_id: string,body:ProductFeeFieldValues)  => {
  const {data} = await axios.put<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/${fee}`,{...body, product_id});

  return data;
}

const validateProductFeeName = async (params: ProductFeeValidation) => axios.get<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/check/name`, { params })

const deleteProductFee = (feeId: string) => axios.delete<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/${feeId}`);

export {
    fetchProductFees,
    validateProductFeeName,
    addProductFee,
    deleteProductFee,
    updateProductFee,
}
