import { fetchUserNotifications } from "@crm/services.api"
import { IPaginateResponse } from "@model/data.model"
import { Notification } from "@model/notification"
import { errorNotificationHandler } from "@moxie/shared"
import { useInfiniteQuery } from "@tanstack/react-query"

export const useFetchNotifications = (limit: number) => {
    const query = useInfiniteQuery<IPaginateResponse<Notification>>({
        queryFn: (args) => fetchUserNotifications(args.pageParam, limit),
        queryKey: ['notifications', limit],
        getNextPageParam: (lastPage) => lastPage.meta.page === lastPage.meta.total ? false : Number(lastPage.meta.page) + 1,
        onError() {
            errorNotificationHandler('Error in fetching notifications')
        }
    });
    
    return {
        notifications: query.data?.pages?.map(p => p.data)?.flat() ?? [],
        hasMore: Boolean(query.hasNextPage),
        next: query.fetchNextPage,
        currentPage: (query.data?.pages?.slice(-1)[0])?.meta.page ?? 1,
        isLoading: query.isLoading
    }
}
