import { Layout } from '@shared-components/modules';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Registration from './registration';
import RegistrationSuccess from './registration-success';

const App: React.FC = () => {
  return (
    <Layout>
      <Router>
        <Switch>
          <Route exact path="/" component={Registration} />
          <Route
            exact
            path="/registration-success"
            component={RegistrationSuccess}
          />
        </Switch>
      </Router>
    </Layout>
  );
};

export default App;
