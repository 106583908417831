import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './product.action';
import * as action from './product.constant';
import { AxiosResponse } from 'axios';
import { errorHandler } from '@moxie/utils';
import { successNotificationHandler } from '@moxie/shared';
import { IAction, IProduct } from '@shared-components/models';
import {
  getCRMProductsList,
  postCRMProduct,
} from '../../libs/services.api/lib/product.api';

export const addProduct = function* ({
  payload,
  triggerRefresh,
}: IAction<IProduct>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postCRMProduct(payload);
      yield put(actionTypes.addProductSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTypes.addProductFailure(err));
  }
};

const getProducts = function* (params: any) {
  try {
    const res: AxiosResponse = yield getCRMProductsList(params.payload);
    yield put(actionTypes.getProductsSuccess(res?.data?.data));
  } catch (err) {
    errorHandler(err);
    yield put(actionTypes.getProductsFailure(err));
  }
};

const productSaga = function* (): unknown {
  yield takeLatest(action.ADD_PRODUCT_REQUEST, addProduct);
  yield takeLatest(action.GET_PRODUCTS_REQUEST, getProducts);
};

export default productSaga;
