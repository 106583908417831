import axiosInstance from "@crm/libs/services.api/lib/axios"
import { ContactForm } from "@model/contact-form"
import { useQuery } from "@tanstack/react-query"

const useContactForm = (id: string) => {
    
    const {data: contactForm, isLoading, error} = useQuery({
        queryKey: ['contact-form',id],
        queryFn:() => axiosInstance.get<{data: ContactForm}>(`/crm/contact-form/${id}/public`).then(res => res.data.data)
    })

    return {
        isLoading,
        contactForm,
        error
    }
}

export default useContactForm
