import { fetchContactApplicationAssignees } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';


export const useFetchApplicationAssignee = (contactId: string) => {
    const { data } = useQuery({
      queryKey: ['contact-application-assignee', contactId],
      queryFn: () => fetchContactApplicationAssignees(contactId),
      onError: () => {
        errorNotificationHandler('Error contact application assignee.');
      },
    });
  
    return {
      applicationAssignees: data?.data ?? [],
    }
  }
  