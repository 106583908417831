import { CaretRightOutlined } from '@ant-design/icons';
import { checkUserEmail } from '@crm/services.api';
import { capitalizeFirstLetter } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import {
  DrawerElem,
  FormItem,
  Option,
  SearchableSelect,
  Select,
} from '@shared-components/elements';
import { IGetPopupContainer, IOfficeBranch } from '@shared-components/models';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  Row,
  Space,
  Spin,
} from 'antd';
import AntSelect, { SelectValue } from 'antd/lib/select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RootState } from 'apps/crm/src/core/store';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADDRESS1_LABEL,
  ADDRESS1_PLACEHOLDER,
  ADDRESS2_LABEL,
  ADDRESS2_PLACEHOLDER,
  ADDRESS_INFORMATION,
  ALTERNATIVE_PHONE_LABEL,
  AUTH_MESSAGE,
  CANCEL_BTN,
  CHANGE_TIMEZONE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  CONTACT_INFORMATION,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  DRAWER_WIDTH,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  JOB_INFORMATION,
  LABEL,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PLACEHOLDER,
  regex,
  ROLE_LABEL,
  ROLE_PLACEHOLDER,
  SAVE_BTN,
  TEXT,
  TIMEZONE,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
  USER_ADD_TITLE,
  USER_EDIT_TITLE,
} from '../../../../../libs/constants/src/index';
import { officeBranchActions, rolePermissionActions } from '@crm/core';
import { RuleObject } from 'antd/lib/form';
import { useAssignedBranch } from '@crm/libs/hooks/contacts';

const { Panel } = Collapse;

interface Props {
  form: FormInstance<any>;
  visible: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  saveData: (payload: any) => void;
  isEdit?: boolean;
}

const UserDrawer = ({
  visible,
  onClose,
  form,
  saveData,
  isEdit,
  disabled,
  setDisabled,
}: Props) => { 
  const rolesData = useSelector((store: any) => store.role_permissions);
  const { branches: branchesData, isLoading: branchesLoading } = useAssignedBranch(); 

  const dispatch = useDispatch();
  const currentUser = useSelector((store: RootState) => store.auth.user);
  const [userEmail, setUserEmail] = useState('');
  const [, setEmailFound] = useState(false);

  const user = useSelector((store: any) => store.users.singleData);
  const userDataLoading = useSelector((store: any) => store.users.loading);

  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');

  const [, setUpdateRole] = useState<string | null>();
  const [branch, setBranch] = useState('');
  const departmentList: any = [];

  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleCountryCodeChange = (input: any, country: any) => {
    if(input){
      setPhone(input?.slice(country?.dialCode?.length));
      setCountryCode(`+${country?.dialCode}`);
    } else  {
      setPhone('');
      setCountryCode('');
    }
    setDisabled(false);
  };

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const validateEmail = async (value: { email: string }) => {
    try {
      const res = await checkUserEmail(value?.email);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };

  const handleEmailChange = async (rule: any, value: any): Promise<any> => {
    if (isEdit) return;
    value = value?.toLowerCase();
    if (!value) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    }
    const regexEmail = new RegExp(regex.EMAIL);
    if (!regexEmail.test(value)) {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.INVALID_EMAIL);
    }
    setEmailValidationStatus('validating');
    const trimValue = value?.replace(/ /g, '');
    const result = await validateEmail({
      email: trimValue,
    });

    if (result) {
      setEmailValidationStatus('success');
      return Promise.resolve(true);
    } else {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
    }
  };

  const validatePhoneNumber = (_: RuleObject, value: string) => {
    if (value && value.replace(/\D/g, '').length < 10) {
      setDisabled(true);
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };

  const checkEmailExists = async () => {
    try {
      const emailErr = form.getFieldsError(['email']);
      const emailData = form.getFieldsValue(['email']);
      const formErrors = emailErr.map((err) => err.errors).flat();
      if (
        (emailErr.length === 0 ||
          formErrors.includes(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN)) &&
        emailData
      ) {
        const d = await checkUserEmail(emailData);
        if (d.data?.success === true) {
          setEmailFound(true);
          form.setFields([
            {
              name: 'email',
              errors: [AUTH_MESSAGE.REGISTER_EMAIL_TAKEN],
            },
          ]);
          setDisabled(true);
        } else if (d.data?.success === false) {
          setEmailFound(false);
          form.setFields([
            {
              name: 'email',
              errors: [],
            },
          ]);
          setDisabled(false);
        }
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleBranchClear = () => {
    form.setFieldsValue({ department_id: null });
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleSubmit = async (data: any) => {
    data.country_code = countryCode;

    if (form.getFieldValue('email') === undefined) {
      setEmailValidationStatus('error');
    }
    form.submit();
    setDisabled(true);
  };

  const secondaryBranches = useMemo(() => {
    const branches = branchesData;
    if (!branch) {
      return branches;
    }
    return branches.filter((br: IOfficeBranch) => br.id !== branch);
  }, [branch, branchesData]);

  useEffect(() => {
    dispatch(officeBranchActions.getAllRequest());
    dispatch(rolePermissionActions.getRolesRequest());
  }, []);

  useEffect(() => {
    if (isEdit) {
      setBranch(user?.branch?.id);
      form.setFieldsValue({
        first_name: user?.first_name,
        last_name: user?.last_name,
        address1: user?.address1,
        address2: user?.address2,
        country: user?.country,
        city_or_state: user?.city_or_state,
        phone: user?.phone,
        alternative_phone: user?.alternative_phone,
        secondaryBranches:
          user?.secondaryBranches?.map((branch: IOfficeBranch) => branch.id) ??
          [],
        role: user?.role?.id,
        branch_id: user?.branch?.id,
        department_id: user?.department,
        time_zone: user?.time_zone,
      });
      handleCountryChange(user.country);
    }
  }, [isEdit, userDataLoading, user]);

  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        time_zone: currentUser?.time_zone,
      });
    }
  }, [visible, isEdit]);

  useEffect(() => {
    let userCheckTimeout: any;
    if (!isEdit) {
      userCheckTimeout = setTimeout(checkEmailExists, 300);
    }
    return () => clearTimeout(userCheckTimeout);
  }, [userEmail]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        email: user?.email,
      });
    }
  }, [isEdit, onClose]);

  const handleClose = () => {
    setEmailValidationStatus('success');
    onClose();
  };

  if (visible) {
    return (
      <DrawerElem
        title={<div>{isEdit ? USER_EDIT_TITLE : USER_ADD_TITLE}</div>}
        width={DRAWER_WIDTH}
        visible={visible}
        onClose={handleClose}
        placement={USER_ADD_PLACEMENT}
        data-testid="crm-adduserdrawer"
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={handleClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              {isEdit ? (
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {UPDATE_BTN}
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {SAVE_BTN}
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <Spin spinning={rolesData.loading || branchesLoading}>
          <Form
            onFieldsChange={onFieldsChange}
            layout="vertical"
            form={form}
            size={FORM_SIZE}
            onFinish={saveData}
          >
            <div>
              <Row gutter={GUTTER}>
                <Col span="12">
                  <FormItem
                    name="first_name"
                    label={FIRST_NAME_LABEL}
                    rules={[{ required: true, max: 50, type: 'string' }]}
                  >
                    <Input
                      placeholder={FIRST_NAME_PLACEHOLDER}
                      data-testid="crm-adduser-firstname"
                    />
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem
                    name="last_name"
                    label={LAST_NAME_LABEL}
                    rules={[{ required: true, max: 50, type: 'string' }]}
                  >
                    <Input
                      placeholder={LAST_NAME_PLACEHOLDER}
                      data-testid="crm-adduser-lastname"
                    />
                  </FormItem>
                </Col>
                <Divider />
              </Row>
            </div>
            <Collapse
              defaultActiveKey={ADDRESS_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{ADDRESS_INFORMATION}</span>}
                key={ADDRESS_INFORMATION}
              >
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <FormItem
                      name="address1"
                      label={ADDRESS1_LABEL}
                      rules={[{ required: true, type: 'string', max: 50 }]}
                    >
                      <Input
                        placeholder={ADDRESS1_PLACEHOLDER}
                        data-testid="crm-adduser-addressone"
                      />
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem
                      name="address2"
                      label={ADDRESS2_LABEL}
                      rules={[{ type: 'string', max: 100 }]}
                    >
                      <Input
                        placeholder={ADDRESS2_PLACEHOLDER}
                        data-testid="crm-adduser-addresstwo"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <div id="user_country_list" className="relative">
                      <FormItem
                        name="country"
                        label={COUNTRY_LABEL}
                        rules={[{ required: true, type: 'string', max: 50 }]}
                      >
                        <SearchableSelect
                          onChange={(value: SelectValue) => {
                            if (value) handleCountryChange(value.toString());
                          }}
                          placeholder={COUNTRY_PLACEHOLDER}
                          data-testid="crm-adduser-country"
                          filterOption={handleOptionFilter}
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_country_list')
                          }
                        >
                          {COUNTRIES.map((country) => {
                            return (
                              <Option value={country.name} key={country.code}>
                                {country.name}
                              </Option>
                            );
                          })}
                        </SearchableSelect>
                      </FormItem>
                    </div>
                  </Col>
                  <Col span="12">
                    <FormItem
                      name="city_or_state"
                      label={CITY_OR_STATE_LABEL}
                      rules={[{ required: true, type: 'string', max: 100 }]}
                    >
                      <Input
                        placeholder={CITY_OR_STATE_PLACEHOLDER}
                        data-testid="crm-adduser-city"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={CONTACT_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{CONTACT_INFORMATION}</span>}
                key={CONTACT_INFORMATION}
              >
                <FormItem
                  name="email"
                  label={EMAIL_LABEL}
                  validateStatus={emailValidationStatus}
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                    },
                    { validator: handleEmailChange },
                  ]}
                >
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserEmail(e.target.value);
                    }}
                    placeholder={EMAIL_PLACEHOLDER}
                    data-testid="crm-adduser-email"
                    disabled={isEdit}
                    autoComplete="off"
                  />
                </FormItem>
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <Form.Item
                      name="phone"
                      data-testid="crm-adduser-phone"
                      label={PHONE_LABEL}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter phone number',
                        },
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <PhoneInput
                        value={phone}
                        placeholder={''}
                        onChange={handleCountryCodeChange}
                        enableLongNumbers={15}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <FormItem
                      data-testid="crm-adduser-altphone"
                      name="alternative_phone"
                      label={ALTERNATIVE_PHONE_LABEL}
                      rules={[
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <PhoneInput
                        value={phone}
                        placeholder={''}
                        onChange={handleCountryCodeChange}
                        enableLongNumbers={15}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={JOB_INFORMATION}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{JOB_INFORMATION}</span>}
                key={JOB_INFORMATION}
              >
                <Row gutter={GUTTER}>
                  <Col span="12">
                    <div id="user_role" className="relative">
                      <FormItem
                        name="role"
                        label={ROLE_LABEL}
                        data-testid="crm-adduser-role"
                        rules={[{ required: true }]}
                      >
                        <AntSelect
                          onChange={(value: any) => {
                            setUpdateRole(value);
                          }}
                          showSearch
                          allowClear
                          optionFilterProp="key"
                          placeholder={ROLE_PLACEHOLDER}
                          defaultActiveFirstOption={false}
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_role')
                          }
                        >
                          {rolesData.roles &&
                            rolesData.roles.map((item: any) => {
                              return (
                                <Option value={item?.id} key={item?.name}>
                                  {capitalizeFirstLetter(item?.name)}
                                </Option>
                              );
                            })}
                        </AntSelect>
                      </FormItem>
                    </div>
                  </Col>
                  <Col span="12">
                    <div id="user_branch" className="relative">
                      <FormItem
                        name="branch_id"
                        label={LABEL.BRANCH}
                        rules={[{ required: true }]}
                      >
                        <AntSelect
                          onChange={(data: any) => {
                            setBranch(data);
                            form.resetFields(['secondaryBranches']);
                          }}
                          showSearch
                          allowClear
                          optionFilterProp="key"
                          placeholder={PLACEHOLDER.SELECT_BRANCH}
                          data-testid="crm-adduser-branch"
                          defaultActiveFirstOption={false}
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_branch')
                          }
                        >
                          {!branchesLoading &&
                            branchesData.map((item: any) => {
                              return (
                                <Option value={item?.id} key={item?.name}>
                                  {capitalizeFirstLetter(item?.name)}
                                </Option>
                              );
                            })}
                        </AntSelect>
                      </FormItem>
                    </div>
                  </Col>

                  <Col span="12">
                    <div id="user_secondary_branches" className="relative">
                      <FormItem
                        name="secondaryBranches"
                        label={LABEL.SECONDARY_BRANCHES}
                      >
                        <SearchableSelect
                          optionFilter="key"
                          placeholder={PLACEHOLDER.SELECT_BRANCH}
                          data-testid="crm-adduser-secbranch"
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_secondary_branches')
                          }
                          mode="multiple"
                          onClear={handleBranchClear}
                        >
                          {secondaryBranches &&
                            secondaryBranches.map((item: IOfficeBranch) => {
                              return (
                                <Option value={item?.id || ''} key={item?.name}>
                                  {item?.name}
                                </Option>
                              );
                            })}
                        </SearchableSelect>
                      </FormItem>
                    </div>
                  </Col>
                  <Col span="12">
                    <div id="user_department" className="relative">
                      <FormItem
                        name="department_id"
                        label={LABEL.DEPARTMENT}
                        rules={[{ required: false }]}
                      >
                        <SearchableSelect
                          optionFilter="key"
                          placeholder={PLACEHOLDER.SELECT_DEPARTMENT}
                          data-testid="crm-adduser-department"
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_department')
                          }
                        >
                          {departmentList &&
                            departmentList.map((item: any) => {
                              return (
                                <Option
                                  value={item.department?.id}
                                  key={item.department?.name}
                                >
                                  {item.department?.name}
                                </Option>
                              );
                            })}
                        </SearchableSelect>
                      </FormItem>
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider />
            <Collapse
              defaultActiveKey={CHANGE_TIMEZONE}
              bordered={false}
              ghost={true}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span>{CHANGE_TIMEZONE}</span>}
                key={CHANGE_TIMEZONE}
              >
                <div id="user_timezone" className="relative">
                  <Row gutter={GUTTER}>
                    <Col span="24">
                      <Form.Item
                        label="Timezone"
                        name="time_zone"
                        data-testid="crm-adduser-timezone"
                        rules={[
                          {
                            required: true,
                            message: `${TEXT.TIMEZONE_SELECT}`,
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(): IGetPopupContainer =>
                            document.getElementById('user_timezone')
                          }
                        >
                          {TIMEZONE.map((data, idx) => {
                            return (
                              <Option value={data.utc[0]} key={idx}>
                                {data.text}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Collapse>
          </Form>
        </Spin>
      </DrawerElem>
    );
  } else return <div />;
};

UserDrawer.defaultProps = {
  isView: false,
};

export default UserDrawer;
