import React, { useEffect, useState } from 'react';
import {
  IForm,
  IGetPopupContainer,
  IOfficeBranch,
} from '@shared-components/models';
import { Col, Collapse, Form, Input, Row, Spin } from 'antd';
import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  ADDRESS_INFORMATION,
  ALPHANUMERIC_REQUIRED,
  AUTH_MESSAGE,
  BRANCH_NAME_REQUIRED,
  BRANCH_RESPONSE_MESSAGE,
  COUNTRIES,
  COUNTRY_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  GUTTER,
  LABEL,
  OFFICE_INFORMATION,
  OFFICE_PLACEHOLDER,
  PLACEHOLDER,
  regex,
  TIMEZONE,
  TIMEZONE_LABEL,
} from '@moxie/constants';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  SearchableSelect,
  Option,
  UserSelectBox,
} from '@shared-components/elements';
import { SelectValue } from 'antd/lib/select';
import { useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { checkBranchName } from '@crm/services.api';
import { RuleObject } from 'antd/lib/form';

const { Panel } = Collapse;

const OfficeBranchForm: React.FC<IForm<IOfficeBranch>> = ({
  form,
  initialData,
  handleFieldsChange,
  onSubmit,
  setNameValidationStatus,
}: IForm<IOfficeBranch>) => {
  const [phone, setPhone] = useState('');
  const [, setCountryCode] = useState<any>();

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
      handleCountryChange(initialData?.address?.country);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  const users = useAppSelector((store: RootState) => store.users.allData);
  const usersLoading = useAppSelector(
    (store: RootState) => store.users.loading
  );

  const handleCountryChange = (countryName?: string) => {
    COUNTRIES.find((item) => {
      if (item.name === countryName) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleCountryCodeChange = (input: any, country: any) => {
    if(input){
      setPhone(input?.slice(country?.dialCode?.length));
      setCountryCode(`+${country?.dialCode}`);
    } else  {
      setPhone('');
      setCountryCode('');
    }
  };

  const validatePhoneNumber = (_: RuleObject, value: string) => {
    if (value && value.replace(/\D/g, '').length < 10) {
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };

  const handleBranchNameChange = async (
    rule: any,
    value: any
  ): Promise<any> => {
    value = value?.trim();
    value = value?.toLowerCase().replace(/  + /g, ' ');
    if (value?.length > 50) {
      setNameValidationStatus('error');
      return Promise.resolve(true);
    }
    if (value?.length > 0) {
      setNameValidationStatus('validating');
      if (value !== '' || null || undefined) {
        const result = await checkBranchName(value, initialData?.id);
        if (result.data.success) {
          setNameValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setNameValidationStatus('error');
          return Promise.reject(
            new Error(BRANCH_RESPONSE_MESSAGE.BRANCH_NAME_ALREADY_EXISTS)
          );
        }
      }
    }
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Spin spinning={usersLoading}>
        <div className="form_section_header">{OFFICE_INFORMATION}</div>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={LABEL.BRANCH_NAME}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                  message: ALPHANUMERIC_REQUIRED,
                },
                { max: 50 },
                {
                  whitespace: true,
                  type: 'string',
                  message: BRANCH_NAME_REQUIRED,
                },
                { validator: handleBranchNameChange },
              ]}
            >
              <Input
                placeholder={OFFICE_PLACEHOLDER}
                data-testid="crm-addbranch-branchname"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div id="country_list">
              <Form.Item
                name={['address', 'country']}
                label={LABEL.COUNTRY}
                rules={[{ required: false }]}
              >
                <SearchableSelect
                  onChange={(value: SelectValue) => {
                    if (value) handleCountryChange(value.toString());
                  }}
                  placeholder={COUNTRY_PLACEHOLDER}
                  data-testid="crm-addbranch-country"
                  optionFilter="search_prop"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('country_list')
                  }
                >
                  {COUNTRIES.map((item) => {
                    return (
                      <Option
                        value={item.name}
                        key={item.code}
                        search_prop={`${item.name} ${item.code}`}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="email"
              label={LABEL.EMAIL}
              rules={[
                {
                  required: true,
                  message: AUTH_MESSAGE.INVALID_EMAIL,
                  pattern: new RegExp(regex.EMAIL),
                },
              ]}
            >
              <Input
                placeholder={EMAIL_PLACEHOLDER}
                data-testid="crm-addbranch-email"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid="crm-addbranch-mobile"
              name="mobile"
              label={LABEL.MOBILE}
              rules={[
                {
                  validator: validatePhoneNumber,
                },
              ]}
            >
              <PhoneInput
                value={phone}
                placeholder={''}
                onChange={handleCountryCodeChange}
                enableLongNumbers={15}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="business_registration_number"
              label={LABEL.BUSINESS_REG_NO}
              rules={[
                {
                  pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                  message: ALPHANUMERIC_REQUIRED,
                },
                { required: false },
              ]}
            >
              <Input
                placeholder={LABEL.BUSINESS_REG_NO}
                data-testid="crm-addbranch-businessno"
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <div id="timezone_list">
              <Form.Item
                name="timezone"
                label={TIMEZONE_LABEL}
                rules={[{ required: false }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.SELECT_TIMEZONE}
                  data-testid="crm-addbranch-timezone"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('timezone_list')
                  }
                >
                  {TIMEZONE.map((item) => {
                    return (
                      <Option value={item.text} key={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <div id="contact_person_list">
              <Form.Item
                name="contact_person_id"
                label={LABEL.CONTACT_PERSON}
                rules={[{ required: false }]}
              >
                <UserSelectBox
                  placeholder={LABEL.CONTACT_PERSON}
                  data-testid="crm-addbranch-contactperson"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('contact_person_list')
                  }
                  users={users}
                  showUnassigned={true}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Collapse
          defaultActiveKey={['addressInfo']}
          bordered={false}
          ghost={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header={<span>{ADDRESS_INFORMATION}</span>} key="addressInfo">
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name={['address', 'address_line_one']}
                  label={ADDRESS1_LABEL}
                  rules={[
                    { max: 50 },
                    {
                      pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                      message: ALPHANUMERIC_REQUIRED,
                    },
                    { required: false },
                  ]}
                >
                  <Input
                    placeholder={ADDRESS1_LABEL}
                    data-testid="crm-addbranch-addressone"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['address', 'address_line_two']}
                  label={ADDRESS2_LABEL}
                  rules={[
                    { max: 50 },
                    {
                      pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                      message: ALPHANUMERIC_REQUIRED,
                    },
                    { required: false },
                  ]}
                >
                  <Input
                    placeholder={ADDRESS2_LABEL}
                    data-testid="crm-addbranch-addresstwo"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name={['address', 'city']}
                  label={LABEL.CITY}
                  rules={[
                    { max: 50 },
                    {
                      pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                      message: ALPHANUMERIC_REQUIRED,
                    },
                    { required: false },
                  ]}
                >
                  <Input
                    placeholder={PLACEHOLDER.CITY}
                    data-testid="crm-addbranch-city"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['address', 'state']}
                  label={LABEL.STATE}
                  rules={[
                    { max: 50 },
                    {
                      pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                      message: ALPHANUMERIC_REQUIRED,
                    },
                    { required: false },
                  ]}
                >
                  <Input
                    placeholder={PLACEHOLDER.STATE}
                    data-testid="crm-addbranch-state"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name={['address', 'postal_code']}
                  label={LABEL.ZIP_OR_POSTAL_CODE}
                  rules={[
                    { max: 10 },
                    {
                      required: false,
                      pattern: regex.ALPHA_NUMERIC_WITH_SPACE,
                      message: ALPHANUMERIC_REQUIRED,
                    },
                  ]}
                >
                  <Input
                    placeholder={LABEL.POSTAL_CODE}
                    data-testid="crm-addbranch-postalcode"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Spin>
    </Form>
  );
};

export { OfficeBranchForm };
