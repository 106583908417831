import React, { useEffect } from 'react';
import { authActions } from '@crm/core';
import { useDispatch } from 'react-redux';

const CrmDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.getAuthUser());
  }, []);
  return (
    <div>
      <h3>This is CRM dashboard</h3>
    </div>
  );
};

export { CrmDashboard };
