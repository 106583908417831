import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ADD_OFFICE_VISIT,
  LABEL,
  PAGE_HEADER_MENU_OFFICE_VISIT,
  TEXT,
} from '@moxie/constants';
import { PageHeaderTitle, Table } from '@shared-components/elements';
import AntSelect from 'antd/lib/select';
import { OfficeVisitDrawer } from './office-visit-drawer';
import { Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { officeVisitActions, useAppSelector } from '@crm/core';
import { PlusOutlined } from '@ant-design/icons';
import {
  attendingColumns,
  waitingColumns,
  columns,
  completedColumns,
} from './office-visit-table-column';
import { officeVisitList } from '@crm/services.api';
import { OfficeVisitDetailsDrawer } from './office-visit-details-drawer';
import { PageHeaderMenu } from '../components/page-header-menu';
import { Branch } from '@model/api-response.model';
import { useAssignedBranch } from '@crm/libs/hooks/contacts';

const OfficeVisitTableComponent: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const [form] = useForm();
  const localBranchId = localStorage.getItem('branchId');
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const {
    initialData,
    drawerIsOpen,
    detailDrawerIsOpen,
    refreshTable,
    loggedInUserId,
    companyId,
    branchId,
  } = useAppSelector((state) => ({
    initialData: state.office_visits.singleData,
    drawerIsOpen: state.office_visits.drawerIsOpen,
    detailDrawerIsOpen: state.office_visits.detailDrawerIsOpen,
    refreshTable: state.office_visits.refresh,
    loggedInUserId: state.auth?.user?.id,
    companyId: state.auth?.user?.company_id,
    branchId: state.auth?.user?.branch_id,
  }));

  const { branches: branchList, isLoading: branchListLoading} = useAssignedBranch();

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState<any>({
    selectBy: name,
    branch: localBranchId || branchId,
  });
  const [showAll, setShowAll] = useState<boolean>(true);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<string>(
    branchId ? branchId : localBranchId ? localBranchId : ''
  );
  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const showDrawer = () => {
    setVisible(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    form.resetFields();
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
    setDisabled(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    dispatch(officeVisitActions.closeOfficeVisitDrawer());
  };

  const handleDetailsDrawerClose = () => {
    dispatch(officeVisitActions.closeOfficeVisitDetailsDrawer());
  };

  const getData = (str: string) => {
    return officeVisitList(str);
  };

  const setDrawerOpen = (): boolean => {
    return visible || drawerIsOpen!;
  };

  const submitForm = (data: any) => {
    if (initialData?.id) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData.id,
          data,
          triggerRefresh
        )
      );
    } else {
      const newData = { ...data, company_id: companyId, branch_id: branchId };
      dispatch(
        officeVisitActions.addOfficeVisitRequest(newData, () => {
          dispatch(officeVisitActions.officeVisitTableRefresh());
          socket?.emit('server::office-visit-updated', {
            assignee: data.assignee_id,
            branch: branchList?.find((branch: Branch) => branch.id === data.branch_id)
              ?.name as string,
            contact_id: data.contact_id,
            crm_user_id: user?.id as string,
            type: 'created',
          });
        })
      );
    }
    handleClose();
  };

  const handleTableButtons = async (type: string, isActive: boolean) => {
    switch (type) {
      case 'assignedToMe':
        setAssignedToMe(!assignedToMe);
        setShowAll(isActive);
        setFilter({ ...filter, userId: isActive ? '' : loggedInUserId });
        return;
      case 'showAll':
        setShowAll(!showAll);
        setAssignedToMe(isActive);
        setFilter({ ...filter, userId: '' });
        return;
      default:
        return;
    }
  };

  const handleBranchChange = (branchId: string) => {
    setSelectedBranch(branchId);
    setFilter({ ...filter, branch: branchId });
  };

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ ...filter, selectBy: name });
  }, [name]);

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.OFFICE_VISIT} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_OFFICE_VISIT}
        defaultActiveKey={name}
      >
        <div className="add-office-visit-btn-container">
          <Button type="primary" onClick={showDrawer}>
            <PlusOutlined data-testid="crm-addofficevisitbutton" />{' '}
            {ADD_OFFICE_VISIT}
          </Button>
        </div>
      </PageHeaderMenu>
      <OfficeVisitDrawer
        visible={setDrawerOpen()}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        initialData={initialData}
        submitForm={submitForm}
      />
      <OfficeVisitDetailsDrawer
        visible={detailDrawerIsOpen!}
        onClose={handleDetailsDrawerClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        initialData={initialData}
        submitForm={submitForm}
      />
      <div className="bg-white padding-top-2">
        <Table
          action={getData}
          filterOptions={true}
          filter={filter}
          refresh={refreshTable}
          columns={
            name === 'waiting'
              ? waitingColumns
              : name === 'attending'
                ? attendingColumns
                : name === 'completed'
                  ? completedColumns
                  : columns
          }
          className="lead-table"
          rowSelect={false}
          filterContent={
            <>
              <AntSelect
                className="branch-list"
                value={!branchListLoading ? selectedBranch : ''}
                showSearch
                optionFilterProp="search_prop"
                placeholder="Select branch"
                onChange={handleBranchChange}
              >
                {branchList?.length > 0 &&
                  branchList?.map((item: any) => {
                    return (
                      <AntSelect.Option
                        value={item?.id}
                        key={item?.id}
                        search_prop={`${item?.name} ${item?.email}`}
                      >
                        {item?.name}
                      </AntSelect.Option>
                    );
                  })}
              </AntSelect>
            </>
          }
          tableButtons={
            <>
              <Button
                onClick={() => handleTableButtons('assignedToMe', assignedToMe)}
                type={assignedToMe ? 'primary' : 'default'}
              >
                {LABEL.ASSIGNED_TO_ME}
              </Button>
              <Button
                onClick={() => handleTableButtons('showAll', showAll)}
                type={showAll ? 'primary' : 'default'}
              >
                {LABEL.SHOW_ALL}
              </Button>
            </>
          }
        />
      </div>
    </div>
  );
};

export { OfficeVisitTableComponent };

