export const EMAIL_LABEL = 'Email';
export const ADMIN_EMAIL_LABEL = 'Admin Email';
export const CRM_EMAIL_LABEL = 'CRM Email';
export const PASSWORD_LABEL = 'Password';
export const CONFIRM_PASSWORD_LABEL = 'Confirm password';
export const REMEMBER_ME_LABEL = 'Remember me';
export const COMPANY_NAME_LABEL = 'Company name';
export const COMPANY_CODE = 'Company Code';
export const COMPANY_FEATURE = 'Feature';
export const YOUR_ROLE_LABEL = 'Your role';
export const INSTITUTION_PHONE_NUMBER_LABEL = 'Company phone number';
export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const COUNTRY_LABEL = 'Country';
export const CITY_OR_STATE_LABEL = 'City/State';
export const CITY_LABEL = 'City';
export const STATE_LABEL = 'State';
export const ZIP_CODE_LABEL = 'Zip Code';
export const FORGET_PASSWORD_LABEL = 'Forgot password?';
export const SELECT_FEATURE_LABEL = 'Select feature';
export const NO_OF_EMPLOYEES_LABEL = 'Number of employees';
export const NO_OF_DESTINATION_COUNTRIES_LABEL =
  'Number of destination countries';
export const COUNTRIES_OPERATED_LABEL = 'Countries operated';
export const NO_OF_ESTIMATES_LABEL =
  'Estimated number of applications in a year';

export const LABEL = {
  TAX_NUMBER: 'Tax Number',
  GENDER: 'Gender',
  REMARK: 'Remark',
  IELTS: 'IELTS',
  TOEFL: 'TOEFL',
  PTE: 'PTE',
  FORM_NAME: 'Form Name',
  FORM_TITLE: 'Form Title',
  HEADER_TEXT: 'Header Text',
  ACADEMIC_SCORE: 'Academic Score',
  UPLOAD_PROFILE_PICTURE: 'Upload Profile Image',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  DATE_OF_BIRTH: 'Date of Birth',
  PHONE_NUMBER: 'Phone Number',
  PHONE: 'Phone',
  SHARING_RATIO: 'Sharing Ratio',
  TENANT_SHARING: 'Tenant Share',
  EMAIL: 'Email',
  SECONDARY_EMAIL: 'Secondary Email',
  CONTACT_PREFERENCES: 'Contact Preferences',
  SELECT_JOB_PERIOD: 'Select Job Period',
  STREET: 'Street',
  CITY: 'City',
  STATE: 'State',
  DUE_DATE: 'Due Date',
  DUE_TIME: 'Due Time',
  POSTAL_CODE: 'Postal Code',
  ZIP_OR_POSTAL_CODE: 'Postal Code',
  COUNTRY: 'Country',
  COUNTRY_OF_BIRTH: 'Country of Birth',
  NATIONALITY: 'Nationality',
  VISA_TYPE: 'Visa Type',
  VISA_EXPIRY_DATE: 'Visa Expiry Date',
  COUNTRY_OF_PASSPORT: 'Country of Passport',
  PASSPORT_EXPIRY_DATE: 'Passport Expiry Date',
  ON_SHORE_ADMISSION: 'On Shore Admission',
  OFF_SHORE_ADMISSION: 'Off Shore Admission',
  DEGREE_LEVEL: 'Degree Level',
  PRODUCT_SUB_TYPE: 'Product Sub Type',
  DEGREE_TITLE: 'Degree Title',
  INSTITUTION: 'Institution',
  INSTITUTION_BRANCHES: 'Institution/Branches',
  APPLIED_INSTITUTION: 'Applied Institution',
  PRODUCT: 'Product',
  APPLIED_PRODUCT: 'Applied Product',
  INTAKE_YEAR: 'Intake Year',
  INTAKE_MONTH: 'Intake Month',
  START_END_DATE: 'Start / End Date',
  SUBJECT_AREA: 'Subject Area',
  COMMISSION_TYPE: 'Commission Type',
  SUBJECT: 'Discipline',
  COURSE_START: 'Course Start',
  COURSE_END: 'Course End',
  GPA: 'GPA',
  TOTAL_GPA: 'Total GPA',
  SECURED_GPA: 'Secured GPA',
  PERCENTAGE: 'Percentage',
  OVERALL_SCORE: 'Overall Score',
  SPEAKING: 'Speaking',
  READING: 'Reading',
  WRITING: 'Writing',
  LISTENING: 'Listening',
  SAT_I: 'SAT I',
  SAT_II: 'SAT II',
  SELECT_SERVICE: 'Select Service',
  SELECT_WORKFLOW: 'Select Workflow',
  SELECT_INSTITUTION: 'Select Institution',
  SELECT_DISCIPLINE: 'Select Discipline',
  SELECT_SUBJECT_AREA: 'Select Subject Area',
  SELECT_EDUCATION_LEVEL: 'Select Education Level',
  SELECT_PRODUCT_SUB_TYPE: 'Select Product Sub Type',
  DURATION: 'Duration',
  GRE: 'GRE',
  FEE_RANGE: 'Fee Range',
  GMAT: 'GMAT',
  COMPANY: 'Company',
  CATEGORY: 'Category',
  CATEGORIES: 'Categories',
  PREFERRED_INTAKE: 'Preferred Intake',
  SECONDARY_INTAKE_PREFERENCE: 'Secondary Intake Preference',
  INTAKES: 'Intakes',
  UPLOAD_DOCUMENTS: 'Upload Documents',
  ATTACHMENTS: 'Attachments',
  COMMENTS: 'Comments',
  PRIVACY_INFO: 'Privacy Information',
  CONSENT: 'Consent',
  SHOW_INFO_IN_LEAD_FORM: 'Show this information in Lead Form',
  CREATE_NEW_LEAD_FORM: 'Add Contact Form',
  EDIT_LEAD_FORM: 'Edit Contact Form',
  CREATE_NEW_BRANCH: 'Add Branch',
  CREATE_NEW_DEPARTMENT: 'Add Department',
  URL: 'URL',
  RELATED_OFFICE: 'Related Office',
  NUMBER_OF_BRANCHES: 'Number of Branches',
  TOTAL_PRODUCTS: 'Total Products',
  RELATED_TO: 'Related To',
  SOURCE: 'Source',
  ASSIGNEE: 'Assignee',
  RESET: 'Reset',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  BADGE: 'Badge',
  NAME: 'Name',
  SOURCE_NAME: 'Source Name',
  BANK_NAME: 'Bank Name',
  ACCOUNT_TYPE: 'Account Type',
  BANK_BALANCE: 'Bank Balance',
  RELATIONSHIP: 'Relationship',
  PRODUCT_INSTITUTION_NAME: 'Product / Institution Name',
  COURSE_DURATION: 'Select Course Duration',
  ARCHIVED: 'Archived',
  ARCHIVED_BY: 'Archived by',
  ADDED_FROM: 'Added from',
  ADDED_ON: 'Added on',
  LAST_UPDATED: 'Last Updated',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  CLIENT: 'Contact',
  CLIENT_INTERNAL_ID: 'Internal ID',
  CLIENT_ID: 'Contact ID',
  CURRENT_CITY: 'Current City',
  APPLICATION: 'Application',
  PASSPORT_NUMBER: 'Passport No',
  PASSPORT_NO: 'Passport Number',
  ZIP_CODE: 'Zip Code',
  FOLLOWERS: 'Followers',
  TAGS: 'Tag Name',
  HEUBERT: 'Heubert',
  BRANCHES: 'Branches',
  PRODUCT_TYPE: 'Product Type',
  SELECT_PRODUCT_TYPE: 'Select Product Type',
  COURSE_TYPE: 'Course Type',
  WALK_IN: 'Walk-In',
  SERVICE: 'Service',
  IMPORT_SERVICE: 'Import Service',
  TYPE: 'Type',
  CURRENCY: 'Currency',
  REGISTRATION_NUMBER: 'Registration Number',
  WEBSITE: 'Website',
  FAX: 'Fax',
  PARTNER_TYPE: 'Partner Type',
  CREATED_AT: 'Created At',
  UPDATED_AT: 'Updated At',
  ENROLLED: 'Enrolled',
  IN_PROGRESS: 'In Progress',
  CONTACT_PERSON: 'Contact Person',
  CONTACT: 'Contact',
  COMPANY_NAME: 'Company Name',
  MOBILE: 'Mobile',
  COMPANY_EMAIL: 'Company Email',
  COMPANY_PHONE: 'Company Phone',
  COMPANY_FAX: 'Company Fax',
  COMPANY_WEBSITE: 'Company Website',
  OFFICE_PHONE: 'Office Phone Number',
  BRANCH_NAME: 'Branch Name',
  BRANCH: 'Branch',
  SECONDARY_BRANCHES: 'Secondary Branches',
  WORKFLOW: 'Workflow',
  WORKFLOW_TYPE: 'Workflow Type',
  WORKFLOW_NAME: 'Workflow Name',
  WORKFLOW_TYPE_NAME: 'Workflow Type Name',
  BUSINESS_REG_NO: 'Business Registration Number',
  DEPARTMENT: 'Department',
  DEPARTMENT_NAME: 'Department Name',
  FEE_OPTION_NAME: 'Fee Option Name',
  COUNTRY_OF_RESIDENCE: 'Country of Residence',
  INSTALLMENT_TYPE: 'Installment Type',
  FEE_TYPE: 'Fee Type',
  FEE_COMMISSION: 'Commission %',
  IS_COMMISSIONABLE: 'Is Commissionable',
  ADD_QUOTATION: 'Add in quotation',
  SUB_AGENT_SHARING: 'Sub-Agent Sharing',
  FEE_TOTAL: 'Total Fee',
  FEE_NET_TOTAL: 'Net Total',
  JOB_TITLE: 'Job Title',
  INDUSTRY: 'Industry',
  EMPLOYMENT_TYPE: 'Employment Type',
  JOB_DESCRIPTION: 'Job Description',
  ORGANIZATION_NAME: 'Organization Name',
  STILL_WORKING: 'Still Working',
  REFERENCE_NAME: 'Reference Name',
  REFERENCE_POSITION: 'Reference Position',
  REFERENCE_PHONE: 'Reference Phone',
  REFERENCE_EMAIL: 'Reference Email',
  LEAD_APPLICATIONS: 'Lead Applications',
  CURRENT_STAGE: 'Current Stage',
  GO_TO_CURRENT_STAGE: 'Go To Current Stage',
  PREVIOUS_STAGE: 'Prev Stage',
  NEXT_STAGE: 'Next Stage',
  DISCONTINUE: 'Discontinue',
  COMPLETED: 'Completed',
  COMPLETE_APPLICATION: 'Complete Application',
  REVERT: 'Revert',
  PREVIOUS: 'previous',
  NEXT: 'next',
  COMPLETE: 'complete',
  DOCUMENT_CHECKLIST: 'Document Checklist',
  APPLICATION_FORM: 'Application Form',
  APPLICATION_ID: 'Application ID',
  STAGES: 'Workflow Stages',
  CHECKLIST: 'Documents',
  FORM: 'Form Fields',
  CONTACT_TYPE: 'Type',
  ADDED_DATE: 'Added Date',
  EXPIRATION_DATE: 'Expiration Date',
  DOMAIN_NAME: 'Domain Name',
  PLAN_TYPE: 'Plan Type',
  DOMAIN: 'Domain',
  NO_APPOINTMENTS: 'No Appointments',
  TENANT: 'Tenant',
  SHOW_ALL: 'Show All',
  CREATE_NEW_APPLICATION: 'Add Application',
  ONLY_MY_APPLICATIONS: 'Only My Applications',
  CRICOS_COURSE_CODE: 'CRICOS Course Code',
  VISIT_PURPOSE: 'Visit Purpose',
  SESSION_NOTES: 'Session Notes',
  IMPORT_EXISTING: 'Import Existing',
  IMPORT_EXISTING_SERVICE: 'Import Service',
  CREATE_NEW: 'Create New',
  CREATE_NEW_SERVICE: 'Create New Service',
  EDIT_SERVICE: 'Edit Service',
  ASSIGNED_TO_ME: 'Assigned to me',
  SELECT: 'Select',
  DATE: 'Date',
  ATTEND_TIME: 'Attend time',
  WAIT_TIME: 'Wait time',
  SESSION_START: 'Session Start',
  SESSION_END: 'Session End',
  CHECK_IN_DETAILS: 'Check-in Details',
  ATTEND_SESSION: 'Attend Session',
  END_SESSION: 'End Session',
  PARTNER_AGENTS: 'Partner Agents',
  SUB_AGENT: 'Sub-Agent',
  SUB_AGENT_BRANCH: 'Sub-agent Branch',
  SUPER_AGENT: 'Super-Agent',
  SUPER_AGENT_BRANCH: 'Super-agent Branch',
  OFFICES_AND_ASSIGNEES: 'Offices and Assignees',
  AGENT_TYPE: 'Agent Type',
  PRIMARY_BRANCH: 'Primary Branch',
  CONTACT_ACCESS_LEVEL: 'Contact Access Level',
  NOT_AVAILABLE: 'Not Available',
  INTERNAL_ID: 'Internal ID',
  CONTACT_ID: 'Contact ID',
  CONTACT_NAME: 'Contact Name',
  APPLICATIONS: 'Applications',
  DATE_OF_SUBMISSION: 'Date of submission',
  CONTACT_STATE: 'Contact State',
  CONTACT_COUNTRY: 'Contact Country',
  PROCESSING_BRANCH: 'Processing Branch',
  INSTITUTION_COUNTRY: 'Institution Country',
  INSTITUTION_BRANCH: 'Institution Branch',
};

export const OTHER_TEST_KEY_VALUE = {
  sat_1: LABEL.SAT_I,
  sat_2: LABEL.SAT_II,
  gmat: LABEL.GMAT,
  gre: LABEL.GRE,
};

export const PRODUCT_ACTIONS = {
  START_APPLICATION: 'start_application',
  ADD_TO_INTERESTED: 'add_to_interested',
};

export const ASSIGNEE_LABEL = 'Assignee';
export const PHONE_LABEL = 'Phone Number';
export const SYSTEM = 'System';
export const BLUE = 'Blue';
export const ADDRESS1_LABEL = 'Address one';
export const ADDRESS2_LABEL = 'Address two';
export const ALTERNATIVE_PHONE_LABEL = 'Alternative Phone';
export const ROLE_LABEL = 'Role';
export const OLD_PASSWORD = 'Old Password';
export const CURRENT_PASSWORD = 'Current Password';
export const TIMEZONE_LABEL = 'Timezone';
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const CONFIRM_NEW_PASSWORD = 'Confirm New Password';
export const TITLE_LABEL = 'Title';
export const DESCRIPTION_LABEL = 'Description';
export const NOTES_LABEL = 'Notes';
export const UNASSIGNED_LABEL = 'Unassigned';
export const ALL_APPLICATIONS = 'All Applications';
export const ADD_MESSAGE = 'Add message...';
export const PREVIOUS_VISITS = 'Previous Visits';

export const BOOLEAN = {
  YES: 'Yes',
  NO: 'No',
  TRUE: 'true',
  FALSE: 'false',
};
export const LANGUAGE_TEST_SCORE_LABEL = 'Language Test Score';
