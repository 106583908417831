import { userActions } from '@crm/core';
import { UserList } from '@crm/services.api';
import { PAGE_HEADER_MENU_CRM_USER } from '@moxie/constants';
import { IUserFormAdd } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserDrawer from './user-drawer';
import { UserTableList } from './user-table-list';
import { PageHeaderMenu } from './components/page-header-menu';

const UserTable = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [personalDetailsForm] = useForm();
  const [disabled, setDisabled] = useState(true);
  const [, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [, setInitialData] = useState<IUserFormAdd>();
  const [refreshTable, setRefreshTable] = useState(true);
  const [filter] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((store: any) => store.users.singleData);
  const { name }: { name: string } = useParams();
  const params = useParams();

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  useEffect(() => {
    triggerRefresh();
  }, [params]);

  const getUserList = async (params: string) => {
    if (name === 'active') {
      return await UserList(true, params);
    } else if (name === 'inactive') {
      return await UserList(false, params);
    }
  };

  const handleDrawerClose = () => {
    setFormData({});
    setDrawerVisible(false);
    personalDetailsForm.resetFields();
    setIsEditMode(false);
    setDisabled(true);
  };

  const showDrawer = async () => {
    setDrawerVisible(true);
    setIsEditMode(false);
    setDisabled(true);
  };

  const handleSubmit = (data: any) => {
    const secondaryBranches = data.secondaryBranches?.map((item: string) => ({
      id: item,
    }));
    if (isEditMode) {
      dispatch(
        userActions.editUserAction(
          { id: userData.id, ...data },
          () => triggerRefresh && triggerRefresh()
        )
      );
    } else {
      dispatch(
        userActions.addUserRequest(
          {
            ...data,
            role: `${data.role}`,
            secondaryBranches,
          },
          triggerRefresh
        )
      );
    }
    handleDrawerClose();
  };

  return (
    <div className="full-height">
      <PageHeaderMenu menu={PAGE_HEADER_MENU_CRM_USER} defaultActiveKey={name}>
        <UserDrawer
          visible={drawerVisible}
          setDisabled={setDisabled}
          disabled={disabled}
          onClose={handleDrawerClose}
          form={personalDetailsForm}
          saveData={handleSubmit}
          isEdit={isEditMode}
        />
      </PageHeaderMenu>
      <UserTableList
        filterOptions={true}
        actionBar={true}
        showDrawer={showDrawer}
        getData={getUserList}
        filter={filter}
        refresh={refreshTable}
        hideBranch={false}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export default UserTable;
