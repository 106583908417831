import { subjectDisciplineActions, useAppSelector } from '@crm/core';
import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useGetSubjectAreas = (form: FormInstance<any>) => {
  const [selectedDiscipline, setSelectedDiscipline] = useState<string | any>();
  const dispatch = useDispatch();
  const subjectAreas = useAppSelector(
    (state) => state.subject_discipline.subjectAreas
  );
  const subjectAreasLoading = useAppSelector(
    (state) => state.subject_discipline.loading
  );

  const handleDisciplineChange = (value: string) => {
    setSelectedDiscipline(value);
    form.setFieldsValue({ subject_area_id: undefined });
  };

  useEffect(() => {
    if (selectedDiscipline) {
      dispatch(
        subjectDisciplineActions.getSubjectAreasRequest(selectedDiscipline)
      );
    }
  }, [selectedDiscipline]);
  return {
    subjectAreas,
    subjectAreasLoading,
    selectedDiscipline,
    setSelectedDiscipline,
    handleDisciplineChange,
  };
};
