import { userActions } from '@crm/core';
import { getArchivedContact, getcontactList } from '@crm/services.api';
import {
  LEAD_INITIAL_DATA,
  PAGE_HEADER_MENU_LEAD,
  TEXT,
} from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { ContactListRouteParam, ILead } from '@shared-components/models';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { LeadAddEdit } from '../../contact-add-edit';
import { LeadTableList } from '../lead-table-list';
import { useAssigneeChanged } from '@crm/libs/hooks/contacts';
import { PageHeaderMenu } from '../component/page-header-menu';
import axios from 'axios';


const LeadComponent: React.FC = () => {
  const { name } = useParams<ContactListRouteParam>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialData, setInitialData] = useState<ILead>();
  const dispatch = useDispatch();

  const { refresh, triggerRefresh } = useAssigneeChanged();

  const { token, cancel } = axios.CancelToken.source();

  const location = useLocation();


  const getData = async (str: string) => {
    const params = new URLSearchParams(str);
    const selectBy = params.get('selectBy');
    if (selectBy === 'archived') {
      params.delete('selectBy');
      const urlString = params.toString();
      return await getArchivedContact(urlString, token);
    } else {
      return await getcontactList(str, token);
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
  };

  const handleAddContact = () => {
    dispatch(userActions.fetchUsers());
    setInitialData(LEAD_INITIAL_DATA);
    setIsDrawerOpen(true);
    setIsEditMode(false);
  };

  useEffect(() => {
    return () => {
      cancel();
    }
  }, [getData, location.pathname])

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.LEADS} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_LEAD} defaultActiveKey={name} />
      <LeadAddEdit
        isOpen={isDrawerOpen}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
        setDisabled={setDisabled}
        disabled={disabled}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        isEdit={isEditMode}
      />

      <LeadTableList
        filterOptions={true}
        getData={getData}
        refresh={refresh}
        handleAddContact={handleAddContact}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export { LeadComponent };
