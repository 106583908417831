import axios from './axios';
import { API_URL } from '@moxie/constants';
import { IResponse } from '@shared-components/models';

const getVisitPurposeListBasedOnService = (
  serviceId: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.VISIT_PURPOSE}/${serviceId}/service/list`);
};

export { getVisitPurposeListBasedOnService };
