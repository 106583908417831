import React, { useState } from 'react';
import { IProfileSection } from '@shared-components/models';
import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import {
  Avatar,
  Col,
  Row,
  Space,
  Tag,
  Typography,
  DatePicker,
  Tooltip,
  Card,
} from 'antd';
import { BRANCH, GUTTER, TEXT, TIMEZONE_LABEL } from '@moxie/constants';
import { Button } from '@shared-components/elements';
import UserDrawer from './user-drawer';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { userActions } from '@crm/core';

const { Text, Paragraph } = Typography;

const UserProfile: React.FC<IProfileSection> = ({ data }: IProfileSection) => {
  const {
    first_name,
    last_name,
    email,
    position,
    photo,
    phone,
    time_zone,
    branch,
  } = data;

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [userForm] = useForm();

  const handleOpen = () => {
    setVisible(true);
  };
  const handleOnClose = () => {
    setVisible(false);
    setDisabled(true);
    userForm.resetFields();
  };
  const [isCopied, setIsCopied] = useState(false);
  const [ellipsis] = useState(true);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  const handleSubmit = async (values: Record<string,unknown>): Promise<void> => {
    setDisabled(true);
     dispatch(userActions.editUserAction({ id: data.id, ...values }, () => {
      setVisible(false);
      dispatch(userActions.fetchUser(data.id));
     }) );
  };

  return (
    <section className="profile">
      <Card>
        <UserDrawer
          isEdit={true}
          form={userForm}
          disabled={disabled}
          setDisabled={setDisabled}
          visible={visible}
          onClose={handleOnClose}
          saveData={handleSubmit}
        />
        <Row gutter={24}>
          <Col lg={8} className="custom_divider">
            <div className="profile__info">
              <div className="profile__info__item profile__info__user">
                {photo ? (
                  <Avatar size="large" src={photo} />
                ) : (
                  <Avatar size="large">
                    {first_name?.substring(0, 1).toUpperCase() +
                      last_name?.substring(0, 1).toUpperCase()}
                  </Avatar>
                )}
                <div className="profile__info__user__detail initial_capital">
                  <h3>
                    {first_name} {last_name}
                  </h3>
                  <Tag className="tag-primary tag-primary--capitalize">
                    {position}
                  </Tag>
                </div>
                {data.deactivated === false ? (
                  <div className="profile__info__edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={handleOpen}
                      type="link"
                    />
                  </div>
                ) : null}
              </div>
              <Row>
                {branch ? (
                  <Col>
                    <Row className="user__branch">
                      <Col className="profile__header">
                        <h4 className="profile__title">{BRANCH}:</h4>
                      </Col>
                      <Col className="user__branch_title">
                        <div className="user_branch initial_capital">
                          <Tag>{branch?.name || ''}</Tag>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              <div className="profile__footer">
                {time_zone && (
                  <Space>
                    <Text className="profile__title" strong>
                      {TIMEZONE_LABEL}:
                    </Text>
                    <Text className="profile__title">
                      <small>{time_zone}</small>
                    </Text>
                  </Space>
                )}
              </div>
            </div>
          </Col>
          <Col lg={8} className="custom_divider">
            <Row justify="space-between" gutter={GUTTER}>
              <Col span={24}>
                <div className="profile__info__item">
                  {phone && (
                    <Row>
                      <Col>
                        <h3 className="profile__title">{TEXT.PHONE}:</h3>
                      </Col>
                      <Col className="padding-left-1">
                        <h3 className="profile__link">{phone}</h3>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="profile__info__item">
                  {email && (
                    <>
                      <Row className="email_div">
                        <Col>
                          <span className="profile__title margin-0">
                            {TEXT.EMAIL}:
                          </span>
                        </Col>

                        <Col
                          className="user-profile-email-container"
                          span={20}
                          lg={18}
                        >
                          <span className="profile__title padding-left-1">
                            <a className="email_link" href={`mailto:${email}`}>
                              <Paragraph
                                ellipsis={
                                  ellipsis ? { tooltip: `${email}` } : undefined
                                }
                                className="text-link-color initial_small"
                              >
                                {email}
                              </Paragraph>
                            </a>
                          </span>
                        </Col>

                        <Col className="hide">
                          <Tooltip
                            className=" margin-left-1 "
                            placement="right"
                            color={isCopied ? '#8EAF74' : '#9B9D9A '}
                            title={
                              isCopied
                                ? `${TEXT.COPIED_SUCCESS}`
                                : `${TEXT.COPY_EMAIL}`
                            }
                          >
                            <LinkOutlined
                              className="copy_email_icon"
                              onClick={() => copyToClipboard(`${email}`)}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <div className="profile__info__item">
              <div className="profile__header">
                <h4 className="profile__title align-items-center margin-0">
                  {TEXT.OVERVIEW}:
                </h4>
                <DatePicker picker="year" />
              </div>
              <div className="profile__overview">
                <div className="profile__overview__item">
                  <h4>{TEXT.TOTAL_CLIENTS}</h4>
                  {/* TODO: Refactor it later. Get number of clients from backend.  */}
                  <span>3</span>
                </div>
                <div className="profile__overview__item">
                  <h4>{TEXT.TOTAL_APPLICATIONS}</h4>
                  <span>4</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  );
};

export { UserProfile };
