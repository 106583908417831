import {
  CANCEL_BTN,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import { Space } from 'antd';
import React from 'react';
import { ProductsForm } from './products-form';

const ProductsFormDrawer = ({
  isOpen,
  onClose,
  initialData,
  form,
  isEdit,
  setDisabled,
  handleSubmit,
  disabled,
  isLoading,
  productId,
}: any) => {
  const handleSubmitButtonClick = () => {
    setDisabled(true);
    form.submit();
  };
  const title = isEdit ? EDIT_PRODUCT : CREATE_PRODUCT;

  return (
    <DrawerElem
      data-testid="product-drawer"
      visible={isOpen}
      onClose={onClose}
      width={720}
      title={title}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>

            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ProductsForm
        isLoading={isLoading}
        productId={productId}
        initialData={initialData}
        form={form}
        isEdit={isEdit}
        onSubmit={handleSubmit}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};
export { ProductsFormDrawer };
