/* eslint-disable no-unused-vars */
import React from 'react';
import { ILead, ITableNameCardProps } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { UserNameCard } from './user-name-card';
import {
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
  RESTORE,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { Menu } from 'antd';
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { popConfirm } from '../../shared';
import { leadActions } from '@crm/core';

const userMenu = (
  archived: boolean,
  {
    onEdit,
    onArchive,
    onDelete,
  }: {
    onEdit: () => void;
    onArchive: () => void;
    onDelete: () => void;
  },
  data: ILead | any,
  user: any
) => {
  return (
    <Menu>
      {!archived && (
        <Menu.Item key={1} onClick={onEdit} icon={<EditOutlined />}>
          {EDIT_BTN}
        </Menu.Item>
      )}
      {data?.status !== 'Client' && (
        <Menu.Item key={2} icon={<AppstoreAddOutlined />} onClick={onArchive}>
          {archived ? RESTORE : ARCHIVE}
        </Menu.Item>
      )}
      {
        <Menu.Item key={3} icon={<DeleteOutlined />} danger onClick={onDelete}>
          {DELETE_BTN}
        </Menu.Item>
      }
    </Menu>
  );
};

export const ContactTableNameCard: React.FC<ITableNameCardProps | any> = ({
  firstName,
  lastName,
  email,
  index,
  id,
  data,
  userProp,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: ITableNameCardProps | any) => {
  const dispatch = useDispatch();
  const user = userProp;

  const handleEdit = () => {
    setInitialData(data);
    setIsEditMode(true);
    setIsDrawerOpen(true);
  };

  const onArchive = () => {
    if (data?.archived)
      dispatch(
        leadActions.archiveLeadRequest(
          id,
          { archived: false, assignee_user_id: data.assignee_user_id },
          triggerRefresh
        )
      );
    else
      dispatch(
        leadActions.archiveLeadRequest(id, { archived: true }, triggerRefresh)
      );
    triggerRefresh();
  };

  const onDelete = () => {
    dispatch(leadActions.deleteLeadRequest(data.id, () => triggerRefresh()));
  };

  const handleArchive = () => {
    popConfirm(
      !data?.archived ? ARCHIVE : RESTORE,
      !data?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${data?.first_name} ${data?.last_name}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onArchive
    );
  };

  const handleSingleLeadDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_LEAD,
      `${CONFIRM_MESSAGE.DELETE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onDelete,
      true
    );
  };

  const menuActions = {
    onEdit: handleEdit,
    onArchive: handleArchive,
    onDelete: handleSingleLeadDelete,
  };

  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      id={id}
      email={email}
      link={`${URL_DETAIL_LINK}${id}/applications`}
      actionBar={true}
      index={index}
      menu={userMenu(data?.archived || false, menuActions, data, user)}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};
