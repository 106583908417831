const alias = 'CONTACT';

export const GET_CONTACT_DETAILS_REQUEST = `GET_${alias}_DETAILS_REQUEST`;
export const GET_CONTACT_DETAILS_SUCCESS = `GET_${alias}_DETAILS_SUCCESS`;
export const GET_CONTACT_DETAILS_FAILURE = `GET_${alias}_DETAILS_FAILURE`;

export const UPDATE_CONTACT_ASSIGNEE_REQUEST = `UPDATE_${alias}_ASSIGNEE_REQUEST`;
export const UPDATE_CONTACT_ASSIGNEE_SUCCESS = `UPDATE_${alias}_ASSIGNEE_SUCCESS`;
export const UPDATE_CONTACT_ASSIGNEE_FAILURE = `UPDATE_${alias}_ASSIGNEE_FAILURE`;

export const UPDATE_CONTACT_FOLLOWERS_REQUEST = `UPDATE_${alias}_FOLLOWERS_REQUEST`;
export const UPDATE_CONTACT_FOLLOWERS_SUCCESS = `UPDATE_${alias}_FOLLOWERS_SUCCESS`;
export const UPDATE_CONTACT_FOLLOWERS_FAILURE = `UPDATE_${alias}_FOLLOWERS_FAILURE`;

export const UPDATE_CONTACT_PERSONAL_INFO_REQUEST = `UPDATE_${alias}_PERSONAL_INFO_REQUEST`;
export const UPDATE_CONTACT_PERSONAL_INFO_SUCCESS = `UPDATE_${alias}_PERSONAL_INFO_SUCCESS`;
export const UPDATE_CONTACT_PERSONAL_INFO_FAILURE = `UPDATE_${alias}_PERSONAL_INFO_FAILURE`;

export const UPDATE_CONTACT_ADDRESS_INFO_REQUEST = `UPDATE_${alias}_ADDRESS_INFO_REQUEST`;
export const UPDATE_CONTACT_ADDRESS_INFO_SUCCESS = `UPDATE_${alias}_ADDRESS_INFO_SUCCESS`;
export const UPDATE_CONTACT_ADDRESS_INFO_FAILURE = `UPDATE_${alias}_ADDRESS_INFO_FAILURE`;

export const UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST = `UPDATE_${alias}_PASSPORT_VISA_INFO_REQUEST`;
export const UPDATE_CONTACT_PASSPORT_VISA_INFO_SUCCESS = `UPDATE_${alias}_PASSPORT_VISA_INFO_SUCCESS`;
export const UPDATE_CONTACT_PASSPORT_VISA_INFO_FAILURE = `UPDATE_${alias}_PASSPORT_VISA_INFO_FAILURE`;

export const UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST = `UPDATE_${alias}_EMERGENCY_DETAILS_REQUEST`;
export const UPDATE_CONTACT_EMERGENCY_DETAILS_SUCCESS = `UPDATE_${alias}_EMERGENCY_DETAILS_SUCCESS`;
export const UPDATE_CONTACT_EMERGENCY_DETAILS_FAILURE = `UPDATE_${alias}_EMERGENCY_DETAILS_FAILURE`;

export const CLEAR_CONTACT_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_CONTACT_EDUCATION_BACKGROUND_SUCCESS = `ADD_${alias}_EDUCATION_BACKGROUND_SUCCESS`;
export const ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST = `ADD_${alias}_EDUCATION_BACKGROUND_REQUEST`;
export const ADD_CONTACT_EDUCATION_BACKGROUND_FAILURE = `ADD_${alias}_EDUCATION_BACKGROUND_FAILURE`;

export const GET_CONTACT_EDUCATION_BACKGROUND_SUCCESS = `GET_${alias}_EDUCATION_BACKGROUND_SUCCESS`;
export const GET_CONTACT_EDUCATION_BACKGROUND_REQUEST = `GET_${alias}_EDUCATION_BACKGROUND_REQUEST`;
export const GET_CONTACT_EDUCATION_BACKGROUND_FAILURE = `GET_${alias}_EDUCATION_BACKGROUND_FAILURE`;

export const UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST = `UPDATE_${alias}_EDUCATION_BACKGROUND_REQUEST`;
export const UPDATE_CONTACT_EDUCATION_BACKGROUND_SUCCESS = `UPDATE_${alias}_EDUCATION_BACKGROUND_SUCCESS`;
export const UPDATE_CONTACT_EDUCATION_BACKGROUND_FAILURE = `UPDATE_${alias}_EDUCATION_BACKGROUND_FAILURE`;

export const DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST = `DELETE_${alias}_EDUCATION_BACKGROUND_REQUEST`;
export const DELETE_CONTACT_EDUCATION_BACKGROUND_SUCCESS = `DELETE_${alias}_EDUCATION_BACKGROUND_SUCCESS`;
export const DELETE_CONTACT_EDUCATION_BACKGROUND_FAILURE = `DELETE_${alias}_EDUCATION_BACKGROUND_FAILURE`;

export const CLEAR_CONTACT_EDUCATION_BACKGROUND_FETCH = `CLEAR_${alias}_EDUCATION_BACKGROUND_FETCH`;

export const ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST = `ADD_${alias}_PROFESSIONAL_EXPERIENCE_REQUEST`;
export const ADD_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS = `ADD_${alias}_PROFESSIONAL_EXPERIENCE_SUCCESS`;
export const ADD_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE = `ADD_${alias}_PROFESSIONAL_EXPERIENCE_FAILURE`;

export const GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST = `GET_${alias}_PROFESSIONAL_EXPERIENCE_REQUEST`;
export const GET_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS = `GET_${alias}_PROFESSIONAL_EXPERIENCE_SUCCESS`;
export const GET_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE = `GET_${alias}_PROFESSIONAL_EXPERIENCE_FAILURE`;

export const UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST = `UPDATE_${alias}_PROFESSIONAL_EXPERIENCE_REQUEST`;
export const UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS = `UPDATE_${alias}_PROFESSIONAL_EXPERIENCE_SUCCESS`;
export const UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE = `UPDATE_${alias}_PROFESSIONAL_EXPERIENCE_FAILURE`;

export const DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST = `DELETE_${alias}_PROFESSIONAL_EXPERIENCE_REQUEST`;
export const DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS = `DELETE_${alias}_PROFESSIONAL_EXPERIENCE_SUCCESS`;
export const DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE = `DELETE_${alias}_PROFESSIONAL_EXPERIENCE_FAILURE`;

export const CLEAR_CONTACT_PROFESSIONAL_EXPERIENCE_FETCH = `CLEAR_${alias}_PROFESSIONAL_EXPERIENCE_FETCH`;
