import React, { useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { useAppSelector } from '@crm/core';
import {
  AUTH_MESSAGE,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  GUTTER,
  INTAKES,
  LABEL,
  PLACEHOLDER,
  PRODUCT_DETAILS,
  PRODUCT_INFORMATION,
  PRODUCT_NAME_REQUIRED,
  REVENUE_TYPE,
  TEXT,
  regex,
} from '@moxie/constants';
import { Editor } from '@shared-components/forms';
import {
  IGetPopupContainer,
  ProductFormProps,
} from '@shared-components/models';
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd';
import { formExtraNotes, preventMinus } from 'libs/shared/src/functions';

import { useGetBranchesByInstitution } from '../hooks/useGetBranchesByInstitution';
import { useGetProductById } from '../hooks/useGetProductById';

import { useGetProductSubTypes } from '../hooks/useGetProductSubTypes';
import { useGetSubjectAreas } from '../hooks/useGetSubjectAreasByDiscipline';
import { useGetSubjectDiscipline } from '../hooks/useGetSubjectDiscipline';
import { useHandleServiceChange } from '../hooks/useHandleServiceChange';
const { Option } = Select;
const { Panel } = Collapse;

interface Props extends ProductFormProps {
  productId: string;
  isLoading: boolean;
}

const ProductsForm: React.FC<Props> = ({
  productId,
  form,
  initialData,
  onSubmit,
  setDisabled,
  isEdit,
}) => {
  const services = useAppSelector((state) => state.services.allData);
  const servicesLoading = useAppSelector((state) => state.services.loading);
  const { isLoading } = useGetProductById(productId);
  const {
    institutions,
    institutionsLoading,
    productTypes,
    productTypesLoading,
    handleServiceChange,
    setSelectedService,
  } = useHandleServiceChange(form);

  const {
    branches,
    branchesLoading,
    workflows,
    workflowsLoading,
    handleInstitutionChange,
    setSelectedInstitution,
  } = useGetBranchesByInstitution(form);

  const {
    productSubTypes,
    productSubTypesLoading,
    setSelectedProductType,
    handleProductTypeChange,
  } = useGetProductSubTypes(form);

  const {
    subjectDisciplines,
    subjectDisciplinesLoading,
  } = useGetSubjectDiscipline();

  const {
    subjectAreas,
    subjectAreasLoading,
    setSelectedDiscipline,
    handleDisciplineChange,
  } = useGetSubjectAreas(form);

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length === 1) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setSelectedService(form.getFieldValue('service'));
    }
  }, [isEdit, form.getFieldValue('service')]);

  useEffect(() => {
    if (isEdit) {
      setSelectedInstitution(form.getFieldValue('institution_id'));
    }
  }, [isEdit, form.getFieldValue('institution_id')]);

  useEffect(() => {
    if (isEdit) {
      setSelectedProductType(form.getFieldValue('product_type_id'));
    }
  }, [isEdit, form.getFieldValue('product_type_id')]);

  useEffect(() => {
    if (isEdit) {
      setSelectedDiscipline(form.getFieldValue('subject_disciplines_id'));
    }
  }, [isEdit, form.getFieldValue('subject_disciplines_id')]);

  return (
    <Spin
      spinning={
        isEdit
          ? isLoading ||
            subjectAreasLoading ||
            servicesLoading ||
            institutionsLoading ||
            branchesLoading ||
            productTypesLoading
          : servicesLoading
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        scrollToFirstError
        onFieldsChange={onFieldsChange}
      >
        <Collapse
          defaultActiveKey={['1']}
          bordered={false}
          ghost={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header={<span>{PRODUCT_DETAILS}</span>} key="1">
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  label={LABEL.NAME}
                  name="name"
                  data-testid="crm-productname"
                  rules={[
                    { required: true, max: 100 },
                    {
                      whitespace: true,
                      type: 'string',
                      message: PRODUCT_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Input className="note-title-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div id="product_service" className="relative">
                  <Form.Item
                    label={LABEL.SERVICE}
                    name="service"
                    data-testid="crm-productservice"
                    rules={[{ required: true }]}
                  >
                    <Select
                      value={initialData?.service}
                      disabled={isEdit}
                      onChange={handleServiceChange}
                      placeholder={PLACEHOLDER.SELECT_SERVICE}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_service')
                      }
                    >
                      {/*  */}
                      {services?.map((service: any, i: number) => (
                        <Option value={service.id} key={i}>
                          {service.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div id="product_institution" className="relative">
                  <Form.Item
                    label={LABEL.INSTITUTION}
                    name="institution_id"
                    data-testid="crm-productinstitution"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={handleInstitutionChange}
                      disabled={isEdit}
                      value={initialData?.institution?.id}
                      placeholder={PLACEHOLDER.SELECT_INSTITUTION}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_institution')
                      }
                    >
                      {institutionsLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        institutions?.map((institution: any, i: number) => (
                          <Option value={institution.id} key={i}>
                            {institution.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col span={12}>
                <div id="product_branches" className="relative">
                  <Form.Item
                    label={LABEL.BRANCHES}
                    name="branches"
                    data-testid="crm-productbranch"
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="note-title-input"
                      placeholder={PLACEHOLDER.SELECT_BRANCH}
                      loading={branchesLoading}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_branches')
                      }
                      mode="multiple"
                    >
                      {branchesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        branches?.map((branch: any, i: number) => (
                          <Option value={branch.id} key={i}>
                            {branch.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div id="product_workflow" className="relative">
                  <Form.Item
                    label={LABEL.WORKFLOW}
                    name="workflows"
                    data-testid="crm-productworkflow"
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="note-title-input"
                      placeholder={PLACEHOLDER.SELECT_WORKFLOW}
                      loading={workflowsLoading}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_workflow')
                      }
                      mode="multiple"
                    >
                      {workflowsLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        workflows?.map((item: any, i: number) => (
                          <Option
                            value={item.workflow?.workflowType?.id}
                            key={i}
                          >
                            {item.workflow?.workflowType?.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="identification_system_code"
                  rules={[{ max: 15 }]}
                  label={TEXT.COURSE_CODE}
                  data-testid="crm-productcourse-code"
                >
                  <Input className="note-title-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div id="product_type" className="relative">
                  <Form.Item
                    label={LABEL.PRODUCT_TYPE}
                    name="product_type_id"
                    rules={[{ required: true }]}
                    data-testid="crm-product_product_type"
                  >
                    <Select
                      onChange={handleProductTypeChange}
                      className="note-title-input"
                      value={initialData?.product_type_id}
                      placeholder={PLACEHOLDER.PRODUCT_TYPE}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_type')
                      }
                    >
                      {productTypesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        productTypes?.map((productType: any, i: number) => (
                          <Option value={productType.id} key={i}>
                            {productType.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div id="product_sub_type" className="relative">
                  <Form.Item
                    label={LABEL.PRODUCT_SUB_TYPE}
                    name="product_sub_type_id"
                    data-testid="crm-product-product_sub_type"
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="note-title-input"
                      value={initialData?.product_sub_type}
                      placeholder={PLACEHOLDER.PRODUCT_SUB_TYPE}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_sub_type')
                      }
                    >
                      {productSubTypesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        productSubTypes?.map(
                          (productSubType: any, i: number) => (
                            <Option value={productSubType.id} key={i}>
                              {productSubType.name}
                            </Option>
                          )
                        )
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={LABEL.COMMISSION_TYPE}
                  name="revenue_type"
                  rules={[{ required: true }]}
                >
                  <Radio.Group className="note-title-input">
                    <Radio
                      value="Commission"
                      key="1"
                      checked={true}
                      data-testid="crm-product-revenuetype-from-partner"
                    >
                      {REVENUE_TYPE.COMMISSION_FROM_PARTNER}
                    </Radio>
                    <Radio
                      key="2"
                      value="Contact fee"
                      data-testid="crm-product-revenuetypefromcontact"
                    >
                      {REVENUE_TYPE.REVENUE_FROM_CONTACT}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={['1']}
          bordered={false}
          ghost={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header={<span>{PRODUCT_INFORMATION}</span>} key="1">
            <Row gutter={GUTTER}>
              <Col span={12}>
                <div id="product_discipline" className="relative">
                  <Form.Item
                    label={LABEL.SUBJECT}
                    name="subject_disciplines_id"
                    data-testid="crm-product-discpline"
                  >
                    <Select
                      onChange={handleDisciplineChange}
                      className="note-title-input"
                      value={initialData?.subject_disciplines_id}
                      placeholder={LABEL.SELECT_DISCIPLINE}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_discipline')
                      }
                    >
                      {subjectDisciplinesLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        subjectDisciplines?.map(
                          (subjectDiscipline: any, i: number) => (
                            <Option value={subjectDiscipline.id} key={i}>
                              {subjectDiscipline.discipline}
                            </Option>
                          )
                        )
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div id="product_subject_area" className="relative">
                  <Form.Item
                    label={LABEL.SUBJECT_AREA}
                    name="subject_area_id"
                    data-testid="crm-product-subjectarea"
                    extra={
                      !form.getFieldValue('subject_disciplines_id')
                        ? formExtraNotes('Discipline')
                        : ''
                    }
                  >
                    <Select
                      className="note-title-input"
                      value={initialData?.subject_area_id}
                      placeholder={LABEL.SELECT_SUBJECT_AREA}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_subject_area')
                      }
                    >
                      {subjectAreasLoading ? (
                        <Option disabled value={''}>
                          <Spin spinning className="full-width" size="small" />
                        </Option>
                      ) : (
                        subjectAreas?.map((subjectArea: any, i: number) => (
                          <Option value={subjectArea.id} key={i}>
                            {subjectArea.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col span={16}>
                <p className="ant-form-text padding-bottom-1">
                  {TEXT.DURATIONS}
                </p>
                <br></br>
                <Row className="product-addon">
                  <Col span={8}>
                    <Form.Item name="product_duration_year">
                      <Input
                        data-testid="crm-product-duration-year"
                        min={0}
                        onKeyPress={preventMinus}
                        type="number"
                        addonAfter={TEXT.YEARS}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'product_duration_month'}>
                      <Input
                        data-testid="crm-product-duration-month"
                        min={0}
                        type="number"
                        onKeyPress={preventMinus}
                        width={30}
                        addonAfter={TEXT.MONTHS}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="product_duration_week">
                      <Input
                        data-testid="crm-product-duration-weeks"
                        min={0}
                        onKeyPress={preventMinus}
                        type="number"
                        addonAfter={TEXT.WEEKS}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <div id="product_intake" className="relative">
                  <Form.Item
                    data-testid="crm-product-intakes"
                    label={LABEL.INTAKES}
                    name="intakes"
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="note-title-input"
                      placeholder={PLACEHOLDER.INTAKES}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('product_intake')
                      }
                      mode={'multiple'}
                    >
                      {INTAKES.map((data) => {
                        return (
                          <Option value={data?.value} key={data?.value}>
                            {data?.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12} className="padding-top-3">
                <Form.Item data-testid="crm-product-selectcheckbox">
                  <Checkbox>{TEXT.SELECT_ALL_INTAKES}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  data-testid="crm-product-programlink"
                  label={TEXT.PROGRAM_LINK}
                  name="program_link"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(regex.WEBSITE),
                      message: AUTH_MESSAGE.INVALID_WEB_URL,
                    },
                  ]}
                >
                  <Input
                    placeholder={TEXT.PROGRAM_LINK}
                    className="note-title-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={DESCRIPTION_LABEL}
                  name="description"
                  data-testid="crm-product-description"
                >
                  <Editor
                    value="description"
                    placeholder={DESCRIPTION_PLACEHOLDER}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    </Spin>
  );
};

export { ProductsForm };
