import { getcontactList } from '@crm/services.api';
import { PAGE_HEADER_MENU_CLIENT, TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { ILead } from '@shared-components/models';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LeadAddEdit } from '../../contact-add-edit';
import { ClientTableList } from '../client-table-list';
import { PageHeaderMenu } from '../components/page-header-menu';
import { useAssigneeChanged } from '@crm/libs/hooks/contacts';
import axios from 'axios';

const ClientComponent: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialData, setInitialData] = useState<ILead>();
  const { refresh, triggerRefresh } = useAssigneeChanged();

  const { token, cancel } = axios.CancelToken.source();

  const location = useLocation();

  const getData = async (str: string) => {
    return await getcontactList(str, token);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
  };

  useEffect(() => {
    return () => {
      cancel();
    }
  }, [getData, location.pathname])

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CLIENTS} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_CLIENT} defaultActiveKey={name} />
      <LeadAddEdit
        isOpen={isDrawerOpen}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
        setDisabled={setDisabled}
        disabled={disabled}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        isEdit={isEditMode}
      />

      <ClientTableList
        filterOptions={true}
        getData={getData}
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export { ClientComponent };
