import { API_URL } from '@moxie/constants';
import axios from './axios';
import { ILead, IResponse } from '@shared-components/models';

const postLead = async (data: ILead): Promise<ILead> => {
  return axios.post(API_URL.URL_CRM_ADD_LEAD, data);
};

const updateLead = async (data: Partial<ILead>): Promise<ILead> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.URL_CRM_ADD_LEAD}/${id}`, data);
};

const deleteLead = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.URL_CRM_ADD_LEAD}/${id}`);
};

const archiveLead = async (
  id: string | undefined,
  data: any
): Promise<ILead> => {
  return axios.put(`${API_URL.URL_CRM_ADD_LEAD}/${id}`, data);
};

export { postLead, deleteLead, updateLead, archiveLead };
