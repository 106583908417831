import axios from './axios';
import { IResponse, IUser, IUserAddResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const userAdd = async (payload: any): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_USER}/add`, payload);
};

const userUpdate = async (data: Partial<IUser>): Promise<IUser> => {
  const id = data.id;
  delete data.id;
  return axios.put(`${API_URL.CRM_USER}/${id}`, data);
};

const checkUserEmail = (email: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_USER}/check-email/${email}`);
};

const UserList = async (status: any, params?: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_USER}/list${params}&active=${status}`);
};

const UserListForContacts = async (status = true): Promise<any> => {
  return axios.get(`${API_URL.CRM_USER}/list?active=${status}`);
};

const UserData = async (id: string): Promise<IUserAddResponse> => {
  const data = await axios.get(`${API_URL.CRM_USER}/${id}`);
  return data;
};

const deactivateUser = async (userId: string): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_USER}/deactivate/${userId}`);
};

const activateUser = async (userId: string): Promise<IResponse> => {
  return axios.post(`${API_URL.CRM_USER}/activate/${userId}`);
};

const updateUserPermissions = async (
  userId: string,
  crmPermissionData: { crm_permission_id: number }
): Promise<any> => {
  return axios.put(
    `${API_URL.CRM_USER}/${userId}/update-permissions`,
    crmPermissionData
  );
};

const fetchPrimaryBranchUsers = async (): Promise<any> => {
  return axios.get(`${API_URL.CRM_USER}/primary-branch-users`);
}

const fetchUsersByBranchId = async (branchId: string) => {
  const {data} = await axios.get(`${API_URL.CRM_USER}/${branchId}/users`);
  return data;
}


export {
  userAdd,
  userUpdate,
  UserList,
  UserData,
  checkUserEmail,
  deactivateUser,
  activateUser,
  UserListForContacts,
  updateUserPermissions,
  fetchPrimaryBranchUsers,
  fetchUsersByBranchId
};
