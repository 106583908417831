import { useAppSelector } from "@admin/core";

const allowedUsers = [
  'sameer@experteducation.com.au',
  'utsav.sitaula@experteducation.com.au',
  'it@experteducation.com.au',
  'prajit.baniya@experteducation.com.au',
  'info@xprt.com',
  'one@xprt.com',
  'info@everest.com',
  'info@idea.com',
  'info@global.com'
];

type Resource = 'contact-enquiry' | 'branch'| 'user'| 'service'| 'product' | 'product-fee' | 'product-requirement' | 'contact' | 'agent'| 'institution';
type Permission = 'all' | 'create' | 'read' | 'update' | 'delete';

export const useCanAccess = () => {
  const user = useAppSelector(store => store.auth.user);

  return (resource: Resource, ...permissions: Permission[]) => {
    // TODO:: implement permission once the permission is integrated from backend
    return Boolean(user && !!allowedUsers.includes(user.email.toLowerCase()));
  }
}
