import React, { useState } from 'react';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space, Typography } from 'antd';
import InstitutionForm from '../../forms/institution/institution-form';
import { institutionActions } from '@crm/core';
import { ICRMInstitutionDrawer } from '@shared-components/models';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { normalizePhrase } from 'libs/shared/src/functions';
const { Title } = Typography;

const InstitutionDrawer = ({
  drawerOpen,
  handleDrawerEvent,
  isEdit,
  handleEditEvent,
  isDetailPage,
  triggerRefresh,
}: ICRMInstitutionDrawer) => {
  const [form] = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const postFormSubmit = () => {
    setIsSubmitting(false);
    handleDrawerEvent(false);
    form.resetFields();
    handleEditEvent(false);
  };

  const submitFormData = (data: any) => {
    data.name = normalizePhrase(data.name);
    if (data?.id && isEdit) {
      dispatch(
        institutionActions.updateInstitutionRequest(data, triggerRefresh, true)
      );
    } else {
      dispatch(institutionActions.addInstitutionRequest(data, triggerRefresh));
    }
    postFormSubmit();
  };

  const handleSubmitClick = async () => {
    try {
      setIsSubmitting(true);
      setSubmitDisabled(true);
      await form.validateFields();
      form.submit();
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const handleDrawerClose = () => {
    if (!isDetailPage) {
      dispatch(institutionActions.clearFetchInstitution());
    }
    form.resetFields();
    handleEditEvent(false);
    handleDrawerEvent(false);
  };

  return (
    <DrawerElem
      data-testid="crm-addinstitutiondrawer"
      title={
        isEdit || isDetailPage ? (
          <Title level={4}>{TEXT.EDIT_INSTITUTE}</Title>
        ) : (
          <Title level={4}>{TEXT.ADD_INSTITUTE}</Title>
        )
      }
      width={DRAWER_WIDTH}
      onClose={handleDrawerClose}
      visible={drawerOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleDrawerClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitClick}
              type="primary"
              disabled={submitDisabled}
            >
              {isEdit || isDetailPage ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <InstitutionForm
        form={form}
        submitFormData={submitFormData}
        setSubmitDisabled={setSubmitDisabled}
        isSubmitting={isSubmitting}
        isEdit={isEdit}
      />
    </DrawerElem>
  );
};

export { InstitutionDrawer };
